import currenciesMetadata from "constants/currenciesMetadata";

import React, { useEffect, useState } from "react";

import LaunchIcon from "@mui/icons-material/Launch";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { previewDynamicDcaStrategy, dcaBotsBacktest } from "API/calls";
import CenterWrapper from "components/elements/CenterWrapper";
import LocalizedDatePicker from "components/elements/LocalizedDatePicker";
import PageTitle from "components/elements/PageTitle";
import PriceField from "components/elements/PriceField";
import Select from "components/elements/Select";
import Tile from "components/elements/Tile";
import { SectionBox } from "components/modules/BotsList/Item";
import LinearDifference from "components/modules/BotsList/Item/LinearDifference";
import PercentDifferenceBox from "components/modules/BotsList/Item/PercentDifferenceBox";
import ValueItem from "components/modules/BotsList/Item/ValueItem";
import SatsView from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import dayjs, { Dayjs } from "dayjs";
import { Trans, useTranslation } from "react-i18next";
import { usePrevious } from "react-use";
import { DcaBotMode, DcaDynamicStrategyType } from "types/enums";
import { IPoint } from "types/types";
import { percentageChange, sampleArray, splitCurrencyPair } from "utils";
import { renderNumber } from "utils/formatter";

import DynamicStrategySection from "../NewDcaBot/DynamicStrategySection";
import { ABOUT_DYNAMIC_STRATEGIES_LINK, ALLOWED_DYNAMIC_STRATEGIES } from "../NewDcaBot/DynamicStrategySelect";
import { DEFAULT_DYNAMIC_STRATEGY_VALUES } from "../NewDcaBot/module/useNewDcaBotData";
import { IPointChart, ITooltipData } from "./Chart";
import ChartWrapper from "./Chart/Wrapper";

interface IBacktestData {
  d: string; //date
  p: number; //price
  i: number; //indicatorValue
  m: number; //multiplierValue
  ovf: number; //orderValueFixed
  ovd: number; //orderValueDynamic
  if: number; //totalInvestedFixed
  id: number; //totalInvestedDynamic
  af: number; //totalAmountFixed
  ad: number; //totalAmountDynamic
  apf: number; //totalAveragePriceFixed
  apd: number; //totalAveragePriceDynamic
  vf: number; //totalValueFixed
  vd: number; //totalValueDynamic
  vfp: number; //totalValueFixedPercentDiff
  vdp: number; //totalValueDynamicPercentDiff
  fdp: number; //totalValueFixedVsDynamicPercentDiff
}

const DATE_FORMAT = "YYYY-MM-DD";
const MIN_DATE_FROM = "2017-12-01";
const HIGHLIGHTED_CHART_LINE_WIDTH = 2;
const SAMPLE_SIZE = 366;
const DEFAULT_SELECT_BACK = "3";
const SELECT_BACK = ["1", "2", "3", "4", "5", "6", "custom"];
const BACKTEST_CURRENCY_PAIRS = {
  "BTC/CZK": {
    defaultOrderValue: "300",
    counterCurrDisplayedScale: 0,
    baseCurrDisplayedScale: 4,
  },
  "BTC/EUR": {
    defaultOrderValue: "10",
    counterCurrDisplayedScale: 0,
    baseCurrDisplayedScale: 4,
  },
  "BTC/USD": {
    defaultOrderValue: "10",
    counterCurrDisplayedScale: 0,
    baseCurrDisplayedScale: 4,
  },
};

const isSomeNaN = (values: string[]) => values.some((value) => value === "NaN");

const BacktestDcaStrategy: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [points, setPoints] = useState<IPoint[]>([]);
  const [pointsOriginal, setPointsOriginal] = useState<IPoint[]>([]);
  const [currentDynamicStrategyMultiplier, setCurrentDynamicStrategyMultiplier] = useState<number>(0);
  const [backtestData, setBacktestData] = useState<IBacktestData[]>([]);
  const [backtestDataOriginal, setBacktestDataOriginal] = useState<IBacktestData[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currencyPair, setCurrencyPair] = useState<string>(Object.keys(BACKTEST_CURRENCY_PAIRS)[0]);
  const [selectBack, setSelectBack] = useState<string>(DEFAULT_SELECT_BACK);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(dayjs().format(DATE_FORMAT));
  const [type, setType] = useState<DcaDynamicStrategyType>(DcaDynamicStrategyType.BTC_NUPL);
  const prevType = usePrevious(type);
  const [minMultiplier, setMinMultiplier] = useState<number>(0.5);
  const [maxMultiplier, setMaxMultiplier] = useState<number>(3);
  const [isLimitsTransition, setIsLimitsTransition] = useState<boolean>(false);
  const [limits, setLimits] = useState<{ top: number; center: number; bottom: number }>(
    DEFAULT_DYNAMIC_STRATEGY_VALUES[DcaDynamicStrategyType.BTC_NUPL].DCA_BUY
  );
  const [marketOrderValue, setMarketOrderValue] = useState<string>(
    BACKTEST_CURRENCY_PAIRS[currencyPair as keyof typeof BACKTEST_CURRENCY_PAIRS].defaultOrderValue
  );
  const { counterCurrency, baseCurrency } = splitCurrencyPair(currencyPair);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale } = BACKTEST_CURRENCY_PAIRS[currencyPair as keyof typeof BACKTEST_CURRENCY_PAIRS];
  const { price_scale : priceScale} = currenciesMetadata.currency_pairs[currencyPair as keyof typeof currenciesMetadata.currency_pairs];

  const handleChangeDate = (key: "dateFrom" | "dateTo") => (newValue: Dayjs | null) => {
    const value = newValue ? newValue?.format(DATE_FORMAT) : newValue;

    if (key === "dateFrom") setDateFrom(value);
    else setDateTo(value);
  };

  const handleChangeSettings = (field: string, value: number): Promise<void> => {
    if (field === "dynamicStrategySettings.maxMultiplier") setMaxMultiplier(value);
    if (field === "dynamicStrategySettings.minMultiplier") setMinMultiplier(value);
    if (field === "dynamicStrategySettings.top") setLimits((prev) => ({ ...prev, top: value }));
    if (field === "dynamicStrategySettings.bottom") setLimits((prev) => ({ ...prev, bottom: value }));
    if (field === "dynamicStrategySettings.center") setLimits((prev) => ({ ...prev, center: value }));

    return new Promise<void>(() => null);
  };

  useEffect(() => {
    setMarketOrderValue(BACKTEST_CURRENCY_PAIRS[currencyPair as keyof typeof BACKTEST_CURRENCY_PAIRS].defaultOrderValue);
  }, [currencyPair]);

  useEffect(() => {
    const fetchPreview = async () => {
      try {
        const { data } = await previewDynamicDcaStrategy({
          currencyPair,
          frequency: "D",
          frequencyParameter: 1,
          strategy: {
            type: "MARKET",
            marketOrderValue,
            dynamicStrategy: {
              type,
              minMultiplier,
              maxMultiplier,
              top: limits.top,
              bottom: limits.bottom,
              center: limits.center,
            },
          },
        });

        setPoints(sampleArray(data?.dynamicStrategyChart?.points, SAMPLE_SIZE));
        setPointsOriginal(data?.dynamicStrategyChart?.points);
        setCurrentDynamicStrategyMultiplier(data?.currentDynamicStrategyMultiplier);
      } catch (error) {
        // silent
      }
    };

    fetchPreview();
  }, [marketOrderValue, currencyPair, minMultiplier, maxMultiplier, JSON.stringify(limits)]);

  const isValidDate = (date: string | null) => date !== null && dayjs(date).isValid();

  useEffect(() => {
    const fetchBacktest = async () => {
      setIsLoading(true);
      try {
        const { data } = await dcaBotsBacktest({
          currencyPair,
          strategy: {
            type: "MARKET",
            marketOrderValue,
            dynamicStrategy: {
              type,
              minMultiplier,
              maxMultiplier,
              top: limits.top,
              bottom: limits.bottom,
              center: limits.center,
            },
          },
          dateFrom,
          dateTo,
        });

        setBacktestData(sampleArray(data.data, SAMPLE_SIZE));
        setBacktestDataOriginal(data.data);
      } catch (error) {
        // silent
      } finally {
        setIsLoading(false);
      }
    };

    if (isValidDate(dateFrom) && isValidDate(dateTo)) fetchBacktest();
  }, [
    marketOrderValue,
    currencyPair,
    minMultiplier,
    maxMultiplier,
    JSON.stringify(limits),
    dateFrom,
    dateTo,
  ]);

  useEffect(() => {
    if (selectBack === "custom") return;

    let currentDateTo = dateTo;
    const today = dayjs().format(DATE_FORMAT);

    if (today !== currentDateTo) {
      currentDateTo = today;
      setDateTo(today);
    }

    const value = Number(selectBack);
    const dateFrom = dayjs(currentDateTo).subtract(value, "year").format(DATE_FORMAT);
    setDateFrom(dateFrom);
  }, [selectBack]);

  useEffect(() => {
    if (type && type !== DcaDynamicStrategyType.EMPTY && prevType) {
      setIsLimitsTransition(true);
      setLimits(DEFAULT_DYNAMIC_STRATEGY_VALUES[type].DCA_BUY);
    }
  }, [type]);

  useEffect(() => {
    if (isLimitsTransition) {
      setIsLimitsTransition(false);
    }
  }, [limits]);

  const getDynamicStrategyMultiplierInfo = () => {
    if (!currentDynamicStrategyMultiplier || !marketOrderValue) return;

    const currentValue = Number(`${marketOrderValue}`.replace(",", "."));

    const from = `${renderNumber(currentValue * minMultiplier, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`;
    const to = `${renderNumber(currentValue * maxMultiplier, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`;
    const current = `${renderNumber(currentValue * currentDynamicStrategyMultiplier, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`;
    const multiplier = `${renderNumber(currentDynamicStrategyMultiplier, 2)}`;

    const minMultiplierString = `${minMultiplier}`.replace(".", ",");
    const maxMultiplierString = `${maxMultiplier}`.replace(".", ",");

    const monthlyCurrently = `${renderNumber(currentValue * currentDynamicStrategyMultiplier * 30, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`;
    const monthlyMax = `${renderNumber(currentValue * maxMultiplier * 30, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`;

    return (
      <Trans
        i18nKey="dcaBots.create.dynamicStrategy.multiplierInfoOnBacktest"
        values={{
          minMultiplierString,
          from,
          maxMultiplierString,
          to,
          multiplier,
          current,
          monthlyCurrently,
          monthlyMax,
        }}
      />
    );
  };

  const getOrderValueChart = () => {
    const orderValueFixedPoints: IPointChart[] = [];
    const orderValueDynamicPoints: IPointChart[] = [];

    backtestData.forEach((item) => {
      if (item.d) {
        if (item.ovf) orderValueFixedPoints.push({ time: item.d, value: item.ovf });
        if (item.ovd) orderValueDynamicPoints.push({ time: item.d, value: item.ovd });
      }
    });

    const lastOvf = renderNumber(orderValueFixedPoints[orderValueFixedPoints.length - 1]?.value, counterCurrDisplayedScale);
    const lastOvd = renderNumber(orderValueDynamicPoints[orderValueDynamicPoints.length - 1]?.value, counterCurrDisplayedScale);

    const orderValueFixedPointsOriginal: IPointChart[] = [];
    const orderValueDynamicPointsOriginal: IPointChart[] = [];

    backtestDataOriginal.forEach((item) => {
      if (item.d) {
        if (item.ovf) orderValueFixedPointsOriginal.push({ time: item.d, value: item.ovf });
        if (item.ovd) orderValueDynamicPointsOriginal.push({ time: item.d, value: item.ovd });
      }
    });

    return (
      <ChartWrapper
        title={t("backtestDcaStrategy.orderValue.title")}
        subtitle={
          !isSomeNaN([lastOvf, lastOvd]) && (
            <Trans
              i18nKey="backtestDcaStrategy.orderValue.subtitle"
              values={{ lastOvf, lastOvd, currency: counterCurrency }}
            />
          )
        }
        loading={!backtestData.length}
        lineSeriesOptions={[
          {
            label: t("backtestDcaStrategy.orderValue.fixed"),
            type: "price",
            position: "right",
            color: theme.palette.warning.main,
          },
          {
            label: t("backtestDcaStrategy.orderValue.dynamic"),
            type: "price",
            position: "right",
            color: theme.palette.tertiary.main,
            lineWidth: 1,
          },
        ]}
        points={[orderValueFixedPoints, orderValueDynamicPoints]}
        pointsOriginal={[orderValueFixedPointsOriginal, orderValueDynamicPointsOriginal]}
        valueUnit={counterCurrency}
        tooltipScale={counterCurrDisplayedScale}
        fixedTooltip
      />
    );
  };

  const getDifferenceTooltip = (key: string, first: number, second: number, multiplier = 1) => (data?: ITooltipData[]) => {
    if (!data) return;

    const firstNumber = data[first]?.value;
    const secondNumber = data[second]?.value;
    const diff = renderNumber(percentageChange(secondNumber, firstNumber) * multiplier, 2);

    return (
      <div>
        {t(`backtestDcaStrategy.${key}.difference`)}: <strong>{diff}</strong>%
      </div>
    );
  };

  const getPriceAndAverageChart = () => {
    const pricePoints: IPointChart[] = [];
    const totalAveragePriceFixedPoints: IPointChart[] = [];
    const totalAveragePriceDynamicPoints: IPointChart[] = [];

    backtestData.forEach((item) => {
      if (item.d) {
        if (item.p) pricePoints.push({ time: item.d, value: item.p });
        if (item.apf) totalAveragePriceFixedPoints.push({ time: item.d, value: item.apf });
        if (item.apd) totalAveragePriceDynamicPoints.push({ time: item.d, value: item.apd });
      }
    });

    const lastApfNumber = totalAveragePriceFixedPoints[totalAveragePriceFixedPoints.length - 1]?.value;
    const lastApdNumber = totalAveragePriceDynamicPoints[totalAveragePriceDynamicPoints.length - 1]?.value;
    const lastP = renderNumber(pricePoints[pricePoints.length - 1]?.value, priceScale);
    const lastApf = renderNumber(lastApfNumber, priceScale);
    const lastApd = renderNumber(lastApdNumber, priceScale);
    const diff = renderNumber(percentageChange(lastApfNumber, lastApdNumber) * -1, 2);

    const pricePointsOriginal: IPointChart[] = [];
    const totalAveragePriceFixedPointsOriginal: IPointChart[] = [];
    const totalAveragePriceDynamicPointsOriginal: IPointChart[] = [];

    backtestDataOriginal.forEach((item) => {
      if (item.d) {
        if (item.p) pricePointsOriginal.push({ time: item.d, value: item.p });
        if (item.apf) totalAveragePriceFixedPointsOriginal.push({ time: item.d, value: item.apf });
        if (item.apd) totalAveragePriceDynamicPointsOriginal.push({ time: item.d, value: item.apd });
      }
    });

    return (
      <ChartWrapper
        title={t("backtestDcaStrategy.priceAndAverage.title")}
        subtitle={
          !isSomeNaN([lastP, lastApf, lastApd]) && (
            <Trans
              i18nKey="backtestDcaStrategy.priceAndAverage.subtitle"
              values={{ lastP, lastApf, lastApd, diff, currency: counterCurrency }}
            />
          )
        }
        loading={!backtestData.length}
        lineSeriesOptions={[
          {
            label: t("backtestDcaStrategy.priceAndAverage.price", { currencyPair }),
            type: "price",
            position: "right",
            color: theme.palette.mode === "light" ? colors.black : colors.gray700,
          },
          {
            label: t("backtestDcaStrategy.priceAndAverage.fixed"),
            type: "price",
            position: "right",
            color: theme.palette.warning.main,
          },
          {
            label: t("backtestDcaStrategy.priceAndAverage.dynamic"),
            type: "price",
            position: "right",
            color: theme.palette.tertiary.main,
            lineWidth: HIGHLIGHTED_CHART_LINE_WIDTH,
          },
        ]}
        points={[pricePoints, totalAveragePriceFixedPoints, totalAveragePriceDynamicPoints]}
        pointsOriginal={[
          pricePointsOriginal,
          totalAveragePriceFixedPointsOriginal,
          totalAveragePriceDynamicPointsOriginal,
        ]}
        priceScaleMode={1}
        valueUnit={counterCurrency}
        tooltipScale={priceScale}
        tooltipRender={getDifferenceTooltip("priceAndAverage", 2, 1, -1)}
      />
    );
  };

  const getTotalValueChart = () => {
    const totalValueFixedPoints: IPointChart[] = [];
    const totalValueDynamicPoints: IPointChart[] = [];

    backtestData.forEach((item) => {
      if (item.d) {
        if (item.vf) totalValueFixedPoints.push({ time: item.d, value: item.vf });
        if (item.vd) totalValueDynamicPoints.push({ time: item.d, value: item.vd });
      }
    });

    const lastVfNumber = totalValueFixedPoints[totalValueFixedPoints.length - 1]?.value;
    const lastVdNumber = totalValueDynamicPoints[totalValueDynamicPoints.length - 1]?.value;
    const lastVf = renderNumber(lastVfNumber, counterCurrDisplayedScale);
    const lastVd = renderNumber(lastVdNumber, counterCurrDisplayedScale);
    const diff = renderNumber(percentageChange(lastVfNumber, lastVdNumber), 2);

    const totalValueFixedPointsOriginal: IPointChart[] = [];
    const totalValueDynamicPointsOriginal: IPointChart[] = [];

    backtestDataOriginal.forEach((item) => {
      if (item.d) {
        if (item.vf) totalValueFixedPointsOriginal.push({ time: item.d, value: item.vf });
        if (item.vd) totalValueDynamicPointsOriginal.push({ time: item.d, value: item.vd });
      }
    });

    return (
      <ChartWrapper
        title={t("backtestDcaStrategy.totalValue.title")}
        subtitle={
          !isSomeNaN([lastVf, lastVd]) && (
            <Trans
              i18nKey="backtestDcaStrategy.totalValue.subtitle"
              values={{ lastVf, lastVd, diff, currency: counterCurrency }}
            />
          )
        }
        loading={!backtestData.length}
        lineSeriesOptions={[
          {
            label: t("backtestDcaStrategy.totalValue.fixed"),
            type: "price",
            position: "right",
            color: theme.palette.warning.main,
          },
          {
            label: t("backtestDcaStrategy.totalValue.dynamic"),
            type: "price",
            position: "right",
            color: theme.palette.tertiary.main,
            lineWidth: HIGHLIGHTED_CHART_LINE_WIDTH,
          },
        ]}
        points={[totalValueFixedPoints, totalValueDynamicPoints]}
        pointsOriginal={[totalValueFixedPointsOriginal, totalValueDynamicPointsOriginal]}
        valueUnit={counterCurrency}
        tooltipScale={counterCurrDisplayedScale}
        tooltipRender={getDifferenceTooltip("totalValue", 1, 0)}
      />
    );
  };

  const getPercentDiffChart = () => {
    const totalValueFixedPercentDiffPoints: IPointChart[] = [];
    const totalValueDynamicPercentDiffPoints: IPointChart[] = [];
    const totalValueFixedVsDynamicPercentDiffPoints: IPointChart[] = [];

    backtestData.forEach((item) => {
      if (item.d) {
        if (item.vfp) totalValueFixedPercentDiffPoints.push({ time: item.d, value: item.vfp });
        if (item.vdp) totalValueDynamicPercentDiffPoints.push({ time: item.d, value: item.vdp });
        if (item.fdp) totalValueFixedVsDynamicPercentDiffPoints.push({ time: item.d, value: item.fdp });
      }
    });

    const lastVfp = renderNumber(totalValueFixedPercentDiffPoints[totalValueFixedPercentDiffPoints.length - 1]?.value, counterCurrDisplayedScale);
    const lastVdp = renderNumber(totalValueDynamicPercentDiffPoints[totalValueDynamicPercentDiffPoints.length - 1]?.value, counterCurrDisplayedScale);
    const lastFdp = renderNumber(totalValueFixedVsDynamicPercentDiffPoints[totalValueFixedVsDynamicPercentDiffPoints.length - 1]?.value, counterCurrDisplayedScale);

    const totalValueFixedPercentDiffPointsOriginal: IPointChart[] = [];
    const totalValueDynamicPercentDiffPointsOriginal: IPointChart[] = [];
    const totalValueFixedVsDynamicPercentDiffPointsOriginal: IPointChart[] = [];

    backtestDataOriginal.forEach((item) => {
      if (item.d) {
        if (item.vfp) totalValueFixedPercentDiffPointsOriginal.push({ time: item.d, value: item.vfp });
        if (item.vdp) totalValueDynamicPercentDiffPointsOriginal.push({ time: item.d, value: item.vdp });
        if (item.fdp) totalValueFixedVsDynamicPercentDiffPointsOriginal.push({ time: item.d, value: item.fdp });
      }
    });

    return (
      <ChartWrapper
        title={t("backtestDcaStrategy.totalValuePercentDifference.title")}
        subtitle={
          !isSomeNaN([lastVfp, lastVdp, lastFdp]) && (
            <Trans
              i18nKey="backtestDcaStrategy.totalValuePercentDifference.subtitle"
              values={{ lastVfp, lastVdp, lastFdp, currency: counterCurrency }}
            />
          )
        }
        loading={!backtestData.length}
        lineSeriesOptions={[
          {
            label: t("backtestDcaStrategy.totalValuePercentDifference.fixed"),
            type: "price",
            position: "right",
            color: theme.palette.warning.main,
          },
          {
            label: t("backtestDcaStrategy.totalValuePercentDifference.dynamic"),
            type: "price",
            position: "right",
            color: theme.palette.tertiary.main,
            lineWidth: HIGHLIGHTED_CHART_LINE_WIDTH,
          },
          {
            label: t("backtestDcaStrategy.totalValuePercentDifference.fixedVsDynamic"),
            type: "price",
            position: "right",
            color: theme.palette.success.main,
            lineWidth: HIGHLIGHTED_CHART_LINE_WIDTH,
          },
        ]}
        points={[
          totalValueFixedPercentDiffPoints,
          totalValueDynamicPercentDiffPoints,
          totalValueFixedVsDynamicPercentDiffPoints,
        ]}
        pointsOriginal={[
          totalValueFixedPercentDiffPointsOriginal,
          totalValueDynamicPercentDiffPointsOriginal,
          totalValueFixedVsDynamicPercentDiffPointsOriginal,
        ]}
        valueUnit={"%"}
        tooltipScale={2}
      />
    );
  };

  const getTotalInvestedChart = () => {
    const totalInvestedFixedPoints: IPointChart[] = [];
    const totalInvestedDynamicPoints: IPointChart[] = [];

    backtestData.forEach((item) => {
      if (item.d) {
        if (item.if) totalInvestedFixedPoints.push({ time: item.d, value: item.if });
        if (item.id) totalInvestedDynamicPoints.push({ time: item.d, value: item.id });
      }
    });

    const lastIfNumber = totalInvestedFixedPoints[totalInvestedFixedPoints.length - 1]?.value;
    const lastIdNumber = totalInvestedDynamicPoints[totalInvestedDynamicPoints.length - 1]?.value;
    const lastIf = renderNumber(lastIfNumber, counterCurrDisplayedScale);
    const lastId = renderNumber(lastIdNumber, counterCurrDisplayedScale);
    const diff = renderNumber(percentageChange(lastIfNumber, lastIdNumber), 2);

    const totalInvestedFixedPointsOriginal: IPointChart[] = [];
    const totalInvestedDynamicPointsOriginal: IPointChart[] = [];

    backtestDataOriginal.forEach((item) => {
      if (item.d) {
        if (item.if) totalInvestedFixedPointsOriginal.push({ time: item.d, value: item.if });
        if (item.id) totalInvestedDynamicPointsOriginal.push({ time: item.d, value: item.id });
      }
    });

    return (
      <ChartWrapper
        title={t("backtestDcaStrategy.totalInvested.title")}
        subtitle={
          !isSomeNaN([lastIf, lastId]) && (
            <Trans
              i18nKey="backtestDcaStrategy.totalInvested.subtitle"
              values={{ lastIf, lastId, diff, currency: counterCurrency }}
            />
          )
        }
        loading={!backtestData.length}
        lineSeriesOptions={[
          {
            label: t("backtestDcaStrategy.totalInvested.fixed", { currency: counterCurrency }),
            type: "price",
            position: "right",
            color: theme.palette.warning.main,
          },
          {
            label: t("backtestDcaStrategy.totalInvested.dynamic", { currency: counterCurrency }),
            type: "price",
            position: "right",
            color: theme.palette.tertiary.main,
            lineWidth: HIGHLIGHTED_CHART_LINE_WIDTH,
          },
        ]}
        points={[totalInvestedFixedPoints, totalInvestedDynamicPoints]}
        pointsOriginal={[totalInvestedFixedPointsOriginal, totalInvestedDynamicPointsOriginal]}
        valueUnit={counterCurrency}
        tooltipScale={counterCurrDisplayedScale}
        tooltipRender={getDifferenceTooltip("totalInvested", 1, 0)}
      />
    );
  };

  const getTotalAmountChart = () => {
    const totalAmountFixedPoints: IPointChart[] = [];
    const totalAmountDynamicPoints: IPointChart[] = [];

    backtestData.forEach((item) => {
      if (item.d) {
        if (item.af) totalAmountFixedPoints.push({ time: item.d, value: item.af });
        if (item.ad) totalAmountDynamicPoints.push({ time: item.d, value: item.ad });
      }
    });

    const lastAfNumber = totalAmountFixedPoints[totalAmountFixedPoints.length - 1]?.value;
    const lastAdNumber = totalAmountDynamicPoints[totalAmountDynamicPoints.length - 1]?.value;
    const lastAf = renderNumber(lastAfNumber, baseCurrDisplayedScale);
    const lastAd = renderNumber(lastAdNumber, baseCurrDisplayedScale);
    const diff = renderNumber(percentageChange(lastAfNumber, lastAdNumber), 2);

    const totalAmountFixedPointsOriginal: IPointChart[] = [];
    const totalAmountDynamicPointsOriginal: IPointChart[] = [];

    backtestDataOriginal.forEach((item) => {
      if (item.d) {
        if (item.af) totalAmountFixedPointsOriginal.push({ time: item.d, value: item.af });
        if (item.ad) totalAmountDynamicPointsOriginal.push({ time: item.d, value: item.ad });
      }
    });

    return (
      <ChartWrapper
        title={t("backtestDcaStrategy.totalAmount.title")}
        subtitle={
          !isSomeNaN([lastAf, lastAd]) && (
            <Trans
              i18nKey="backtestDcaStrategy.totalAmount.subtitle"
              values={{ lastAf, lastAd, diff, currency: baseCurrency }}
            />
          )
        }
        loading={!backtestData.length}
        lineSeriesOptions={[
          {
            label: t("backtestDcaStrategy.totalAmount.fixed", { currency: baseCurrency }),
            type: "price",
            position: "right",
            color: theme.palette.warning.main,
          },
          {
            label: t("backtestDcaStrategy.totalAmount.dynamic", { currency: baseCurrency }),
            type: "price",
            position: "right",
            color: theme.palette.tertiary.main,
            lineWidth: HIGHLIGHTED_CHART_LINE_WIDTH,
          },
        ]}
        points={[totalAmountFixedPoints, totalAmountDynamicPoints]}
        pointsOriginal={[totalAmountFixedPointsOriginal, totalAmountDynamicPointsOriginal]}
        valueUnit={baseCurrency}
        tooltipScale={baseCurrDisplayedScale}
        tooltipRender={getDifferenceTooltip("totalAmount", 1, 0)}
      />
    );
  };

  const handleChangeSelectBack = (event: any) => setSelectBack(event.target.value as string);

  const result = (
    title: string,
    totalAmount?: number,
    currentValue?: number,
    totalInvested?: number,
    difference?: number,
    averagePrice?: number,
    fixedVsDynamic?: number
  ) => {
    const currentValueDifference = (currentValue ?? 0) - (totalInvested ?? 0);

    return (
      <Stack width={"100%"} spacing={1}>
        <Typography variant="h6" fontWeight={400} sx={{ color: "tertiary.main" }}>
          {title}
        </Typography>
        <Stack spacing={"2px"}>
          <SectionBox>
            <Stack justifyContent={"center"}>
              <Typography fontSize={"0.875rem"} fontWeight={500} color={colors.gray950}>
                {t("backtestDcaStrategy.result.saved")}
              </Typography>
              {totalAmount ? (
                <SatsView
                  color={colors.gray950}
                  fontSize={"1.125rem"}
                  fontWeight={500}
                  value={totalAmount}
                  currency={baseCurrency}
                  scale={baseCurrDisplayedScale}
                  withZeros={!!totalAmount}
                />
              ) : (
                <Skeleton variant="text" width={150} height={40} />
              )}
            </Stack>
          </SectionBox>
          <SectionBox>
            <Stack spacing={1}>
              <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <ValueItem
                  label={t("backtestDcaStrategy.result.currentValue")}
                  value={currentValue}
                  sx={{ flexDirection: "column-reverse" }}
                  valueFontSize="1.125rem"
                  isLoading={!currentValue}
                  skeletonHeight={40}
                  withZeros={!!currentValue}
                  scale={counterCurrDisplayedScale}
                  counterCurrency={counterCurrency}
                  baseCurrency={baseCurrency}
                  withoutUsd
                />
                {difference ? (
                  <PercentDifferenceBox value={difference ?? 0} />
                ) : (
                  <Skeleton variant="text" width={50} height={60} />
                )}
              </Stack>
              <LinearDifference currentValue={currentValue} value={totalInvested} />
              <Stack direction={"row"} justifyContent={"space-between"}>
                <ValueItem
                  label={t("backtestDcaStrategy.result.invested")}
                  value={totalInvested}
                  sx={{ flexDirection: "column-reverse" }}
                  isLoading={!totalInvested}
                  withZeros={!!totalInvested}
                  scale={counterCurrDisplayedScale}
                  counterCurrency={counterCurrency}
                  baseCurrency={baseCurrency}
                  withoutUsd
                />
                <ValueItem
                  label={t("backtestDcaStrategy.result.difference")}
                  value={Math.abs(currentValueDifference)}
                  sx={{ flexDirection: "column-reverse", alignItems: "end" }}
                  isLoading={!currentValueDifference}
                  scale={counterCurrDisplayedScale}
                  counterCurrency={counterCurrency}
                  baseCurrency={baseCurrency}
                  withZeros={!!currentValueDifference}
                  prefix={currentValueDifference ? (currentValueDifference > 0 ? "+ " : "- ") : undefined}
                  withoutUsd
                />
              </Stack>
            </Stack>
          </SectionBox>
          <SectionBox>
            <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
              <ValueItem
                label={t("backtestDcaStrategy.result.averagePrice")}
                value={averagePrice}
                scale={priceScale}
                counterCurrency={counterCurrency}
                baseCurrency={baseCurrency}
                isLoading={!averagePrice}
                withoutSats
              />
              {fixedVsDynamic && fixedVsDynamic > 0 ? (
                <Box
                  p={1}
                  bgcolor={alpha(colors.success, 0.15)}
                  color={colors.success}
                  borderRadius={"10px"}
                  textAlign={"center"}>
                  <Typography fontWeight={600}>
                    <Trans
                      i18nKey="backtestDcaStrategy.result.better"
                      values={{ value: renderNumber(fixedVsDynamic, 0) }}
                    />
                  </Typography>
                </Box>
              ) : undefined}
            </Stack>
          </SectionBox>
        </Stack>
      </Stack>
    );
  };

  const resultSection = () => {
    const diff = Math.round(percentageChange(backtestData[backtestData.length - 1]?.apf, backtestData[backtestData.length - 1]?.apd)) * -1;

    return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
      {result(
        t("backtestDcaStrategy.result.dcaFixed"),
        backtestData[backtestData.length - 1]?.af,
        backtestData[backtestData.length - 1]?.vf,
        backtestData[backtestData.length - 1]?.if,
        backtestData[backtestData.length - 1]?.vfp,
        backtestData[backtestData.length - 1]?.apf
      )}
      {result(
        t("backtestDcaStrategy.result.dcaDynamic"),
        backtestData[backtestData.length - 1]?.ad,
        backtestData[backtestData.length - 1]?.vd,
        backtestData[backtestData.length - 1]?.id,
        backtestData[backtestData.length - 1]?.vdp,
        backtestData[backtestData.length - 1]?.apd,
        diff,
      )}
    </Stack>
  )};

  const getDynamicStrategySection = () => {
    if (isLimitsTransition) {
      return (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      );
    }

    return (
      <DynamicStrategySection
        type={type}
        setFieldValue={handleChangeSettings}
        minMultiplier={minMultiplier}
        maxMultiplier={maxMultiplier}
        top={limits.top}
        bottom={limits.bottom}
        center={limits.center}
        dynamicStrategyChart={points}
        dynamicStrategyChartOriginal={pointsOriginal}
        disabled={isLoading}
        mode={DcaBotMode.DCA_BUY}
      />
    );
  };

  return (
    <Tile isMain={true} header={t("backtestDcaStrategy.title")} m={{ xs: -2, md: 0 }}>
      <PageTitle i18nKey="backtestDcaStrategy.meta_title" />
      <Box p={2}>
        <Grid>
          <Alert severity="info">
            <AlertTitle>
              <Trans i18nKey="backtestDcaStrategy.alertTitle" />
            </AlertTitle>
            <Trans
              i18nKey="backtestDcaStrategy.alertText"
              components={{
                a: (
                  <Link
                    href="/proc-bitcoin/dynamicka-dca-strategie"
                    underline="always"
                    color={theme.palette.info.main}
                  />
                ),
              }}
            />
          </Alert>
        </Grid>
        <Grid container spacing={5} sx={{ pt: 3 }}>
          <Grid item lg={4} xs={12}>
            <Stack spacing={2}>
              <Select
                label={t("currencyPair")}
                value={currencyPair}
                onChange={(event) => setCurrencyPair(event.target.value as string)}>
                {Object.keys(BACKTEST_CURRENCY_PAIRS).map((currencyPair, index) => (
                  <MenuItem key={`currencyPair-${index}`} value={currencyPair}>
                    {currencyPair}
                  </MenuItem>
                ))}
              </Select>
              <Select
                label={t("backtestDcaStrategy.selectBack.label")}
                value={selectBack}
                onChange={handleChangeSelectBack}>
                {SELECT_BACK.map((value) => (
                  <MenuItem key={`select-back-${value}`} value={value}>
                    {t(`backtestDcaStrategy.selectBack.${value}`)}
                  </MenuItem>
                ))}
              </Select>
              {selectBack === "custom" && (
                <>
                  <LocalizedDatePicker
                    label={t("backtestDcaStrategy.dateFrom")}
                    value={dayjs(dateFrom)}
                    onChange={handleChangeDate("dateFrom")}
                    disabled={isLoading}
                    minDate={dayjs(MIN_DATE_FROM)}
                    maxDate={dayjs(dateTo)}
                    disableMaskedInput
                  />
                  <LocalizedDatePicker
                    label={t("backtestDcaStrategy.dateTo")}
                    value={dayjs(dateTo)}
                    onChange={handleChangeDate("dateTo")}
                    disabled={isLoading}
                    minDate={dayjs(dateFrom)}
                    maxDate={dayjs()}
                    disableMaskedInput
                  />
                </>
              )}
              <Typography variant="body2">
                <Trans i18nKey="backtestDcaStrategy.frequencyInfo" />
              </Typography>
              <PriceField
                fullWidth={true}
                autoComplete="off"
                onlyPositive
                maxLength={6}
                allowZero
                precision={counterCurrDisplayedScale}
                label={t("dcaBots.create.dynamicStrategyOrderValueOnBacktest")}
                value={marketOrderValue}
                onChange={(event) => setMarketOrderValue(event.target.value)}
                helperText={getDynamicStrategyMultiplierInfo()}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
                }}
              />
              <Typography variant="body2">
                <Trans i18nKey="backtestDcaStrategy.orderValueInfo" />
              </Typography>
              {isMobile && resultSection()}
              <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                {t("dcaBots.create.dynamicStrategy.settings")}
              </Typography>
              <Select
                label={t("dcaBots.create.dynamicStrategy.label")}
                value={type}
                size="small"
                onChange={(event) => setType(event.target.value as DcaDynamicStrategyType)}>
                {ALLOWED_DYNAMIC_STRATEGIES.map((strategy) => (
                  <MenuItem key={`dynamic-strategy-${strategy}`} value={strategy}>
                    {t(`dcaBots.create.dynamicStrategyLabel.types.${strategy}`)}
                  </MenuItem>
                ))}
                <MenuItem key={"other"} disabled>
                  {t("dcaBots.create.dynamicStrategyLabel.other")}
                </MenuItem>
              </Select>
              <Link
                href={ABOUT_DYNAMIC_STRATEGIES_LINK[type as keyof typeof ABOUT_DYNAMIC_STRATEGIES_LINK]}
                sx={{ color: theme.palette.secondary.contrastText, fontSize: "14px", display: "flex", alignItems: "center" }}
                target="_blank">
                {t("dcaBots.create.dynamicStrategy.link", { strategy: t(`dcaBots.create.dynamicStrategyLabel.typesShort.${type}`) })}
                <LaunchIcon sx={{ fontSize: "14px", ml: 0.5 }} />
              </Link>
              {getDynamicStrategySection()}
              {getOrderValueChart()}
            </Stack>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Stack spacing={2}>
              {!isMobile && resultSection()}
              {getPercentDiffChart()}
              {getPriceAndAverageChart()}
              {getTotalValueChart()}
              {getTotalInvestedChart()}
              {getTotalAmountChart()}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Tile>
  );
};

export default BacktestDcaStrategy;
