import * as React from "react";

import { Stack, Link, CircularProgress, Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import CenterWrapper from "components/elements/CenterWrapper";
import Modal from "components/elements/Modal";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPICoinmateDepositInfo, useAPIAnycoinDepositInfo } from "hooks/useAPI";
import { useTranslation, Trans } from "react-i18next";
import { ExchangeType } from "types/enums";
import { renderNumber } from "utils/formatter";

import IProps from "./types";

const depositLink = {
  COINMATE: "https://coinmate.io/pages/secured/deposit.page",
  ANYCOIN: "https://www.anycoin.cz/myaccount?section=funding",
};

const DepositInfo = ({ open, handleClose, id, exchangeEnum, functional }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const [currency, setCurrency] = React.useState("CZK");
  const { data: anycoinDepositInfo, isValidating: anycoinDepositInfoIsValidating } = useAPIAnycoinDepositInfo(
    id,
    exchangeEnum === ExchangeType.ANYCOIN && functional
  );
  const { data: coinmateDepositInfo, isValidating: coinmateDepositInfoIsValidating } = useAPICoinmateDepositInfo(
    id,
    exchangeEnum === ExchangeType.COINMATE && functional
  );

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newCurrency: string) => {
    if (!newCurrency) return;

    setCurrency(newCurrency);
  };

  const getAvailableBalance = () => {
    const data = anycoinDepositInfo ?? coinmateDepositInfo;
    if (!data) return "-";

    return renderNumber(data[`${currency.toLowerCase()}AvailableBalance` as keyof typeof data] as number);
  };

  const getBankAccountInfo = () => (
    <Alert severity="warning">
      <Trans
        i18nKey="exchangeConnections.deposit.bankAccountInfo"
        values={{ exchangeEnum: t(`exchangeConnections.deposit.${exchangeEnum}`) }}
      />
    </Alert>
  );

  const getDepositLinkInfo = () => (
    <Alert severity="info">
      <Trans
        i18nKey="exchangeConnections.deposit.depositLinkInfo"
        values={{ link: depositLink[exchangeEnum as keyof typeof depositLink] }}
        components={{
          a: (
            <Link
              style={{ color: "inherit", textDecorationColor: "inherit", wordBreak: "break-all" }}
              href={depositLink[exchangeEnum as keyof typeof depositLink]}
              target="_blank"
            />
          ),
        }}
      />
    </Alert>
  );

  const getBrokerageBankAccountInfo = () => (
    <Alert severity="warning">
      <Trans
        i18nKey="exchangeConnections.deposit.exchangeBankAccountInfo"
        values={{ link: depositLink[exchangeEnum as keyof typeof depositLink] }}
        components={{
          a: (
            <Link
              style={{ color: "inherit", textDecorationColor: "inherit", wordBreak: "break-all" }}
              href={depositLink[exchangeEnum as keyof typeof depositLink]}
              target="_blank"
            />
          ),
        }}
      />
    </Alert>
  );

  const getTransferInfoInfo = () => <Alert severity="info">{t("exchangeConnections.deposit.transferInfo")}</Alert>;

  const getBankDepositInfo = () => {
    const data = anycoinDepositInfo ?? coinmateDepositInfo;
    if (!data) return null;

    return (
      <Box sx={{ background: colors.legacyGray100, borderRadius: "10px", p: 2, mb: 1 }}>
        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
          <Stack spacing={2} width={{ xs: "100%", sm: "35%" }}>
            <Stack spacing={0.5} direction={"column"}>
              <Typography variant="body2" fontWeight={600}>
                {t("exchangeConnections.deposit.bankAccount")}
              </Typography>
              <Typography variant="body1">{data.czkBankAccount}</Typography>
            </Stack>
            <Stack spacing={0.5} direction={"column"}>
              <Typography variant="body2" fontWeight={600}>
                {t("exchangeConnections.deposit.variableSymbol")}
              </Typography>
              <Typography variant="body1">{data.czkVariableSymbol}</Typography>
            </Stack>
            <Stack spacing={0.5} direction={"column"}>
              <Typography variant="body2" fontWeight={600}>
                {t("exchangeConnections.deposit.specificSymbol")}
              </Typography>
              <Typography variant="body1">{data.czkSpecificSymbol}</Typography>
            </Stack>
          </Stack>
          <Stack spacing={2} width={{ xs: "100%", sm: "65%" }}>
            <Stack spacing={0.5} direction={"column"}>
              <Typography variant="body2" fontWeight={600}>
                {t("exchangeConnections.deposit.messageLabel")}
              </Typography>
              <Typography variant="body1">{t("exchangeConnections.deposit.messageText")}</Typography>
              <Typography variant="caption" sx={{ pt: 2 }}>
                <Trans i18nKey="exchangeConnections.deposit.messageAlert" />
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  };

  const getContent = () => {
    switch (exchangeEnum) {
      case ExchangeType.ANYCOIN:
        return (
          <>
            {getDepositLinkInfo()}
            {getTransferInfoInfo()}
            {getBankAccountInfo()}
          </>
        );
      case ExchangeType.COINMATE:
        if (currency === "EUR") {
          return (
            <>
              {getDepositLinkInfo()}
              {getBankAccountInfo()}
            </>
          );
        } else {
          return (
            <>
              {getBankDepositInfo()}
              {getBrokerageBankAccountInfo()}
              {getTransferInfoInfo()}
            </>
          );
        }
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      withCloseButton
      title={t("exchangeConnections.deposit.title", {
        exchangeEnum: t(`exchangeConnections.deposit.${exchangeEnum}`),
      })}>
      {coinmateDepositInfoIsValidating || anycoinDepositInfoIsValidating ? (
        <CenterWrapper>
          <CircularProgress color="primary" />
        </CenterWrapper>
      ) : (
        <Stack spacing={2}>
          <Stack spacing={2} direction={"row"} display={"flex"} alignItems={"center"}>
            <Typography variant="body2">{t("exchangeConnections.deposit.wantToDeposit")}</Typography>
            <ToggleButtonGroup size="small" color="primary" value={currency} exclusive onChange={handleChange}>
              <ToggleButton value="CZK">CZK</ToggleButton>
              <ToggleButton value="EUR">EUR</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Typography variant="body2">
            <Trans
              i18nKey="exchangeConnections.deposit.availableBalance"
              values={{
                currency,
                exchangeEnum: t(`exchangeConnections.deposit.${exchangeEnum}`),
                amount: getAvailableBalance(),
              }}
            />
          </Typography>
          {getContent()}
        </Stack>
      )}
    </Modal>
  );
};

export default DepositInfo;
