import { useEffect, useState } from "react";

import { SelectChangeEvent } from "@mui/material/Select";
import {
  createDcaBot,
  getCurrencyPairsTicker,
  updateDcaBot,
  getExchangeConnectionsBalancesCurrencies,
  getDcaBotsPreview,
} from "API/calls";
import { useDefaultLayoutContext } from "context/DefaultLayoutContext";
import { useUserDataContext } from "context/UserDataContext";
import { useFormik } from "formik";
import { useAPIExchangeConnectionsList, useAPIDcaBotsDetail, useAPIDcaBotsList } from "hooks/useAPI";
import useCurrencyPairs from "hooks/useCurrencyPairs";
import useDateTime from "hooks/useDateTime";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BotStrategyType, DcaBotMode, DcaBuyStrategyType, DcaDynamicStrategyType, ExchangeType, Side, SubscriptionType } from "types/enums";
import { IExchangeConnectionsDetail, IPoint } from "types/types";
import { getOrderTypeByBotStrategyType, isAtTierOrHigher, isEmptyArray, splitCurrencyPair } from "utils";
import { getNumberWithComma } from "utils/formatter";
import * as yup from "yup";

import { ILimitOrder } from "../LimitOrdersSection/types";
import { IExchangesCurrencyPairs, IObject } from "./types";

const getInitNumber = (value?: number) => {
  if (value) return getNumberWithComma(value);

  return value;
};

const getInitLimitOrders = (orders: ILimitOrder[] | undefined) => {
  if (orders) return orders.map((order) => ({ ...order, value: getNumberWithComma(order.value) }));

  return [
    {
      value: undefined,
      percentChange: 1,
      executeAsMarketWhenNotFilled: true,
    },
  ];
};

const compareLimitOrders = (arr1: ILimitOrder[], arr2: ILimitOrder[]) => {
  if (arr1.length !== arr2.length)  return false;

  for (let i = 0; i < arr1.length; i++) {
    const value1 = Number(arr1[i].value);
    const value2 = Number(arr2[i].value);

    if (value1 !== value2
        || arr1[i].percentChange !== arr2[i].percentChange
        || arr1[i].executeAsMarketWhenNotFilled !== arr2[i].executeAsMarketWhenNotFilled) {
      return false;
    }
  }

  return true;
}

export const DEFAULT_DYNAMIC_STRATEGY_VALUES = {
  [DcaDynamicStrategyType.BTC_NUPL]: {
    DCA_BUY: {
      top: 100,
      center: 50,
      bottom: 0,
    },
    DCA_SELL: {
      top: 80,
      center: 50,
      bottom: 20,
    },
  },
  [DcaDynamicStrategyType.BTC_FEAR_AND_GREED]: {
    DCA_BUY: {
      top: 100,
      center: 60,
      bottom: 20,
    },
    DCA_SELL: {
      top: 100,
      center: 60,
      bottom: 20,
    },
  }
};

const useNewDcaBotData = (id?: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getTomorrow, getTomorrowDay } = useDateTime();
  const { getLimit, getDcaBotSellModeInFreeSubscription } = useExchangesMetadata("", "");
  const { refreshMenu } = useDefaultLayoutContext();

  //STATE
  const [selectedExchange, setSelectedExchange] = useState<ExchangeType | string>("");
  const [changedFrequencyByUser, setChangedFrequencyByUser] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [askPrice, setAskPrice] = useState<number | undefined>(undefined);
  const [initialRunEnabled, setInitialRunEnabled] = useState<boolean>(false);
  const [availableBalance, setAvailableBalance] = useState<number>(0);
  const [limitOrdersSum, setLimitOrdersSum] = useState<number>(0);
  const [currentDynamicStrategyMultiplier, setCurrentDynamicStrategyMultiplier] = useState<number>();
  const [dynamicStrategyChart, setDynamicStrategyChart] = useState<IPoint[]>([]);
  const [hasEditedLimitOrders, setHasEditedLimitOrders] = useState<boolean>(false);
  const [isDefaultValuesTransition, setIsSetDefaultValuesTransition] = useState<boolean>(false);

  //API SECTION
  const { data: currencyPairs } = useCurrencyPairs();
  const { data: dcaBotsData } = useAPIDcaBotsList();
  const { data: dcaBotDetailData, mutate, isValidating } = useAPIDcaBotsDetail(id, false);
  const [exchangesCurrencyPairs, setExchangesCurrencyPairs] = useState<IExchangesCurrencyPairs>({});
  const { data: userExchangeConnections } = useAPIExchangeConnectionsList(false);
  const { subscriptionType } = useUserDataContext();

  //FORMIK/YUP SECTION
  const initValues = {
    exchangeConnectionId: "",
    currencyPair: "",
    label: dcaBotDetailData?.label ?? "",
    savingsTarget: getInitNumber(dcaBotDetailData?.savingsTarget),
    frequency: dcaBotDetailData?.frequency ?? "W",
    frequencyParameter: dcaBotDetailData?.frequencyParameter ?? getTomorrowDay(),
    strategyType: dcaBotDetailData?.strategy?.type ?? BotStrategyType.MARKET,
    buyStrategyType: dcaBotDetailData?.strategy?.dynamicStrategy
      ? DcaBuyStrategyType.DYNAMIC
      : DcaBuyStrategyType.FIXED,
    marketOrderValue: getInitNumber(dcaBotDetailData?.strategy?.marketOrderValue),
    limitOrders: getInitLimitOrders(dcaBotDetailData?.strategy?.limitOrders),
    initialRun: false,
    dynamicStrategyType: dcaBotDetailData?.strategy?.dynamicStrategy?.type ?? undefined,
    mode: dcaBotDetailData?.mode ?? "",
    dynamicStrategySettings: {
      minMultiplier: dcaBotDetailData?.strategy?.dynamicStrategy?.minMultiplier ? dcaBotDetailData?.strategy?.dynamicStrategy?.minMultiplier : 0.5,
      maxMultiplier:  dcaBotDetailData?.strategy?.dynamicStrategy?.maxMultiplier ? dcaBotDetailData?.strategy?.dynamicStrategy?.maxMultiplier : 3,
      top: dcaBotDetailData?.strategy?.dynamicStrategy?.top ?? 100,
      bottom: dcaBotDetailData?.strategy?.dynamicStrategy?.bottom ?? 0,
      center: dcaBotDetailData?.strategy?.dynamicStrategy?.center ?? 50,
    },
  };

  const validationOrderValue = (value?: string, context?: any) => {
    const { from } = context;
    const val = value ? Number(value.replace(",", ".")) : undefined;

    if (Array.isArray(from) && selectedExchange) {
      let currencyPair = "";
      let strategyType: BotStrategyType | undefined = undefined;

      from.forEach((element) => {
        if (!currencyPair) currencyPair = element?.value?.currencyPair;
        if (!strategyType) strategyType = element?.value?.strategyType;
      });

      const { counterCurrency } = splitCurrencyPair(currencyPair);
      const orderType = getOrderTypeByBotStrategyType(strategyType);
      const minOrderValue = getLimit(Side.BUY, "min", "counter", currencyPair, selectedExchange, orderType) || 0;
      const maxOrderValue = getLimit(Side.BUY, "max", "counter", currencyPair, selectedExchange, orderType);

      if (minOrderValue && minOrderValue >= 0) {
        if (!val || val < minOrderValue) {
          return context.createError({
            message: t("minOrderValues", { value: minOrderValue, currency: counterCurrency }),
          });
        }
      }
      if (maxOrderValue && val && val > maxOrderValue) {
        return context.createError({
          message: t("maxOrderValues", { value: maxOrderValue, currency: counterCurrency }),
        });
      }
      if (val === 0) {
        return context.createError({
          message: t("minOrderValuesFallback", { value: 0, currency: counterCurrency }),
        });
      }
      if (!val) {
        return context.createError({
          message: t("fieldRequired"),
        });
      }

      if (val && val?.toString().length > 19) {
        return context.createError({
          message: t("fieldMaxLength", { max: 19 }),
        });
      }
    }

    return true;
  };

  const validationMode = (value?: DcaBotMode, context?: any) => {
    const { parent } = context;

    if (parent && subscriptionType === SubscriptionType.FREE && value === DcaBotMode.DCA_SELL) {
      const exhcangeEnum = userExchangeConnections?.exchangeConnections.find((e) => e.id === parent.exchangeConnectionId)?.exchangeEnum;

      if (!exhcangeEnum) return true;
      return getDcaBotSellModeInFreeSubscription(exhcangeEnum);
    }

    return true;
  };

  const prepareLimitOrder = (limitOrders: ILimitOrder[]) => {
    return limitOrders.map((order) => {
      return { ...order, value: order.value ? `${order.value}`.replace(",", ".") : undefined };
    });
  };

  const validationSchemaBase = yup.object().shape({
    exchangeConnectionId: yup.string().required(t("fieldRequired")),
    currencyPair: yup.string().required(t("fieldRequired")),
    mode: yup.mixed().test("modeFreeError", t("dcaBots.create.modeFreeError"), validationMode),
    label: yup.string().max(50, t("fieldMaxLength", { max: 50 })),
    savingsTarget: yup
      .string()
      .test("length", t("fieldMaxLength", { max: 19 }), (val) => (val ? val?.toString().length <= 19 : true)),
  });

  const validationSchemaExtended = validationSchemaBase.concat(
    yup.object().shape({
      frequency: yup.string().required(t("fieldRequired")),
      frequencyParameter: yup.number().positive().integer(t("fieldErrors.integerNumber")).required(t("fieldRequired")),
      strategyType: yup.string().required(t("fieldRequired")),
      marketOrderValue: yup.string().when("strategyType", {
        is: BotStrategyType.MARKET,
        then: yup.string().test("minOrderValue", "minOrderValueError", validationOrderValue),
      }),
      limitOrders: yup.array().when("strategyType", {
        is: BotStrategyType.LIMIT,
        then: yup.array().of(
          yup.object().shape({
            value: yup.string().test("minOrderValue", "minOrderValueError", validationOrderValue),
            percentChange: yup
              .number()
              .positive(t("fieldErrors.positiveNumber"))
              .integer()
              .required(t("fieldRequired")),
            executeAsMarketWhenNotFilled: yup.boolean().required(t("fieldRequired")),
          })
        ),
      }),
      buyStrategyType: yup.string().required(t("fieldRequired")),
      dynamicStrategyType: yup.string().when("buyStrategyType", {
        is: DcaBuyStrategyType.DYNAMIC,
        then: yup.string().notOneOf([DcaDynamicStrategyType.EMPTY], t("fieldRequired")).required(t("fieldRequired")),
      }),
      dynamicStrategySettings: yup.object().when("dynamicStrategyType", {
        is: (value: DcaDynamicStrategyType) => value !== undefined && value !== DcaDynamicStrategyType.EMPTY,
        then: yup.object().shape({
          minMultiplier: yup.string().required(t("fieldRequired")),
          maxMultiplier: yup.string().required(t("fieldRequired")),
          top: yup.string().required(t("fieldRequired")),
          bottom: yup.string().required(t("fieldRequired")),
          center: yup.string().required(t("fieldRequired")),
        }),
      }),
    })
  );

  const [validationSchema, setValidationSchema] = useState<yup.AnyObjectSchema>(validationSchemaExtended);

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const {
        strategyType,
        marketOrderValue,
        limitOrders,
        savingsTarget,
        dynamicStrategyType,
        buyStrategyType,
        dynamicStrategySettings,
        ...rest
      } = values;
      const processFormData = dcaBotDetailData ? handleUpdate : handleCreate;

      if (rest.mode === DcaBotMode.MANUAL) {
        processFormData({
          mode: rest.mode,
          exchangeConnectionId: rest.exchangeConnectionId,
          currencyPair: rest.currencyPair,
          label: rest.label,
          savingsTarget: savingsTarget ? `${savingsTarget}`.replace(",", ".") : undefined,
          frequency: "M",
          frequencyParameter: 1,
          strategy: {
            type: BotStrategyType.MARKET,
          },
        });
      } else {
        let dynamicStrategy = null;

        if (buyStrategyType === DcaBuyStrategyType.DYNAMIC) {
          dynamicStrategy = {
            type: dynamicStrategyType,
            minMultiplier: dynamicStrategySettings.minMultiplier,
            maxMultiplier: dynamicStrategySettings.maxMultiplier,
            bottom: `${dynamicStrategySettings.bottom}`.replace(",", "."),
            center: `${dynamicStrategySettings.center}`.replace(",", "."),
            top: `${dynamicStrategySettings.top}`.replace(",", "."),
          };
        }

        if (strategyType === BotStrategyType.LIMIT) {
          processFormData({
            ...rest,
            savingsTarget: savingsTarget ? `${savingsTarget}`.replace(",", ".") : undefined,
            strategy: {
              type: strategyType,
              limitOrders: prepareLimitOrder(limitOrders),
              dynamicStrategy,
            },
          });
        }
        if (strategyType === BotStrategyType.MARKET) {
          processFormData({
            ...rest,
            savingsTarget: savingsTarget ? `${savingsTarget}`.replace(",", ".") : undefined,
            strategy: {
              type: strategyType,
              marketOrderValue: marketOrderValue ? `${marketOrderValue}`.replace(",", ".") : undefined,
              dynamicStrategy,
            },
          });
        }
      }
    },
  });

  const hasExchangeConnections = !isEmptyArray(userExchangeConnections?.exchangeConnections);
  const isExistingBot = Boolean(id);
  const initialRunVisible
    = formik.values.currencyPair && formik.values.strategyType === BotStrategyType.LIMIT && !isExistingBot && formik.values.mode !== DcaBotMode.DCA_SELL;

  useEffect(() => {
    if (!isValidating && dcaBotDetailData === null) mutate();
  }, [isValidating]);

  const findFirstFunctionalExchangeConnection = (connections: IExchangeConnectionsDetail[]) => connections.find((item) => item.functional);

  useEffect(() => {
    if (formik.values.mode) {
      if (formik.values.mode === DcaBotMode.MANUAL) setValidationSchema(validationSchemaBase);
      else setValidationSchema(validationSchemaExtended);
    }
  }, [formik.values.mode]);


  const checkDefaultValue = (value: { top: number; center: number; bottom: number }) => {
    return Object.keys(DEFAULT_DYNAMIC_STRATEGY_VALUES).some((strategyType) => {
      const strategy = DEFAULT_DYNAMIC_STRATEGY_VALUES[strategyType as keyof typeof DEFAULT_DYNAMIC_STRATEGY_VALUES];

      return ['DCA_BUY', 'DCA_SELL'].some((operationType) => {
        const defaultValue = strategy[operationType as keyof typeof strategy];

        return defaultValue
               && defaultValue.top === value.top
               && defaultValue.center === value.center
               && defaultValue.bottom === value.bottom;
      });
    });
  }

  const handleSetDefaultValues = async ({ bottom, center, top }: { top: number; center: number; bottom: number }) => {
    setIsSetDefaultValuesTransition(true);
    await formik.setFieldValue("dynamicStrategySettings.bottom", bottom);
    await formik.setFieldValue("dynamicStrategySettings.center", center);
    await formik.setFieldValue("dynamicStrategySettings.top", top);
    setIsSetDefaultValuesTransition(false);
  };

  useEffect(() => {
    if (formik.values.dynamicStrategyType && formik.values.dynamicStrategyType !== DcaDynamicStrategyType.EMPTY) {
      const DEFAULT_VALUES_STRATEGY = DEFAULT_DYNAMIC_STRATEGY_VALUES[formik.values.dynamicStrategyType as keyof typeof DEFAULT_DYNAMIC_STRATEGY_VALUES];

      if (formik.values.mode === DcaBotMode.DCA_SELL) {
        if (checkDefaultValue(formik.values.dynamicStrategySettings)) {
          handleSetDefaultValues(DEFAULT_VALUES_STRATEGY.DCA_SELL);
        }
      }

      if (formik.values.mode === DcaBotMode.DCA_BUY) {
        if (checkDefaultValue(formik.values.dynamicStrategySettings)) {
          handleSetDefaultValues(DEFAULT_VALUES_STRATEGY.DCA_BUY);
        }
      }
    }
  }, [formik.values.mode, formik.values.dynamicStrategyType]);

  useEffect(() => {
    if (id && !dcaBotDetailData) return;

    if (userExchangeConnections?.exchangeConnections && subscriptionType) {
      if (isEmptyArray(userExchangeConnections.exchangeConnections)) return;

      const uniqueExchangeEnums = [
        ...new Set(userExchangeConnections.exchangeConnections.map((item) => item.exchangeEnum)),
      ];

      const pairs: IObject = {};
      if (dcaBotDetailData) {
        const currentExchange = dcaBotDetailData.exchangeConnection.exchangeEnum;
        pairs[currentExchange] = currencyPairs
          .filter((item) => item.id === currentExchange)[0]
          .data.map((item: any) => ({ key: item.key, enabledDynamicStrategies: item.enabled_dca_dynamic_strategies }));
      } else {
        uniqueExchangeEnums.forEach((exchange) => {
          pairs[exchange] = currencyPairs
            .filter((item) => item.id === exchange)[0]
            .data.filter((item: any) => {
              return item.enabled && isAtTierOrHigher(subscriptionType, item.subscription_type);
            })
            .map((item: any) => ({ key: item.key, enabledDynamicStrategies: item.enabled_dca_dynamic_strategies }));
        });
      }
      setExchangesCurrencyPairs(pairs);

      if (dcaBotDetailData) {
        formik.setFieldValue("currencyPair", dcaBotDetailData.currencyPair);
        formik.setFieldValue("exchangeConnectionId", dcaBotDetailData.exchangeConnection.id);
        setSelectedExchange(dcaBotDetailData.exchangeConnection.exchangeEnum);
      } else {
        const exchangeConnection = findFirstFunctionalExchangeConnection(userExchangeConnections.exchangeConnections);

        if(exchangeConnection) {
          formik.setFieldValue("exchangeConnectionId", exchangeConnection.id);
          setSelectedExchange(exchangeConnection.exchangeEnum);
        }
      }
    }
  }, [userExchangeConnections, dcaBotDetailData]);

  useEffect(() => {
    if (!changedFrequencyByUser) return;

    switch (formik.values.frequency) {
      case "M":
        formik.setFieldValue("frequencyParameter", getTomorrow());
        break;
      case "W":
        formik.setFieldValue("frequencyParameter", getTomorrowDay());
        break;
      case "D":
        formik.setFieldValue("frequencyParameter", 3);
        break;
    }
  }, [formik.values.frequency]);

  useEffect(() => {
    if (formik.values.currencyPair && selectedExchange) {
      (async () => {
        const currencyPair = splitCurrencyPair(formik.values.currencyPair);
        try {
          const ticker = (
            await getCurrencyPairsTicker({
              exchangeType: selectedExchange as ExchangeType,
              currencyPair: `${currencyPair.baseCurrency}-${currencyPair.counterCurrency}`,
            })
          ).data;
          setAskPrice(ticker.askPrice);
        } catch {
          // no ticker data
        }
      })();
    }
  }, [formik.values.currencyPair, selectedExchange]);

  useEffect(() => {
    if (formik.values.currencyPair) {
      (async () => {
        const currencyPair = splitCurrencyPair(formik.values.currencyPair);
        try {
          const response = await getExchangeConnectionsBalancesCurrencies(
            formik.values.exchangeConnectionId,
            currencyPair.counterCurrency
          );
          setAvailableBalance(response.data.balances[currencyPair.counterCurrency].available);
        } catch {
          // silent
        }
      })();
    }
  }, [formik.values.currencyPair]);

  useEffect(() => {
    if (formik.values.currencyPair && formik.values.limitOrders && selectedExchange && !isExistingBot) {
      const ordersSum = formik.values.limitOrders
        .map((item) => item.value)
        .filter((item) => item !== undefined && item !== null)
        .map((item) => (item as unknown as string).replace(",", "."))
        .map((item) => Number(item))
        .reduce((total, current) => {
          return total + current;
        }, 0);

      if (formik.values.buyStrategyType === DcaBuyStrategyType.DYNAMIC && currentDynamicStrategyMultiplier) {
        setLimitOrdersSum(ordersSum * currentDynamicStrategyMultiplier);
      } else setLimitOrdersSum(ordersSum);

      const hasEnoughAvailableBalance = availableBalance >= ordersSum && availableBalance > 0;
      setInitialRunEnabled(hasEnoughAvailableBalance);
      formik.setFieldValue("initialRun", hasEnoughAvailableBalance);
    }
  }, [formik.values.limitOrders, selectedExchange, isExistingBot, availableBalance, formik.values.buyStrategyType]);

  useEffect(() => {
    if (isExistingBot && dcaBotDetailData && formik.values.strategyType === BotStrategyType.LIMIT) {
      let result = false;

      if(dcaBotDetailData.strategy.type === BotStrategyType.MARKET) result = true;
      if(!compareLimitOrders(formik.values.limitOrders, dcaBotDetailData.strategy.limitOrders ?? [])) result = true;

      setHasEditedLimitOrders(result);
    }
  }, [formik.values.strategyType, formik.values.limitOrders]);

  const hasLimitDynamicAlert = () => {
    return (
      formik.values.strategyType === BotStrategyType.LIMIT
      && formik.values.buyStrategyType === DcaBuyStrategyType.DYNAMIC
      && ((formik.values.frequency === "D" && formik.values.frequencyParameter > 3)
        || formik.values.frequency === "W"
        || formik.values.frequency === "M")
    );
  };

  useEffect(() => {
    if (
      formik.values.buyStrategyType === DcaBuyStrategyType.DYNAMIC
      && formik.values.dynamicStrategyType !== DcaDynamicStrategyType.EMPTY
    ) {
      const isDynamicStrategyFilled = Object.values(formik.values.dynamicStrategySettings).every((value) => value !== undefined);

      if (isDynamicStrategyFilled && formik.values.currencyPair && formik.values.exchangeConnectionId && !hasLimitDynamicAlert()) {
        (async () => {
          try {
            let strategy: any = {
              type: formik.values.strategyType,
              dynamicStrategy: {
                type: formik.values.dynamicStrategyType,
                minMultiplier: formik.values.dynamicStrategySettings.minMultiplier,
                maxMultiplier: formik.values.dynamicStrategySettings.maxMultiplier,
                bottom: `${formik.values.dynamicStrategySettings.bottom}`.replace(",", "."),
                center: `${formik.values.dynamicStrategySettings.center}`.replace(",", "."),
                top: `${formik.values.dynamicStrategySettings.top}`.replace(",", "."),
              },
            };

            if (formik.values.strategyType === BotStrategyType.LIMIT) {
              strategy = { ...strategy, limitOrders: prepareLimitOrder(formik.values.limitOrders) };
            }

            if (formik.values.strategyType === BotStrategyType.MARKET) {
              strategy = {
                ...strategy,
                marketOrderValue: formik.values.marketOrderValue
                  ? `${formik.values.marketOrderValue}`.replace(",", ".")
                  : undefined,
              };
            }

            const response = await getDcaBotsPreview({
              mode: formik.values.mode,
              exchangeConnectionId: formik.values.exchangeConnectionId,
              currencyPair: formik.values.currencyPair,
              frequency: formik.values.frequency,
              frequencyParameter: formik.values.frequencyParameter,
              strategy,
            });

            setCurrentDynamicStrategyMultiplier(response.data.currentDynamicStrategyMultiplier);
            setDynamicStrategyChart(response.data.dynamicStrategyChart.points);
          } catch {
            // silent
          }
        })();
      }
    }
  }, [formik.values.dynamicStrategySettings, formik.values.buyStrategyType, formik.values.currencyPair, formik.values.dynamicStrategyType, formik.values.limitOrders, formik.values.marketOrderValue, formik.values.strategyType, formik.values.frequencyParameter]);

  useEffect(() => {
    if (formik.values.mode === DcaBotMode.DCA_SELL && formik.values.initialRun === true) {
      formik.setFieldValue("initialRun", false);
    }
  }, [formik.values.mode]);

  const redirectToDetail = (id: string) => {
    let targetPath = `/strategie/${id}`;
    if (formik.values.initialRun && formik.values.mode !== DcaBotMode.MANUAL) {
      targetPath += "?initRun=1";
    }

    navigate(targetPath);
  }

  const handleCreate = async (body: object) => {
    setIsProcessing(true);

    try {
      const data = (await createDcaBot(body)).data;
      enqueueSnackbar(t("dcaBots.create.successMessage"), { variant: "success" });
      refreshMenu?.();
      redirectToDetail(data.id);
    } catch (error) {
      enqueueSnackbar(t("dcaBots.create.errorMessage"), { variant: "error" });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdate = async (body: object) => {
    if (!id || !dcaBotDetailData) return;

    setIsProcessing(true);
    try {
      await updateDcaBot(id, body);
      enqueueSnackbar(t("dcaBots.create.editSuccessMessage"), { variant: "success" });
      refreshMenu?.();
      redirectToDetail(id);
    } catch (error) {
      enqueueSnackbar(t("dcaBots.create.editErrorMessage"), { variant: "error" });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleChangeExchangeConnection = (event: SelectChangeEvent<unknown>) => {
    setSelectedExchange(
      userExchangeConnections?.exchangeConnections.find((connection) => connection.id === event.target.value)
        ?.exchangeEnum ?? ""
    );
    formik.setFieldValue("currencyPair", "");
    formik.handleChange(event);
  };

  const handleFrequencyChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setChangedFrequencyByUser(true);
    formik.handleChange(event);
  };

  return {
    userExchangeConnections,
    selectedExchange,
    availableBalance,
    limitOrdersSum,
    exchangesCurrencyPair: exchangesCurrencyPairs[selectedExchange] ?? [],
    formik,
    exchangeConnectionSelectProps: {
      id: "exchangeConnectionId",
      name: "exchangeConnectionId",
      label: t("dcaBots.create.availableExchangeConnections"),
      value: formik.values.exchangeConnectionId ?? "",
      onChange: handleChangeExchangeConnection,
      disabled: isExistingBot,
      error: !hasExchangeConnections
        ? t("dcaBots.create.noExchangeConnectionData") ?? ""
        : formik.touched.exchangeConnectionId && formik.errors.exchangeConnectionId,
    },
    currencyPairSelectProps: {
      id: "currencyPair",
      name: "currencyPair",
      label: t("dcaBots.create.currencyPair"),
      value: formik.values.currencyPair ?? "",
      onChange: formik.handleChange,
      error: formik.touched.currencyPair && formik.errors.currencyPair,
    },
    labelFieldProps: {
      fullWidth: true,
      id: "label",
      name: "label",
      value: formik.values.label,
      onChange: formik.handleChange,
      error: Boolean(formik.errors.label),
      helperText: formik.errors.label,
    },
    savingsTargetFieldProps: {
      fullWidth: true,
      onChange: formik.handleChange,
      id: "savingsTarget",
      name: "savingsTarget",
      value: formik.values.savingsTarget ?? "",
      error: Boolean(formik.errors.savingsTarget),
      helperText: formik.errors.savingsTarget,
    },
    handleFrequencyChange,
    isValidating,
    dcaBotsData,
    isProcessing,
    askPrice,
    isExistingBot,
    initialRunVisible,
    initialRunEnabled,
    initialRunProps: {
      onChange: formik.handleChange,
      id: "initialRun",
      name: "initialRun",
      checked: formik.values.initialRun,
    },
    currentDynamicStrategyMultiplier,
    dynamicStrategyChart,
    hasLimitDynamicAlert: hasLimitDynamicAlert(),
    hasEditedLimitOrders,
    isDefaultValuesTransition,
  };
};

export default useNewDcaBotData;
