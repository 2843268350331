import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CardProps,
  Collapse,
  Grid,
  Link,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card2 from "components/elements/Card2";
import SectionCard from "components/elements/SectionCard";
import { CoinsStacked, BarLineChart } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { usePopupState, bindTrigger, bindPopover, PopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SubscriptionType } from "types/enums";

import SubscriptionLimitMsg from "../SubscriptionLimitMsg";

interface IProps extends CardProps {
  header: string | React.ReactNode;
  isDisabled?: boolean;
  canAddNewDcaBot?: boolean;
  canAddNewBot?: boolean;
}

const ListWrapper: React.FC<IProps> = ({ header, children, isDisabled, canAddNewBot, canAddNewDcaBot }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { subscriptionPausedError, subscriptionType } = useUserDataContext();
  const { enqueueSnackbar } = useSnackbar();
  const popupState = usePopupState({ variant: "popover", popupId: "addNewBot" });
  const popupDcaState = usePopupState({ variant: "popover", popupId: "addNewDcaBot" });
  const [isCreateNew, setIsCreateNew] = useState<boolean>(false);
  const { colors } = useMUIThemeModeContext();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(320));

  const actions = (
    <Stack sx={{ color: "black" }} spacing={1} direction={{ xs: "column", sm: "row" }}>
      <Button
        variant="contained"
        size="small"
        color={isCreateNew ? "inherit" : "primary"}
        onClick={() => setIsCreateNew(!isCreateNew)}>
        {isCreateNew ? t("cancel") : t(`bots.createNew${isSmallScreen ? "Short" : ""}`)}
      </Button>
    </Stack>
  );

  const handleAddButton = (to: string) => {
    if (isCreateNew) setIsCreateNew(false);
    navigate(to);
  };

  const getAddNewBotButton = (title: string, to: string) => {
    if (subscriptionPausedError) {
      return (
        <Button
          onClick={() => enqueueSnackbar(t("dcaBots.addNewSubscriptionPausedError"), { variant: "error" })}
          startIcon={<AddIcon />}
          variant="contained">
          {title}
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color={"primary"}
        startIcon={<AddIcon />}
        onClick={() => handleAddButton(to)}>
        {title}
      </Button>
    );
  };

  const getButton = (title: string, to: string, id: string, state: PopupState, canAdd?: boolean) => (
    <Box display={"flex"} alignItems={"flex-end"} flexDirection={"column"}>
      {canAdd ? (
        getAddNewBotButton(title, to)
      ) : (
        <>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            {...bindTrigger(state)}>
            {title}
          </Button>
          <Popover
            {...bindPopover(state)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}>
            <Box sx={{ p: 2 }}>
              {subscriptionType && <SubscriptionLimitMsg id={id} subscriptionType={subscriptionType} />}
            </Box>
          </Popover>
        </>
      )}
    </Box>
  );

  const newBotCard = (
    icon: React.ReactNode,
    title: string,
    subtitle1: string,
    subtitle2: JSX.Element,
    subtitle3: JSX.Element,
    buttonTitle: string,
    to: string,
    id: string,
    state: PopupState,
    canAdd?: boolean
  ) => (
    <Card2
      background={colors.gray50}
      height={"100%"}
      textAlign={"center"}
      border={`1px dashed ${colors.gray300}`}
      childrenBoxSx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        {icon}
        <Typography fontWeight={600}>{title}</Typography>
        <Typography variant="subtitle2" fontWeight={400} color={colors.gray600}>
          {subtitle1}
        </Typography>
        <Typography variant="subtitle2" fontWeight={400} color={colors.gray600}>
          {subtitle2}
        </Typography>
      </Stack>
      <Box pt={3} justifyContent={"center"} display="flex">
        {getButton(buttonTitle, to, id, state, canAdd)}
      </Box>
      <Typography variant="subtitle2" fontWeight={400} color={colors.gray600} pt={3}>
        {subtitle3}
      </Typography>
    </Card2>
  );

  return (
    <SectionCard
      id={isDisabled ? undefined : "moje-strategie"}
      title={children ? header : t("bots.listHeader.empty")}
      actions={children && !isDisabled ? actions : undefined}>
      {!isDisabled && (
        <Collapse in={!children || isCreateNew}>
          <Card2 mb={children ? 2 : 0}>
            {subscriptionType === SubscriptionType.FREE && (
              <Alert severity="info" sx={{ mb: 3, "@media (max-width:400px)": { fontSize: ".8rem" } }}>
                <AlertTitle>
                  <Trans i18nKey="bots.create.alertTitle" />
                </AlertTitle>
                <Trans
                  i18nKey="bots.create.alertText"
                  components={{
                    a0: <Link href={"/proc-bitcoin/dynamicka-dca-strategie"} color={theme.palette.info.main} />,
                    a1: <Link href={"/predplatne"} color={theme.palette.info.main} />,
                    a2: <Link href={"/stosaky"} color={theme.palette.info.main} />,
                    a3: <Link href={"/stosaky#odznaky"} />,
                  }}
                />
              </Alert>
            )}
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                {newBotCard(
                  <CoinsStacked sx={{ fontSize: 30, mb: 1 }} />,
                  t("bots.dcaBot.title"),
                  t("bots.dcaBot.subtitle1"),
                  <Trans i18nKey={"bots.dcaBot.subtitle2"} />,
                  <Trans
                    i18nKey={"bots.dcaBot.subtitle3"}
                    components={{
                      a1: (
                        <Link
                          color={theme.palette.info.main}
                          href="/proc-bitcoin/dynamicka-dca-strategie"
                        />
                      ),
                      a2: (
                        <Link
                          color={theme.palette.info.main}
                          href="/backtest-dca-strategie"
                        />
                      ),
                    }}
                  />,
                  t("bots.dcaBot.add"),
                  "/nova-strategie",
                  "dcaBots",
                  popupDcaState,
                  canAddNewDcaBot
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {newBotCard(
                  <BarLineChart sx={{ fontSize: 30, mb: 1 }} />,
                  t("bots.bot.title"),
                  t("bots.bot.subtitle1"),
                  <Trans i18nKey={"bots.bot.subtitle2"} />,
                  <Trans
                    i18nKey={"bots.bot.subtitle3"}
                    components={{
                      a1: (
                        <Link
                          color={theme.palette.info.main}
                          href="/proc-bitcoin/predstaveni-grid-strategie"
                        />
                      ),
                      a2: (
                        <Link
                          color={theme.palette.info.main}
                          href="/proc-bitcoin/detailni-vysvetleni-pouziti-a-fungovani-grid-strategie"
                        />
                      ),
                      a3: (
                        <Link
                          color={theme.palette.info.main}
                          href="/backtest-grid-strategie"
                        />
                      ),
                    }}
                  />,
                  t("bots.bot.add"),
                  "/nova-grid-strategie",
                  "bots",
                  popupState,
                  canAddNewBot
                )}
              </Grid>
            </Grid>
          </Card2>
        </Collapse>
      )}
      {children}
    </SectionCard>
  );
};

export default ListWrapper;
