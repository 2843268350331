import React from "react";

import { ColoredBox } from "./styles";
import IProps from "./types";

const Badge: React.FC<IProps> = ({ color, children }) => {
  return <ColoredBox customColor={color}>{children}</ColoredBox>;
};

export default Badge;
