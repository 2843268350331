import { useState } from "react";

import { Alert, AlertTitle, Button, InputAdornment, Stack, TextField, Typography, useTheme, Link } from "@mui/material";
import { dcaBotsCreateManualOrder, dcaBotsUpdateManualOrder } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import FormToggleButton from "components/elements/FormToggleButton";
import LocalizedDateTimePicker from "components/elements/LocalizedDateTimePicker";
import Modal from "components/elements/Modal";
import PriceField from "components/elements/PriceField";
import TooltipFreeUserUpsell from "components/elements/TooltipFreeUserUpsell";
import { Diamond } from "components/icons";
import { CreditInverted } from "components/icons/components/CreditInverted";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import dayjs from "dayjs";
import { useFormik } from "formik";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { OrderType, Side, SubscriptionType } from "types/enums";
import { ConditionalWrapper } from "utils";
import { renderNumber } from "utils/formatter";
import * as yup from "yup";

interface IProps {
  id: string;
  handleClose: () => void;
  baseCurrency: string;
  counterCurrency: string;
  exchangeEnum: string;
  refreshData: () => void;
  data?: IManualOrder;
}

export interface IManualOrder {
  side: Side | string;
  type: OrderType | string;
  baseCurrencyAmount: string;
  counterCurrencyValue: string;
  time: Date | null;
  note?: string;
  orderId?: string;
}

interface IManualOrderRequest extends Omit<IManualOrder, "time">{
  time?: string;
}

const ManualOrderModal = ({
  id,
  handleClose,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  refreshData,
  data,
}: IProps) => {
  const { t } = useTranslation();
  const { subscriptionType } = useUserDataContext();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const { enqueueSnackbar } = useSnackbar();
  const { getUSDPrice } = useExchangeRate();
  const isPremiumUser = subscriptionType === SubscriptionType.PREMIUM;
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { currencyPairPriceScale, counterCurrScale, currencyPairBaseScale } = useExchangesMetadata(exchangeEnum, `${baseCurrency}/${counterCurrency}`);

  const formik = useFormik<IManualOrder>({
    initialValues: {
      side: data?.side ?? Side.BUY,
      type: data?.type ?? OrderType.MARKET,
      baseCurrencyAmount: data?.baseCurrencyAmount ?? "",
      counterCurrencyValue: data?.counterCurrencyValue ?? "",
      time: data?.time ?? null,
      note: data?.note ?? "",
    },
    validationSchema: yup.object({
      side: yup.string().required(t("fieldRequired")),
      type: yup.string().required(t("fieldRequired")),
      baseCurrencyAmount: yup.string().required(t("fieldRequired")),
      counterCurrencyValue: yup.string().required(t("fieldRequired")),
      time: yup.date().required(t("fieldRequired")).typeError(t("fieldRequired")),
      note: yup.string().max(100, t("fieldMaxLength", { max: 100 })),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      const result = {
        ...values,
        baseCurrencyAmount: values.baseCurrencyAmount.replace(",", "."),
        counterCurrencyValue: values.counterCurrencyValue.replace(",", "."),
        time: dayjs(values.time).format("YYYY-MM-DDTHH:mm:ss"),
      };

      if (data) handleUpdate(result);
      else handleCreate(result);
    },
  });

  const handleCreate = async (values: IManualOrderRequest) => {
    try {
      setIsProcessing(true);
      await dcaBotsCreateManualOrder(id, values);
      refreshData();
      handleClose();
      enqueueSnackbar(t("dcaBots.balances.manualOrder.createSuccess"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("dcaBots.balances.manualOrder.createError"), { variant: "error" });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdate = async (values: IManualOrderRequest) => {
    if (!data?.orderId) return;

    try {
      setIsProcessing(true);
      await dcaBotsUpdateManualOrder(id, data?.orderId, values);
      refreshData();
      handleClose();
      enqueueSnackbar(t("dcaBots.balances.manualOrder.updateSuccess"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("dcaBots.balances.manualOrder.updateError"), { variant: "error" });
    } finally {
      setIsProcessing(false);
    }
  };

  const getAskBidPrice = (price: number, currency: string) => {
    const usdPrice = getUSDPrice(currency, price, 0);

    if (usdPrice) {
      return <Typography fontWeight={600} fontSize={"inherit"}>{`(~\u00a0${usdPrice})`}</Typography>;
    }
  };

  const getRate = () => {
    if (formik.values.baseCurrencyAmount && formik.values.counterCurrencyValue) {
      const rate = Number(formik.values.counterCurrencyValue.replace(",", ".")) / Number(formik.values.baseCurrencyAmount.replace(",", "."));

      return (
        <Stack
          sx={{ fontSize: "0.8em", color: theme.palette.grey[600], minHeight: "20px", pb: 1 }}
          direction={"row"}
          flexWrap={"wrap"}
          columnGap={0.5}>
          <Typography fontSize={"inherit"}>{`${t("dcaBots.balances.manualOrder.rate")} 1 ${baseCurrency}:`}</Typography>
          <Stack direction={"row"} spacing={0.5}>
            <Typography fontWeight={600} fontSize={"inherit"}>
              {`${renderNumber(rate, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}
            </Typography>
            {getAskBidPrice(rate, counterCurrency)}
          </Stack>
        </Stack>
      );
    }
  };

  return (
    <Modal
      open={true}
      onCancel={handleClose}
      title={t(`dcaBots.balances.manualOrder.${data ? "update" : "title"}`)}
      cancelTitle={t("cancel")}
      customConfirmButton={
        <ConditionalWrapper
          condition={!isPremiumUser}
          wrapper={(children) => <TooltipFreeUserUpsell>{children}</TooltipFreeUserUpsell>}>
          <Button
            variant="contained"
            endIcon={!isPremiumUser ? <CreditInverted /> : undefined}
            onClick={() => (isPremiumUser ? formik.handleSubmit() : undefined)}>
            {data ? t("update") : t("confirm")}
          </Button>
        </ConditionalWrapper>
      }
      size={"xs"}
      disableCloseOnClickOutside
      withCloseButton>
      <Stack spacing={2}>
        {!isPremiumUser && (
          <Alert
            style={{ backgroundColor: colors.purple100, color: colors.purple500 }}
            icon={<Diamond style={{ color: colors.purple500 }} />}>
            <AlertTitle>
              <Trans i18nKey={"dcaBots.balances.manualOrder.premiumUpsellAlert.title"} />
            </AlertTitle>
            <Trans
              i18nKey={"dcaBots.balances.manualOrder.premiumUpsellAlert.description"}
              components={{
                a: <Link href={"/predplatne"} color={"inherit"} fontWeight={"bold"} />,
              }}
            />
          </Alert>
        )}
        <FormToggleButton
          fullWidth
          value={formik.values.side}
          onChange={(_event, value) => value && formik.setFieldValue("side", value)}
          items={[
            { value: Side.BUY, label: t("dcaBots.balances.manualOrder.BUY") },
            { value: Side.SELL, label: t("dcaBots.balances.manualOrder.SELL") },
          ]}
          error={formik.touched.side && formik.errors.side}
        />
        <FormToggleButton
          fullWidth
          value={formik.values.type}
          onChange={(_event, value) => value && formik.setFieldValue("type", value)}
          items={[
            { value: OrderType.MARKET, label: OrderType.MARKET,  },
            { value: OrderType.LIMIT, label: OrderType.LIMIT },
          ]}
          error={formik.touched.type && formik.errors.type}
        />
        <PriceField
          autoComplete="off"
          allowZero
          fullWidth={true}
          precision={currencyPairBaseScale}
          id="baseCurrencyAmount"
          name="baseCurrencyAmount"
          label={t("dcaBots.balances.manualOrder.amount")}
          value={formik.values.baseCurrencyAmount}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.baseCurrencyAmount && Boolean(formik.errors.baseCurrencyAmount)}
          helperText={formik.touched.baseCurrencyAmount && formik.errors.baseCurrencyAmount}
          InputProps={{
            endAdornment: <InputAdornment position="start">{baseCurrency}</InputAdornment>,
          }}
        />
        <PriceField
          autoComplete="off"
          allowZero
          fullWidth={true}
          precision={counterCurrScale}
          id="counterCurrencyValue"
          name="counterCurrencyValue"
          label={t("dcaBots.balances.manualOrder.value")}
          value={formik.values.counterCurrencyValue}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.counterCurrencyValue && Boolean(formik.errors.counterCurrencyValue)}
          helperText={formik.touched.counterCurrencyValue && formik.errors.counterCurrencyValue}
          InputProps={{
            endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
          }}
        />
        {getRate()}
        <LocalizedDateTimePicker
          label={t("dcaBots.balances.manualOrder.time")}
          value={formik.values.time === null ? null : dayjs(formik.values.time)}
          onChange={(value) => formik.setFieldValue("time", value)}
          disableMaskedInput
          maxDate={dayjs()}
          minDate={dayjs("2010-05-22")}
          error={formik.touched.time && formik.errors.time}
        />
        <TextField
          fullWidth
          id="note"
          name="note"
          label={t("dcaBots.balances.manualOrder.note")}
          value={formik.values.note}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.note)}
          helperText={formik.errors.note}
          multiline
          rows={2}
        />
      </Stack>
      <BackdropLoading open={isProcessing} />
    </Modal>
  );
};

export default ManualOrderModal;
