const LOCALHOST_DEV = "http://localhost:8801";
const LOCALHOST_TEST = "http://localhost:8802";
const LOCALHOST_PREPROD = "http://localhost:8803";
const LOCALHOST_PROD = "http://localhost:8804";

const VPS_DEV = "http://46.28.110.125:8801";
const VPS_TEST = "http://46.28.110.125:8802";
const VPS_PREPROD = "http://46.28.110.125:8803";
const VPS_PROD = "https://stosuj.cz";

export default {
  LOCALHOST_DEV,
  LOCALHOST_TEST,
  LOCALHOST_PREPROD,
  LOCALHOST_PROD,
  VPS_DEV,
  VPS_TEST,
  VPS_PREPROD,
  VPS_PROD,
};
