import { rechargeOptions } from "constants/subscriptions";

import React from "react";

import Brightness4Icon from "@mui/icons-material/Brightness4";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Avatar, Box, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha, useTheme } from "@mui/material/styles";
import { logout } from "API/calls";
import { UserCircle, CreditMenu, Settings1, CreditMenuDark } from "components/icons";
import { MUIThemeMode, useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { SubscriptionType } from "types/enums";
import { ConditionalWrapper, getAnyByMode, getComponentByMode } from "utils";

import Switch from "./Switch";
import TooltipFreeUserUpsell from "./TooltipFreeUserUpsell";

interface UserProfileProps {
  avatarId?: number;
  disableThemeMode?: boolean;
}

const CustomItem = styled(Box)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    "&:hover": {
      backgroundColor: alpha(colors.black, getAnyByMode(theme, 0.04, 0.08)),
    },
    padding: "12px 16px",
    color: colors.gray700,
    display: "flex",
    alignItems: "center",
  };
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    "& .MuiPaper-root": {
      borderRadius: 10,
      marginTop: theme.spacing(1),
      minWidth: 250,
      color: colors.gray700,
      border: `1px solid ${colors.gray100}`,
      boxShadow: getAnyByMode(theme, "none", "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)"),
      "& .MuiMenu-list": {
        paddingTop: "8px",
        paddingBottom: "0px",
      },
      "& .MuiMenuItem-root": {
        padding: "12px 16px",
        fontWeight: 500,
        "& .MuiSvgIcon-root": {
          fontSize: 20,
          color: colors.gray700,
          marginRight: theme.spacing(1),
        },
        "&:active": {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  };
});

const UserProfile: React.FC<UserProfileProps> = ({ disableThemeMode = false }) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const navigate = useNavigate();
  const { subscriptionType, creditsRechargeType, isMasked, toggleMaskedMode, showInUSD, toggleShowInUSD } = useUserDataContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const isPremium = subscriptionType === SubscriptionType["PREMIUM"];
  const { mode, changeMode } = useMUIThemeModeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeMode = (_event: React.MouseEvent<HTMLElement>, newMode: MUIThemeMode | null) => {
    if (newMode !== null) changeMode(newMode);
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.replace("/");
    } catch (error) {
      enqueueSnackbar(t("commonError"), { variant: "error" });
    }
  };

  const getIcon = () => {
    if (creditsRechargeType) {
      const rechargeType = rechargeOptions.find((option) => option.type === creditsRechargeType);

      if (rechargeType && rechargeType.profileIcon) {
        return (
          <Avatar src={rechargeType.img} sx={{ width: 40, height: 40 }}>
            {t(`recharge.${rechargeType.type}`)}
          </Avatar>
        );
      }
    }

    return <UserCircle />;
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          padding: 0,
          color: disableThemeMode ? "#344054" : colors.gray700,
        }}>
        {getIcon()}
      </IconButton>
      <StyledMenu id="user-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            navigate("/stosaky");
            handleClose();
          }}>
          {getComponentByMode(theme, <CreditMenu />, <CreditMenuDark />)}
          {t("credits")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/nastaveni");
            handleClose();
          }}>
          <Settings1 />
          {t("settings")}
        </MenuItem>
        <CustomItem>
          <Stack width={"100%"} direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={2}>
            <Stack direction={"row"} alignItems={"center"}>
              {isMasked ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
              <Stack pl={1}>
                <Typography fontWeight={500}>{t("maskedMode.title")}</Typography>
                <Typography fontSize={"0.75rem"}>{t("maskedMode.description")}</Typography>
              </Stack>
            </Stack>
            <Switch size="small" checked={isMasked} onChange={toggleMaskedMode} />
          </Stack>
        </CustomItem>
        <CustomItem>
          <Stack width={"100%"} direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={2}>
            <Stack direction={"row"} alignItems={"center"}>
              <CurrencyExchangeIcon fontSize="small" />
              <Stack pl={1}>
                <Typography fontWeight={500}>{t("showInUSD.title")}</Typography>
                <Typography fontSize={"0.75rem"}>{t("showInUSD.description")}</Typography>
              </Stack>
            </Stack>
            <Switch size="small" checked={showInUSD} onChange={toggleShowInUSD} />
          </Stack>
        </CustomItem>
        {!disableThemeMode && (
          <ConditionalWrapper
            condition={!isPremium}
            wrapper={(children) => (
              <TooltipFreeUserUpsell placement={isMobile ? "bottom" : "left"}>{children}</TooltipFreeUserUpsell>
            )}>
            <CustomItem>
              <Stack direction={"column"} spacing={1}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Brightness4Icon fontSize="small" />
                  <Typography fontWeight={500} flexGrow={1} pl={1}>
                    {t("themeMode")}:
                  </Typography>
                  {!isPremium && <CreditMenu sx={{ ml: 0.5, fontSize: 20 }} />}
                </Stack>
                <ToggleButtonGroup
                  color="primary"
                  sx={{ pt: 1 }}
                  disabled={!isPremium}
                  value={mode}
                  onChange={handleChangeMode}
                  exclusive
                  size="small">
                  <ToggleButton sx={{ color: colors.gray700 }} value="light">
                    <LightModeIcon fontSize="small" sx={{ mr: 1 }} />
                    {t("light")}
                  </ToggleButton>
                  <ToggleButton value="device">
                    <SettingsBrightnessIcon fontSize="small" sx={{ mr: 1 }} />
                    {t("device")}
                  </ToggleButton>
                  <ToggleButton value="dark">
                    <DarkModeOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                    {t("dark")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </CustomItem>
          </ConditionalWrapper>
        )}
        <Box p={2}>
          <Button variant="outlined" color="inherit" onClick={handleLogout} style={{ width: "100%" }}>
            {t("logout")}
          </Button>
        </Box>
      </StyledMenu>
    </>
  );
};

export default UserProfile;
