import { subscriptionCost } from "constants/subscriptions";

import React, { useState, useEffect, useContext, createContext } from "react";

import { getCreditsRecharge, getUserData } from "API/calls";
import { RechargeState, RechargeType, SubscriptionType } from "types/enums";
import { ICreditRecharge, ISummarieSettings, IUserData } from "types/types";

export type IUserDataContext = {
  refreshData?: () => void;
  badgesCount?: number;
  language?: string;
  subscriptionType?: SubscriptionType;
  creditsBalance?: number;
  creditsUnclaimed?: number;
  mfaEnabled?: boolean;
  aff: string;
  affBy: string | null;
  subscriptionPaused: boolean;
  subscriptionPausedWarning: boolean;
  subscriptionPausedError: boolean;
  loading: boolean;
  summariesSettings: ISummarieSettings;
  handleChangeSummarySetting?: (key: string, checked: boolean) => void;
  refreshCreditsRecharge?: () => void;
  creditsRechargeType?: RechargeType | null;
  showInUSD: boolean;
  toggleShowInUSD?: () => void;
  isMasked: boolean;
  toggleMaskedMode?: () => void;
};

const defaultState: IUserDataContext = {
  aff: "",
  affBy: null,
  subscriptionPaused: false,
  subscriptionPausedWarning: false,
  subscriptionPausedError: false,
  loading: true,
  showInUSD: false,
  isMasked: false,
  summariesSettings: {
    cashbackSats: true,
    dcaSummaries: true,
    dcaSummariesIncludingDisabled: true,
    gridSummaries: true,
    gridSummariesIncludingDisabled: true,
  },
};

const UserDataContext = createContext<IUserDataContext>(defaultState);

export const UserDataProvider: React.FC<{}> = ({ children }) => {
  const [badgesCount, setBadgesCount] = useState<number | undefined>(defaultState.badgesCount);
  const [language, setLanguage] = useState<string | undefined>(defaultState.language);
  const [subscriptionType, setSubscriptionType] = useState<SubscriptionType | undefined>(defaultState.subscriptionType);
  const [creditsBalance, setCreditsBalance] = useState<number | undefined>(defaultState.creditsBalance);
  const [creditsUnclaimed, setCreditsUnclaimed] = useState<number | undefined>(defaultState.creditsUnclaimed);
  const [mfaEnabled, setMfaEnabled] = useState<boolean | undefined>(defaultState.mfaEnabled);
  const [aff, setAff] = useState<string>(defaultState.aff);
  const [affBy, setAffBy] = useState<string | null>(defaultState.affBy);
  const [subscriptionPaused, setSubscriptionPaused] = useState<boolean>(defaultState.subscriptionPaused);
  const [shouldSubscriptionPausedWarning, setShouldSubscriptionPausedWarning] = useState<boolean>();
  const [subscriptionPausedWarning, setSubscriptionPausedWarning] = useState<boolean>(
    defaultState.subscriptionPausedWarning
  );
  const [subscriptionPausedError, setSubscriptionPausedError] = useState<boolean>(defaultState.subscriptionPausedError);
  const [loading, setLoading] = useState(defaultState.loading);
  const [summariesSettings, setSummariesSettings] = useState<ISummarieSettings>({
    cashbackSats: localStorage.getItem("cashbackSats") === null ? true : false,
    dcaSummaries: localStorage.getItem("dcaSummaries") === null ? true : false,
    dcaSummariesIncludingDisabled: localStorage.getItem("dcaSummariesIncludingDisabled") === null ? true : false,
    gridSummaries: localStorage.getItem("gridSummaries") === null ? true : false,
    gridSummariesIncludingDisabled: localStorage.getItem("gridSummariesIncludingDisabled") === null ? true : false,
  });
  const [creditsRechargeType, setCreditsRechargeType] = useState<RechargeType | null | undefined>(null);
  const [showInUSD, setShowInUSD] = useState<boolean>(localStorage.getItem("showInUSD") === "true");
  const [isMasked, setIsMasked] = useState<boolean>(localStorage.getItem("isMasked") === "true");

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    if (!loading && !!subscriptionType && shouldSubscriptionPausedWarning !== undefined) {
      loadCreditsRecharge();
    }
  }, [loading, subscriptionType, shouldSubscriptionPausedWarning]);

  async function loadUserData() {
    try {
      const userData = (await getUserData()).data as IUserData;
      if (userData) {
        setBadgesCount(userData.badgesCount);
        setLanguage(userData.language);
        setSubscriptionType(userData.subscriptionType);
        setCreditsBalance(userData.creditsBalance);
        setCreditsUnclaimed(userData.creditsUnclaimed);
        setMfaEnabled(userData.mfaEnabled);
        setAff(userData.aff);
        setAffBy(userData.affBy);
        const hasSubscriptionPausedError
          = userData.subscriptionPaused && userData.subscriptionType !== SubscriptionType["FREE"];
        const hasEnoughCreditsForNextMonth
          = !!userData.creditsBalance && userData.subscriptionType && userData.creditsBalance >= subscriptionCost[userData.subscriptionType];
        const hasSubscriptionPausedWarning
          = !hasSubscriptionPausedError && userData.subscriptionType !== SubscriptionType["FREE"] && !hasEnoughCreditsForNextMonth;
        setSubscriptionPaused(userData.subscriptionPaused);
        setShouldSubscriptionPausedWarning(hasSubscriptionPausedWarning);
        setSubscriptionPausedError(hasSubscriptionPausedError);
      } else {
        setSubscriptionType(undefined);
      }
    } finally {
      setLoading(false);
    }
  }

  async function loadCreditsRecharge() {
    try {
      const rechargeData = (await getCreditsRecharge()).data as ICreditRecharge;

      if (rechargeData && rechargeData.state === RechargeState.ACTIVE) {
        setCreditsRechargeType(rechargeData.type);
        setSubscriptionPausedWarning(false);
      } else {
        setSubscriptionPausedWarning(shouldSubscriptionPausedWarning ?? false);
        setCreditsRechargeType(undefined);
      }
    } catch (error) {
      //silent
    }
  }

  const handleChangeSummarySetting = (key: string, checked: boolean) => {
    setSummariesSettings((prevState) => ({ ...prevState, [key]: checked }));

    if (checked) localStorage.removeItem(key);
    else localStorage.setItem(key, "false");
  };

  const toggleShowInUSD = () => {
    localStorage.setItem("showInUSD", (!showInUSD).toString());
    setShowInUSD((prevState) => !prevState);
  };

  const toggleMaskedMode = () => {
    localStorage.setItem("isMasked", (!isMasked).toString());
    setIsMasked((prevState) => !prevState);
  };

  return (
    <UserDataContext.Provider
      value={{
        badgesCount,
        language,
        subscriptionType,
        creditsBalance,
        creditsUnclaimed,
        mfaEnabled,
        aff,
        affBy,
        subscriptionPaused,
        subscriptionPausedWarning,
        subscriptionPausedError,
        refreshData: () => loadUserData(),
        loading,
        summariesSettings,
        handleChangeSummarySetting,
        refreshCreditsRecharge: () => loadCreditsRecharge(),
        creditsRechargeType,
        showInUSD,
        toggleShowInUSD,
        isMasked,
        toggleMaskedMode,
      }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => useContext(UserDataContext);
