import React, { useEffect, useState } from "react";

import { Alert, AlertTitle, Grid, Link, useMediaQuery, useTheme } from "@mui/material";
import { updateDcaBotEnabled, deleteDcaBot } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import { useDefaultLayoutContext } from "context/DefaultLayoutContext";
import { useUserDataContext } from "context/UserDataContext";
import { useAPIDcaBotsDetail, useAPIDcaBotsRunsList, useAPIDcaBotsBalances } from "hooks/useAPI";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LayoutPosition, OrderTriggering, SubscriptionType } from "types/enums";
import { splitCurrencyPair } from "utils";

import BotDetailSkeleton from "../BotDetailSkeleton";
import Balance from "./Balance";
import ChartHistory from "./ChartHistory";
import ControlPanel from "./ControlPanel";
import Detail from "./Detail";
import InitialValuesModal from "./InitialValuesModal";
import RunsList from "./RunsList";
import Settings from "./Settings";
import { MediaQueryGrid, MediaQueryGridReverse } from "./styles";
import TargetValueModal from "./TargetValueModal";
import IProps from "./types";

const getOrderListPosition = () => {
  const position = localStorage.getItem("dcaOrderListPosition") as LayoutPosition;
  return position ? position : LayoutPosition.BOTTOM;
};

const DcaBotDetail: React.FC<IProps> = ({ id }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const navigate = useNavigate();
  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const isOneColumn = useMediaQuery(theme.breakpoints.down("lg"));
  const [initialValuesModalOpen, setInitialValuesModalOpen] = useState(false);
  const [targetValueModalOpen, setTargetValueModalOpen] = useState(false);
  const { subscriptionType } = useUserDataContext();
  const { refreshMenu } = useDefaultLayoutContext();
  const [position, setPosition] = useState<LayoutPosition>(getOrderListPosition());
  const [selectedTriggering, setSelectedTriggering] = useState<OrderTriggering[]>();
  const isFree = subscriptionType === SubscriptionType["FREE"];
  const isPremium = subscriptionType === SubscriptionType["PREMIUM"];

  const {
    data: dcaBotsDetailData,
    mutate: dcaBotsDetailRefresh,
    clearCache: dcaBotsDetailClear,
    isValidating: dcaBotsDetailIsValidating,
  } = useAPIDcaBotsDetail(id);
  const {
    data: dcaBotsRunsListData,
    mutate: dcaBotsRunsListRefresh,
    clearCache: dcaBotsRunsListClear,
    isValidating: dcaBotsRunsListIsValidating,
  } = useAPIDcaBotsRunsList(id, selectedTriggering);

  const { mutate: refreshBalances } = useAPIDcaBotsBalances(
    id,
    dcaBotsDetailData?.exchangeConnection.isExchangeEnabled && dcaBotsDetailData?.exchangeConnection.functional
  );

  const refreshData = () => {
    dcaBotsDetailRefresh();
    dcaBotsRunsListRefresh();
    refreshBalances();
  };

  const refreshRunsList = async (callback?: () => void) => {
    try {
      await dcaBotsRunsListRefresh();
      if (callback) callback();
    } catch (error) {
      // silent
    }
  }

  useEffect(() => {
    return () => {
      dcaBotsDetailClear();
      dcaBotsRunsListClear();
    };
  }, []);

  useEffect(() => {
    if (!dcaBotsDetailIsValidating && dcaBotsDetailData === null) dcaBotsDetailRefresh();
    if (!dcaBotsRunsListIsValidating && dcaBotsRunsListData === null) dcaBotsRunsListRefresh();
  }, [dcaBotsRunsListIsValidating, dcaBotsDetailIsValidating]);

  if (!dcaBotsDetailData) return <BotDetailSkeleton />;

  const { strategy, exchangeConnection, initialBaseCurrencyAmount, initialCounterCurrencyAmount } = dcaBotsDetailData;
  const { baseCurrency, counterCurrency } = splitCurrencyPair(dcaBotsDetailData.currencyPair);

  const handleEnabled = async () => {
    try {
      await confirm({
        title: dcaBotsDetailData.enabled ? t("dcaBots.confirmDisable.title") : t("dcaBots.confirmEnable.title"),
        description: dcaBotsDetailData.enabled
          ? <Trans i18nKey="dcaBots.confirmDisable.description" />
          : <Trans i18nKey="dcaBots.confirmEnable.description" />,
      });

      setIsOpenBackdropLoading(true);
      try {
        await updateDcaBotEnabled(id, { enabled: !dcaBotsDetailData.enabled });
        enqueueSnackbar(
          !dcaBotsDetailData.enabled ? t("dcaBots.successMessageEnable") : t("dcaBots.successMessageDisable"),
          { variant: "success" }
        );
        refreshMenu?.();
        dcaBotsDetailRefresh();
      } catch (error: any) {
        if (error?.response?.status === 423) {
          enqueueSnackbar(t("dcaBots.errorMessage423"), { variant: "error" });
        } else {
          enqueueSnackbar(
            !dcaBotsDetailData.enabled ? t("dcaBots.errorMessageEnable") : t("dcaBots.errorMessageDisable"),
            { variant: "error" }
          );
        }
      }
      setIsOpenBackdropLoading(false);
    } catch (err: any) {
      // silent
    }
  };

  const handleDelete = async () => {
    try {
      await confirm({
        title: t("dcaBots.confirmDelete.title"),
        description: t("dcaBots.confirmDelete.description"),
        dialogProps: { maxWidth: "xs" },
      });

      setIsOpenBackdropLoading(true);
      try {
        await deleteDcaBot(id);
        enqueueSnackbar(t("dcaBots.deleteSuccessMessage"), { variant: "success" });
        refreshMenu?.();
        navigate(`/dashboard`);
      } catch (error: any) {
        if (error?.response?.status === 423) {
          enqueueSnackbar(t("dcaBots.errorMessage423"), { variant: "error" });
        } else {
          enqueueSnackbar(t("dcaBots.deleteErrorMessage"), { variant: "error" });
        }
      }
      setIsOpenBackdropLoading(false);
    } catch (err: any) {
      // silent
    }
  };

  const hasNegativeTotalAmount = Number(dcaBotsDetailData.details?.totalAmount) < 0;

  const togglePosition = () => {
    const newPosition = position === LayoutPosition.BOTTOM ? LayoutPosition.TOP : LayoutPosition.BOTTOM;
    setPosition(newPosition);
    localStorage.setItem("dcaOrderListPosition", newPosition);
  };

  const getRunsList = () => (
    <RunsList
      data={dcaBotsRunsListData}
      dcaBotId={id}
      initialBaseCurrencyAmount={initialBaseCurrencyAmount}
      initialCounterCurrencyAmount={initialCounterCurrencyAmount}
      baseCurrency={baseCurrency}
      counterCurrency={counterCurrency}
      exchangeEnum={exchangeConnection?.exchangeEnum}
      setInitialValuesModalOpen={() => setInitialValuesModalOpen(true)}
      refreshData={refreshRunsList}
      position={position}
      onPositionToggle={togglePosition}
      selectedTriggering={selectedTriggering}
      onTriggeringChange={setSelectedTriggering}
      refreshDetail={refreshData}
      mode={dcaBotsDetailData.mode}
    />
  );

  return (
    <Grid container spacing={4}>
      <BackdropLoading open={isOpenBackdropLoading} />
      {!isOpenBackdropLoading && hasNegativeTotalAmount && (
        <Grid item xs={12}>
          <Alert severity="warning">
            <AlertTitle>
              <Trans i18nKey="dcaBots.negativeTotalAmount.title" />
            </AlertTitle>
            <Trans
              i18nKey="dcaBots.negativeTotalAmount.description"
              components={{
                a: (
                  <Link
                    href="#"
                    underline="always"
                    color="inherit"
                    sx={{ fontWeight: "bold" }}
                    onClick={() => setInitialValuesModalOpen(true)}
                  />
                ),
              }}
            />
          </Alert>
        </Grid>
      )}
      {!isPremium && dcaBotsDetailData.hasManualOrders && (
        <Grid item xs={12}>
          <Alert severity="warning">
            <AlertTitle>
              <Trans i18nKey="dcaBots.hasManualOrders.title" />
            </AlertTitle>
            <Trans i18nKey="dcaBots.hasManualOrders.description" />
          </Alert>
        </Grid>
      )}
      <Grid item lg={7} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Detail
              id={id}
              dcaBotsDetailData={dcaBotsDetailData}
              baseCurrency={baseCurrency}
              counterCurrency={counterCurrency}
              onEnabled={handleEnabled}
              onDelete={handleDelete}
            />
          </Grid>
          <MediaQueryGrid item xs={12} disabled={position === LayoutPosition.TOP}>
            {getRunsList()}
          </MediaQueryGrid>
        </Grid>
      </Grid>
      <Grid item lg={5} xs={12}>
        <Grid container spacing={4}>
          {!isOneColumn && (
            <Grid item xs={12}>
              <ControlPanel
                id={id}
                enabled={dcaBotsDetailData.enabled}
                onEnabled={handleEnabled}
                onDelete={handleDelete}
                isExchangeEnabled={exchangeConnection?.isExchangeEnabled}
                functional={exchangeConnection?.functional}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Balance
              id={id}
              exchangeTitle={exchangeConnection?.exchangeTitle ?? ""}
              exchangeLabel={exchangeConnection?.label}
              exchangeEnum={exchangeConnection?.exchangeEnum}
              isBrokerage={exchangeConnection?.isBrokerage}
              isExchangeEnabled={exchangeConnection?.isExchangeEnabled}
              functional={exchangeConnection?.functional}
              baseCurrency={baseCurrency}
              totalInvested={dcaBotsDetailData?.details?.totalInvested}
              counterCurrency={counterCurrency}
              refreshData={refreshData}
              botRunsList={dcaBotsRunsListData}
              bidPrice={dcaBotsDetailData.details?.bidPrice}
              mode={dcaBotsDetailData.mode}
            />
          </Grid>
          <Grid item xs={12}>
            <Settings
              id={id}
              strategy={strategy}
              currencyPair={dcaBotsDetailData.currencyPair}
              exchangeEnum={exchangeConnection?.exchangeEnum}
              frequency={dcaBotsDetailData.frequency}
              frequencyParameter={dcaBotsDetailData.frequencyParameter}
              createdAt={dcaBotsDetailData.createdAt}
              updatedAt={dcaBotsDetailData.updatedAt}
              enabled={dcaBotsDetailData.enabled}
              currentDynamicStrategyMultiplier={dcaBotsDetailData.details?.currentDynamicStrategyMultiplier}
              isExchangeEnabled={exchangeConnection?.isExchangeEnabled}
              refreshData={refreshData}
              mode={dcaBotsDetailData.mode}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: "block" } }}>
            {dcaBotsRunsListData && (
              <ChartHistory
                chartData={dcaBotsRunsListData.investmentChart}
                priceChartData={dcaBotsRunsListData.priceChart}
                dcaBotRuns={dcaBotsRunsListData.dcaBotRuns}
                baseCurrency={baseCurrency}
                counterCurrency={counterCurrency}
                currencyPair={dcaBotsDetailData.currencyPair}
                exchangeEnum={exchangeConnection?.exchangeEnum}
                targetValuePrice={dcaBotsDetailData.targetValuePrice}
                setTargetValueModalOpen={() => setTargetValueModalOpen(true)}
              />
            )}
          </Grid>
          <MediaQueryGridReverse item xs={12} disabled={position === LayoutPosition.BOTTOM}>
            {getRunsList()}
          </MediaQueryGridReverse>
        </Grid>
      </Grid>
      {targetValueModalOpen && (
        <TargetValueModal
          id={id}
          isFreeUser={isFree}
          isOpen={targetValueModalOpen}
          handleClose={() => setTargetValueModalOpen(false)}
          exchangeEnum={exchangeConnection?.exchangeEnum}
          baseCurrency={baseCurrency}
          counterCurrency={counterCurrency}
          refreshData={refreshData}
          bidPrice={dcaBotsDetailData.details?.bidPrice}
          targetValuePrice={dcaBotsDetailData.targetValuePrice}
        />
      )}
      {initialValuesModalOpen && (
        <InitialValuesModal
          id={id}
          isFreeUser={isFree}
          isOpen={initialValuesModalOpen}
          handleClose={() => setInitialValuesModalOpen(false)}
          exchangeEnum={exchangeConnection?.exchangeEnum}
          baseCurrency={baseCurrency}
          counterCurrency={counterCurrency}
          initialBaseCurrencyAmount={initialBaseCurrencyAmount}
          initialCounterCurrencyAmount={initialCounterCurrencyAmount}
          refreshData={refreshData}
          bidPrice={dcaBotsDetailData.details?.bidPrice}
        />
      )}
    </Grid>
  );
};

export default DcaBotDetail;
