import React, { useEffect, useState } from "react";

import { Menu } from "@mui/icons-material";
import { AppBar, Toolbar, Box, IconButton, Stack, Container, useTheme, useMediaQuery } from "@mui/material";
import { ReactComponent as DarkLogo } from "assets/dark-logo.svg";
import { ReactComponent as Logo } from "assets/logo.svg";
import UserProfile from "components/elements/UserProfile";
import { BarLineChart, CoinsStacked } from "components/icons";
import { DefaultLayoutContext } from "context/DefaultLayoutContext";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPIDcaBotsList, useAPIGridBotsList } from "hooks/useAPI";
import { useIsLogin } from "hooks/useUser";
import { useIsSmallScreen } from "hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import { getComponentByMode } from "utils";

import Footer from "../Default/Footer";
import { HeaderButton } from "../Default/Header";
import BottomNavigation from "./BottomNavigation";
import MenuItem from "./MenuItem";
import SidebarMenu from "./SidebarMenu";

const drawerWidth = 312;

const Main: React.FC = () => {
  const { t } = useTranslation<string>();
  const theme = useTheme();
  const { loadStoredMode } = useMUIThemeModeContext();

  const isLogin = useIsLogin();
  const isSmallScreen = useIsSmallScreen();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const isBottomNavigation = Boolean(isSmallScreen && isLogin);
  const isMobile = useMediaQuery(theme.breakpoints.down(360));

  const { data: dcaBotsData, mutate: dcaBotsMutate } = useAPIDcaBotsList(isLogin === true);
  const { data: botsData, mutate: botsMutate } = useAPIGridBotsList(isLogin === true);

  const reload = () => {
    dcaBotsMutate();
    botsMutate();
  };

  const getBotsList = () => {
    const enabledList: React.ReactNode[] = [];
    const disabledList: React.ReactNode[] = [];

    botsData?.bots.forEach((bot, index) =>
      bot.enabled
        ? enabledList.push(
            <MenuItem
              key={`BotItem-menu-${index}`}
              icon={<BarLineChart color="inherit" sx={{ fontSize: 20 }} />}
              title={bot.currencyPair}
              subtitle={bot.label}
              to={`/grid-strategie/${bot.id}`}
              withSubtitle
              withSelected
            />
          )
        : disabledList.push(
            <MenuItem
              key={`BotItem-menu-${index}`}
              icon={<BarLineChart color="inherit" sx={{ fontSize: 20 }} />}
              title={bot.currencyPair}
              subtitle={bot.label}
              to={`/grid-strategie/${bot.id}`}
              withSubtitle
              withSelected
            />
          )
    );

    dcaBotsData?.dcaBots.forEach((dcaBot, index) =>
      dcaBot.enabled
        ? enabledList.push(
            <MenuItem
              key={`DcaBotItem-menu-${index}`}
              icon={<CoinsStacked color="inherit" sx={{ fontSize: 20 }} />}
              title={dcaBot.currencyPair}
              subtitle={dcaBot.label}
              to={`/strategie/${dcaBot.id}`}
              withSubtitle
              withSelected
            />
          )
        : disabledList.push(
            <MenuItem
              key={`DcaBotItem-menu-${index}`}
              icon={<CoinsStacked color="inherit" sx={{ fontSize: 20 }} />}
              title={dcaBot.currencyPair}
              subtitle={dcaBot.label}
              to={`/strategie/${dcaBot.id}`}
              withSubtitle
              withSelected
            />
          )
    );

    return { enabledList, disabledList };
  };

  useEffect(loadStoredMode, []);

  useEffect(() => {
    setDrawerOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  return (
    <Box sx={{ overflowX: "hidden", position: "relative", height: "100vh" }}>
      <AppBar
        position="fixed"
        elevation={0}
        color="inherit"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            sx={{ visibility: isBottomNavigation ? "hidden" : "unset" }}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}>
            <Menu color="primary" />
          </IconButton>
          <Link to={isLogin ? "/dashboard" : "/"}>
            {getComponentByMode(
              theme,
              <Logo height={isMobile ? "30" : "40"} />,
              <DarkLogo width={158} height={isMobile ? "30" : "40"} />
            )}
          </Link>
          <Box>
            <Stack spacing={2} direction="row">
              {isLogin === false && (
                <>
                  <HeaderButton to="/login" variant="outlined" size={isMobile ? "small" : "medium"}>
                    {t("login")}
                  </HeaderButton>
                  <HeaderButton sx={{ display: { xs: "none", sm: "inline-block" } }} variant="contained" to="/registrace">
                    {t("registration")}
                  </HeaderButton>
                </>
              )}
              {isLogin === true && <UserProfile />}
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <SidebarMenu
        userLoggedIn={!!isLogin}
        drawerOpen={drawerOpen}
        drawerWidth={drawerWidth}
        onItemClick={() => (isSmallScreen ? handleDrawerToggle() : undefined)}
        botsList={getBotsList()}
      />
      <Box
        component="main"
        sx={{
          pt: 3,
          paddingLeft: drawerOpen ? (isSmallScreen ? 0 : `${drawerWidth}px`) : 0,
          transition: "padding-left 0.3s ease",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        className="main-content">
        <Toolbar />
        <Container sx={{ mb: 5 }}>
          <DefaultLayoutContext.Provider value={{ refreshMenu: reload, isDrawerOpen: drawerOpen }}>
            <Outlet />
          </DefaultLayoutContext.Provider>
        </Container>
        <Footer drawerOpen={drawerOpen} isHome={false} />
        {isBottomNavigation && <BottomNavigation botsList={getBotsList()} />}
      </Box>
    </Box>
  );
};

export default Main;
