import { ICoursesConfig } from "types/types";

export default {
  "courses": [
    {
      "title": "Proč se zajímat o\u00a0Bitcoin",
      "directory": "proc-bitcoin",
      "image": "proc-bitcoin.png",
      "description": "Slyšeli jste o Bitcoinu, ale nikdy něvěděli, kde začít? Tady jste správně!",
      "lengthInMinutes": 60,
      "chapters": [
        {
          "file": "1",
          "title": "Svět potřebuje kvalitní peníze",
          "url": "/svet-potrebuje-kvalitni-penize",
          "lengthInMinutes": 16,
          "youtubeId": "Pmyt2aYrsQQ"
        },
        {
          "file": "2",
          "title": "Základní vlastnosti Bitcoinu",
          "url": "/zakladni-vlastnosti-bitcoinu",
          "lengthInMinutes": 15,
          "youtubeId": "IznyahCGEmQ"
        },
        {
          "file": "3",
          "title": "Cena bitcoinu a jak ho nakupovat",
          "url": "/cena-bitcoinu-a-jak-ho-nakupovat",
          "lengthInMinutes": 17,
          "youtubeId": "NqUCDZnPK6E"
        },
        {
          "file": "4",
          "title": "Nejčastější mýty o\u00a0Bitcoinu",
          "url": "/nejcastejsi-myty-o-bitcoinu",
          "lengthInMinutes": 16,
          "youtubeId": "B1JCj_OvKhE"
        },
      ],
    },
    {
      "title": "Úvod do Bitcoinu",
      "directory": "uvod-do-bitcoinu",
      "image": "uvod-do-bitcoinu.png",
      "description": "Jakub Vejmola, alias Kicom, vám Bitcoin vysvětlí od úplného začátku. Do tohoto kurzu jsme vybrali ta nejpodstatnější videa z\u00a0jeho série Úvod do Bitcoinu vhodná pro\u00a0nováčky.",
      "lengthInMinutes": null,
      "author": {
        "image": "author-jakub-vejmola.jpg",
        "name": "Jakub 'Kicom' Vejmola",
        "label": "Bitcoinovej kanál",
        "url": "https://www.youtube.com/@BitcoinovejKanal"
      },
      "chapters": [
        {
          "file": "3",
          "title": "Je Bitcoin bublina?",
          "url": "/je-bitcoin-bublina",
          "lengthInMinutes": 14,
          "youtubeId": "U2qkuowYdD8"
        },
        {
          "file": "4",
          "title": "Jaká bude cena Bitcoinu?",
          "url": "/jaka-bude-cena-bitcoinu",
          "lengthInMinutes": 13,
          "youtubeId": "Eo24pyMN61Y"
        },
        {
          "file": "5",
          "title": "Co je Bitcoin? A jak funguje blockchain?",
          "url": "/co-je-bitcoin-a-jak-funguje-blockchain",
          "lengthInMinutes": 19,
          "youtubeId": "KSKY1P9qLk4"
        },
        {
          "file": "6",
          "title": "Co jsou peníze? A jak fungují?",
          "url": "/co-jsou-penize-a-jak-funguji",
          "lengthInMinutes": 16,
          "youtubeId": "eqycOsCwSls"
        },
        {
          "file": "7",
          "title": "Nahradí Bitcoin peníze?",
          "url": "/nahradi-bitcoin-penize",
          "lengthInMinutes": 19,
          "youtubeId": "GyUGqWEDBks"
        },
        {
          "file": "9",
          "title": "Je Bitcoin podvod/pyramida/letadlo?",
          "url": "/je-bitcoin-podvod-pyramida-letadlo",
          "lengthInMinutes": 10,
          "youtubeId": "3XHR5awFD6w"
        },
        {
          "file": "10",
          "title": "Vnitřní hodnota Bitcoinu",
          "url": "/vnitrni-hodnota-bitcoinu",
          "lengthInMinutes": 18,
          "youtubeId": "1zNtmw1SEVA"
        },
        {
          "file": "18",
          "title": "Kryptoměnové klíče a peněženky",
          "url": "/kryptomenove-klice-a-penezenky",
          "lengthInMinutes": 14,
          "youtubeId": "4CqyY53dDJU"
        },
        {
          "file": "19",
          "title": "Kryptoměnové transakce",
          "url": "/kryptomenove-transakce",
          "lengthInMinutes": 13,
          "youtubeId": "3-3s019F0jQ"
        },
        {
          "file": "22",
          "title": "Proč na Bitcoinu vznikají bubliny?",
          "url": "/proc-na-bitcoinu-vznikaji-bubliny",
          "lengthInMinutes": 22,
          "youtubeId": "AEyXtBgMCkw"
        },
        {
          "file": "29",
          "title": "Je Bitcoin zastaralý?",
          "url": "/je-bitcoin-zastaraly",
          "lengthInMinutes": 13,
          "youtubeId": "y8Xbqg3ZCQc"
        },
        {
          "file": "36",
          "title": "Jak funguje současný ekonomický systém?",
          "url": "/jak-funguje-soucasny-ekonomicky-system",
          "lengthInMinutes": 25,
          "youtubeId": "4fla1UftSrA"
        },
        {
          "file": "42",
          "title": "Bitcoin vs. zlato",
          "url": "/bitcoin-vs-zlato",
          "lengthInMinutes": 16,
          "youtubeId": "VOrE37dBEzY"
        },
        {
          "file": "70",
          "title": "Nekupujte si bitcoin! (protože spadne...)",
          "url": "/nekupujte-si-bitcoin-protoze-spadne",
          "lengthInMinutes": 7,
          "youtubeId": "zez8JTwcszw"
        },
        {
          "file": "75",
          "title": "Jak koupit bitcoin v roce 2023? Je teď vhodný čas...?",
          "url": "/jak-koupit-bitcoin-v-roce-2023-je-ted-vhodny-cas",
          "lengthInMinutes": 16,
          "youtubeId": "9j1lcoaZj10"
        }
      ]
    },
    {
      "title": "Bitcoin do hloubky",
      "directory": "bitcoin-do-hloubky",
      "image": "bitcoin-do-hloubky.png",
      "description": "Tady jsme z Kicomovo série Úvod do Bitcoinu vybrali trochu pokročilejší videa, pokud se tomu chcete podívat více pod pokličku.",
      "lengthInMinutes": null,
      "author": {
        "image": "author-jakub-vejmola.jpg",
        "name": "Jakub 'Kicom' Vejmola",
        "label": "Bitcoinovej kanál",
        "url": "https://www.youtube.com/@BitcoinovejKanal"
      },
      "chapters": [
        {
          "file": "21",
          "title": "Těžba kryptoměn",
          "url": "/tezba-kryptomen",
          "lengthInMinutes": 20,
          "youtubeId": "aSlEaZFoJmU"
        },
        {
          "file": "37",
          "title": "Kdo je tvůrce Bitcoinu Satoshi Nakamoto?",
          "url": "/kdo-je-tvurce-bitcoinu-satoshi-nakamoto",
          "lengthInMinutes": 12,
          "youtubeId": "2gg4_S_jH2M"
        },
        {
          "file": "59",
          "title": "Problém byzantských generálů",
          "url": "/problem-byzantskych-generalu",
          "lengthInMinutes": 8,
          "youtubeId": "zNpyb4354Cg"
        },
        {
          "file": "43",
          "title": "Stock-to-flow a půlení Bitcoinu (halving)",
          "url": "/stock-to-flow-a-puleni-bitcoinu-halving",
          "lengthInMinutes": 19,
          "youtubeId": "k-Lwuhc-jIY"
        },
        {
          "file": "28",
          "title": "Technická analýza a obchodování (trading) kryptoměn",
          "url": "/technicka-analyza-a-obchodovani-trading-kryptomen",
          "lengthInMinutes": 27,
          "youtubeId": "sqrcgkb0pkw"
        },
        {
          "file": "47",
          "title": "Jak funguje Proof of Stake?",
          "url": "/jak-funguje-proof-of-stake",
          "lengthInMinutes": 12,
          "youtubeId": "CpheakmDeak"
        },
        {
          "file": "33",
          "title": "Kdo a jak může měnit Bitcoin?",
          "url": "/kdo-a-jak-muze-menit-bitcoin",
          "lengthInMinutes": 15,
          "youtubeId": "z7e1Dw-0aEk"
        },
        {
          "file": "12",
          "title": "10 problémů Bitcoinu (1/2)",
          "url": "/10-problemu-bitcoinu-1",
          "lengthInMinutes": 14,
          "youtubeId": "EvpSdAGavMY"
        },
        {
          "file": "13",
          "title": "10 problémů Bitcoinu (2/2)",
          "url": "/10-problemu-bitcoinu-2",
          "lengthInMinutes": 20,
          "youtubeId": "_1KPg0DPFqw"
        },
        {
          "file": "56",
          "title": "Životní cyklus Bitcoinové transakce",
          "url": "/zivotni-cyklus-bitcoinove-transakce",
          "lengthInMinutes": 10,
          "youtubeId": "8lNrAD4Cep4"
        },
        {
          "file": "61",
          "title": "Asymetrická kryptografie a digitální podpisy",
          "url": "/asymetricka-kryptografie-a-digitalni-podpisy",
          "lengthInMinutes": 13,
          "youtubeId": "09h3pFk4tXI"
        },
        {
          "file": "64",
          "title": "Lze vykrást Bitcoinovou peněženku? Můžu uhodnout 256-bitový klíč?",
          "url": "/lze-vykrast-bitcoinovou-penezenku-muzu-uhodnout-256-bitovy-klic",
          "lengthInMinutes": 9,
          "youtubeId": "GAD7Vd0aglw"
        },
        {
          "file": "71",
          "title": "Formáty bitcoinových adres a Segwit",
          "url": "/formaty-bitcoinovych-adres-a-segwit",
          "lengthInMinutes": 6,
          "youtubeId": "2h_u0fp5zTA"
        },
        {
          "file": "73",
          "title": "Bitcoin nemá mince",
          "url": "/bitcoin-nema-mince",
          "lengthInMinutes": 13,
          "youtubeId": "ANSDf2zLxNk"
        },
        {
          "file": "77",
          "title": "Katastr na blockchainu",
          "url": "/katastr-na-blockchainu",
          "lengthInMinutes": 16,
          "youtubeId": "jjaKhd6EAdU"
        }
      ]
    },
    {
      "title": "Štosuj.cz průvodce",
      "directory": "stosuj-cz",
      "image": "stosuj-cz.png",
      "description": "Představení a průvodce online aplikací Štosuj.cz, která vám pomůže nastavit a zautomatizovat vlastní strategii. Nastavení pravidelného spoření do Bitcoinu je velmi snadné a zvládne to každý. Podíváme se ale i na všechny naše pokročilé funkce a různé strategie.",
      "lengthInMinutes": null,
      "author": {
        "image": "author-stosuj-cz.png",
        "name": "Štosuj.cz",
        "label": "stosuj.cz",
        "url": "https://www.youtube.com/@StosujCZ"
      },
      "chapters": [
        {
          "file": "stosuj-cz-intro",
          "title": "Jak vám Štosuj.cz pomůže",
          "url": "/jak-vam-stosuj-cz-pomuze",
          "lengthInMinutes": 6,
          "youtubeId": "TE6ecr5ytNg"
        },
        {
          "file": "stosuj-cz-kicom-recenze",
          "title": "Štosuj.cz návod krok za krokem",
          "url": "/stosuj-cz-navod-krok-za-krokem",
          "lengthInMinutes": 33,
          "youtubeId": "uuMalJpmHnM"
        },
        // {
        //   "file": "stosuj-cz-nastaveni-dca-strategie",
        //   "title": "Nastavení DCA strategie",
        //   "url": "/nastaveni-dca-strategie",
        //   "lengthInMinutes": 0, // TODO
        //   "youtubeId": "" // TODO
        // },
        {
          "file": "stosuj-cz-cashback-saty",
          "title": "Cashback saty za své objednávky",
          "url": "/cashback-saty",
          "lengthInMinutes": 2,
          "youtubeId": "yyU9rglWAUA"
        },
        {
          "file": "stosuj-cz-dynamicka-dca-strategie",
          "title": "Dynamická DCA strategie",
          "url": "/dynamicka-dca-strategie",
          "lengthInMinutes": 2,
          "youtubeId": "iEorLcPabe4"
        },
        {
          "file": "stosuj-cz-dynamicke-dca-podle-fear-and-greed-indexu",
          "title": "Dynamické DCA podle Fear & Greed Indexu",
          "url": "/dynamicke-dca-podle-fear-and-greed-indexu",
          "lengthInMinutes": 1,
          "youtubeId": "bqVejABQZ0w"
        },
        {
          "file": "stosuj-cz-predstaveni-grid-strategie",
          "title": "Představení grid strategie",
          "url": "/predstaveni-grid-strategie",
          "lengthInMinutes": 6,
          "youtubeId": "b74NoRizDjE"
        },
        {
          "file": "stosuj-cz-nastaveni-grid-strategie-detailne",
          "title": "Detailní vysvětlení použití a fungování grid strategie",
          "url": "/detailni-vysvetleni-pouziti-a-fungovani-grid-strategie",
          "lengthInMinutes": 6, // TODO
          "youtubeId": "b74NoRizDjE" // TODO
        },
        {
          "file": "stosuj-cz-exit-strategie",
          "title": "Exit strategie",
          "url": "/exit-strategie",
          "lengthInMinutes": 44, // TODO
          "youtubeId": "kigmp7OdTpY" // TODO
        }
      ],
    },
    {
      "title": "Bitcoin: Nestátní peníze",
      "directory": "bitcoin-nestatni-penize",
      "image": "bitcoin-nestatni-penize.png",
      "description": "Máme Bitcoin chápat jako měnu, nebo peníze? O\u00a0co vlastně jde v\u00a0rakouské škole ekonomie? Jaké lekce nám dává dosavadní historie peněz? Tyto a\u00a0mnohé další otázky ohledně Bitcoinu a\u00a0peněz zkoumá Pepa Tětek v přednáškovém seriálu Bitcoin: Nestátní peníze.",
      "lengthInMinutes": null,
      "author": {
        "image": "author-josef-tetek.jpg",
        "name": "Josef Tětek",
        "label": "stackuj.cz",
        "url": "https://stackuj.cz/"
      },
      "chapters": [
        {
          "file": "0",
          "title": "Vše o Bitcoinu v 15 minutách",
          "url": "/vse-o-bitcoinu-v-15-minutach",
          "lengthInMinutes": 15,
          "youtubeId": "w6XB-sbGlSU"
        },
        {
          "file": "1",
          "title": "Bitcoin: Nestátní peníze - úvod",
          "url": "/bitcoin-nestatni-penize/uvod",
          "lengthInMinutes": 15,
          "youtubeId": "V_Aijz_mYaE"
        },
        {
          "file": "2",
          "title": "Bitcoin není kryptoměna",
          "url": "/bitcoin-nestatni-penize/bitcoin-neni-kryptomena",
          "lengthInMinutes": 21,
          "youtubeId": "imzUsP9MSJA"
        },
        {
          "file": "3",
          "title": "Ekonomem snadno a rychle",
          "url": "/bitcoin-nestatni-penize/ekonomem-snadno-a-rychle",
          "lengthInMinutes": 23,
          "youtubeId": "jyKWKK-JYlU"
        },
        {
          "file": "4",
          "title": "Dobré vs. špatné peníze",
          "url": "/bitcoin-nestatni-penize/dobre-vs-spatne-penize",
          "lengthInMinutes": 22,
          "youtubeId": "EFX-dRqnClo"
        },
        {
          "file": "5",
          "title": "Dolar: 1785–1913",
          "url": "/bitcoin-nestatni-penize/dolar-1785-1913",
          "lengthInMinutes": 22,
          "youtubeId": "CRimGr4CL-Q"
        },
        {
          "file": "6",
          "title": "Dolar: 1913 - 1971",
          "url": "/bitcoin-nestatni-penize/dolar-1913-1971",
          "lengthInMinutes": 31,
          "youtubeId": "cQ9WaT1IPHc"
        },
        {
          "file": "7",
          "title": "Cantillonův efekt",
          "url": "/bitcoin-nestatni-penize/cantillonuv-efekt",
          "lengthInMinutes": 26,
          "youtubeId": "LfI9LDp-Lg4"
        },
        {
          "file": "8",
          "title": "Povaha státu",
          "url": "/bitcoin-nestatni-penize/povaha-statu",
          "lengthInMinutes": 25,
          "youtubeId": "oFK0iUVKbR0"
        },
        {
          "file": "9",
          "title": "Peněžní socialismus 1/2",
          "url": "/bitcoin-nestatni-penize/penezni-socialismus-1",
          "lengthInMinutes": 17,
          "youtubeId": "8y6s0nCLwuY"
        },
        {
          "file": "10",
          "title": "Peněžní socialismus 2/2",
          "url": "/bitcoin-nestatni-penize/penezni-socialismus-2",
          "lengthInMinutes": 22,
          "youtubeId": "OxqRDxJznHw"
        },
        {
          "file": "11",
          "title": "Bitcoin vs. zlato",
          "url": "/bitcoin-nestatni-penize/bitcoin-vs-zlato",
          "lengthInMinutes": 26,
          "youtubeId": "t5jZsrb6vbg"
        },
        {
          "file": "12",
          "title": "Historie koruny",
          "url": "/bitcoin-nestatni-penize/historie-koruny",
          "lengthInMinutes": 32,
          "youtubeId": "6Nx-T1FOzqQ"
        },
        {
          "file": "13",
          "title": "Bitcoinizace 1/2",
          "url": "/bitcoin-nestatni-penize/bitcoinizaca-1",
          "lengthInMinutes": 33,
          "youtubeId": "XKU0V7zUQJA"
        },
        {
          "file": "14",
          "title": "Bitcoinizace 2/2",
          "url": "/bitcoin-nestatni-penize/bitcoinizaca-2",
          "lengthInMinutes": 26,
          "youtubeId": "HJMPJkF-XW8"
        },
        {
          "file": "15",
          "title": "Závěrečné myšlenky a doporučení",
          "url": "/bitcoin-nestatni-penize/zaverecne-myslenky-a-doporuceni",
          "lengthInMinutes": 27,
          "youtubeId": "caMZVbxhN5A"
        },
      ]
    }
  ]
} as ICoursesConfig
