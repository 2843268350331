import { Box, Button, Stack, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import List from "components/elements/List";
import { Diamond } from "components/icons";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useIsLogin } from "hooks/useUser";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Pricing = () => {
  const { t } = useTranslation();
  const isLoggedIn = useIsLogin();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const badge = (bgcolor: string, content: JSX.Element) => {
    return (
      <Box borderRadius={"12px"} bgcolor={bgcolor} px={2} py={1}>
        {content}
      </Box>
    );
  };

  return (
    <HomeBox title={<Trans i18nKey="homepage.subscriptions" />} id={"cenik"} px={{ xs: 1, sm: 3, md: 12 }}>
      <Stack direction={{ xs: "column", lg: "row" }} pt={{ xs: 3, md: 9 }} textAlign={"start"}>
        <Box width={"100%"} px={isMobile ? "18px" : undefined} py={isMobile ? undefined : "18px"}>
          <Box
            sx={{
              borderTopLeftRadius: "24px",
              borderBottomLeftRadius: isMobile ? 0 : "24px",
              borderTopRightRadius: isMobile ? "24px" : 0,
            }}
            height={"100%"}
            color={colors["blueGray"]}
            border={`solid 1px ${alpha(colors["deepMidnightBlue"], 0.1)}`}
            px={{ xs: 2, md: 6 }}
            pt={{ xs: 2, md: 6 }}
            pb={{ xs: 4, md: 6 }}>
            <Box display={"flex"}>
              {badge(
                colors["mintGreen"],
                <Typography fontWeight={600} color={colors["deepMidnightBlue"]} fontSize={"1rem"}>
                  {t("homepage.free")}
                </Typography>
              )}
            </Box>
            <Typography
              sx={{ pt: 3 }}
              color={colors["deepMidnightBlue"]}
              fontWeight={600}
              fontSize={{ xs: "1.5rem", sm: "1.75rem" }}>
              {t("homepage.subscriptionsFree")}
            </Typography>
            <Typography sx={{ pt: 2 }} fontWeight={500} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
              {t("homepage.subscriptionsFreeClaim")}
            </Typography>
            <List
              data={[
                "homepage.subscriptionsFree1",
                "homepage.subscriptionsFree2",
                "homepage.subscriptionsFree3",
                "homepage.subscriptionsFree4",
                "homepage.subscriptionsFree5",
              ]}
            />
            {isLoggedIn === false && (
              <Button fullWidth href="#jak-zacit" variant="contained" sx={{ mt: 6 }}>
                {t("homepage.subscriptionsFreeCta")}
              </Button>
            )}
          </Box>
        </Box>
        <Box
          width={"100%"}
          borderRadius={"24px"}
          bgcolor={colors["deepMidnightBlue"]}
          sx={{ backgroundImage: "linear-gradient(90deg, rgba(17,24,39,1) 0%, rgba(138,149,175,0.2) 100%)" }}
          p={{ xs: 2, md: 6 }}
          color={"white"}>
          <Box display={"flex"}>
            {badge(
              colors["purple400"],
              <Stack spacing={1} direction={"row"}>
                <Diamond />
                <Typography fontWeight={600} fontSize={"1rem"}>
                  {t("homepage.premium")}
                </Typography>
              </Stack>
            )}
          </Box>
          <Typography sx={{ pt: 3 }} fontWeight={600} fontSize={{ xs: "1.5rem", sm: "1.75rem" }}>
            {t("homepage.subscriptionsPaid")}
          </Typography>
          <Typography sx={{ pt: 2 }} fontWeight={500} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
            {t("homepage.subscriptionsPaidClaim")}
          </Typography>
          <List
            color="white"
            data={[
              "homepage.subscriptionsPaid1",
              "homepage.subscriptionsPaid2",
              "homepage.subscriptionsPaid3",
              "homepage.subscriptionsPaid4",
              "homepage.subscriptionsPaid5",
              "homepage.subscriptionsPaid6",
            ]}
          />
          <Button
            to="/cenik"
            component={Link}
            variant="contained"
            fullWidth
            color="inherit"
            sx={{ mt: 6, color: colors["deepMidnightBlue"], backgroundColor: "white" }}>
            {t("homepage.subscriptionsPaidCta")}
          </Button>
        </Box>
      </Stack>
    </HomeBox>
  );
};

export default Pricing;
