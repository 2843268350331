import { useState } from "react";

import { Alert, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { updateDcaBotTargetValuePrice } from "API/calls";
import Modal from "components/elements/Modal";
import PriceField from "components/elements/PriceField";
import ResponsiveLabel from "components/elements/ResponsiveLabel";
import TooltipFreeUserUpsell from "components/elements/TooltipFreeUserUpsell";
import { CreditInverted } from "components/icons";
import { useFormik } from "formik";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useSnackbar } from "notistack";
import { useTranslation, Trans } from "react-i18next";
import { ConditionalWrapper } from "utils";
import { renderNumber } from "utils/formatter";
import * as yup from "yup";

interface IProps {
  id: string;
  isOpen: boolean;
  isFreeUser: boolean;
  baseCurrency?: string;
  counterCurrency?: string;
  exchangeEnum?: string;
  handleClose: () => void;
  refreshData: () => void;
  bidPrice?: number;
  targetValuePrice?: number;
}

const TargetValueModal = ({
  id,
  isOpen,
  isFreeUser,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  handleClose,
  refreshData,
  bidPrice,
  targetValuePrice,
}: IProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const currencyPair = `${baseCurrency}/${counterCurrency}`;
  const { counterCurrDisplayedScale, currencyPairPriceScale } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);

  const initValues = {
    targetPrice: targetValuePrice || "",
  };
  const formik = useFormik({
    initialValues: initValues,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      targetPrice: yup.string(),
    }),
    onSubmit: async () => {
      try {
        setIsLoading(true);
        let value: number | null = Number(`${formik.values.targetPrice}`.replace(",", "."));
        if (value === 0) {
          value = null;
        }
        await updateDcaBotTargetValuePrice(id, value);
        refreshData();
        handleClose();
        enqueueSnackbar(t("chartHistory.targetValue.success"), { variant: "success" });
      } catch (error: any) {
        if (error?.response?.status === 403) {
          enqueueSnackbar(t("chartHistory.targetValue.errorForbidden"), { variant: "error" });
        } else {
          enqueueSnackbar(t("chartHistory.targetValue.error"), { variant: "error" });
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  const getInitialValuesForm = () => {
    return (
      <Stack spacing={2}>
        {bidPrice && (
          <>
            <Alert severity="info" sx={{ mb: 1, "@media (max-width:400px)": { fontSize: "0.75rem" } }}>
              <Trans i18nKey="chartHistory.targetValue.info" />
            </Alert>
            <Box>
              <Typography variant="subtitle2">
                <Trans
                  i18nKey="chartHistory.targetValue.bidPrice"
                  values={{
                    baseCurrency,
                    counterCurrency,
                    price: bidPrice ? renderNumber(bidPrice, currencyPairPriceScale, true) : "",
                  }}
                />
              </Typography>
            </Box>
          </>
        )}
        <PriceField
          autoComplete="off"
          precision={counterCurrDisplayedScale}
          fullWidth
          onlyPositive
          allowZero
          id="targetPrice"
          name="targetPrice"
          label={ResponsiveLabel(t("chartHistory.targetValue.targetCounterValueLabel"))}
          value={formik.values.targetPrice}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.targetPrice)}
          helperText={formik.errors.targetPrice}
          inputProps={{
            maxLength: 19,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
          }}
        />
        {bidPrice && (
          <Box sx={{ pt: 1, pb: 3 }}>
            <Typography variant="subtitle2">
              <Trans i18nKey="chartHistory.targetValue.quickActionsTitle" />
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                color="info"
                size="small"
                onClick={() => formik.setFieldValue("targetPrice", bidPrice * 2)}>
                2x
              </Button>
              <Button
                variant="contained"
                color="info"
                size="small"
                onClick={() => formik.setFieldValue("targetPrice", bidPrice * 5)}>
                5x
              </Button>
              <Button
                variant="contained"
                color="info"
                size="small"
                onClick={() => formik.setFieldValue("targetPrice", bidPrice * 10)}>
                10x
              </Button>
            </Stack>
          </Box>
        )}
      </Stack>
    );
  };

  return (
    <Modal
      open={isOpen}
      size="xs"
      onCancel={handleClose}
      withCloseButton
      title={t("chartHistory.targetValue.title")}
      customConfirmButton={
        <ConditionalWrapper
          condition={isFreeUser}
          wrapper={(children) => <TooltipFreeUserUpsell>{children}</TooltipFreeUserUpsell>}>
          <Button
            disabled={isLoading}
            variant="contained"
            endIcon={isFreeUser ? <CreditInverted /> : undefined}
            onClick={() => (!isFreeUser ? formik.handleSubmit() : undefined)}>
            {t("chartHistory.targetValue.ctaSave")}
          </Button>
        </ConditionalWrapper>
      }>
      {getInitialValuesForm()}
    </Modal>
  );
};

export default TargetValueModal;
