export default {
  meta_title_suffix: " | Štosuj.cz",
  on: "Zapnuto",
  off: "Vypnuto",
  yes: "Ano",
  no: "Ne",
  blankPlaceholder: "—",
  close: "Zavřít",
  save: "Uložit",
  change: "Změnit",
  continue: "Pokračovat",
  copyToClipboard: "Zkopírovat do schránky",
  in: "v",
  of: "z",
  rowsPerPage: "Počet řádků na stránku:",
  email: "E-mail",
  password: "Heslo",
  passwordNew: "Nové heslo",
  passwordNewCheck: "Nové heslo (potvrzení)",
  scale: {
    linear: "Lineární",
    log: "Logaritmický",
  },
  home: "Úvodní stránka",
  whyBitcoinSidebarLink: "Proč Bitcoin?",
  goToHome: "Přejít na úvod",
  overview: "Můj přehled",
  terms: "Podmínky služby",
  dcaCalcName: "Historická výnosnost spoření do\u00a0Bitcoinu",
  dcaCalc: "DCA kalkulačka",
  communityAndContact: "Komunita a kontakt",
  collect: "Vyzvednout",
  youHaveCollected: "Získáno",
  moreInfo: "Více informací",
  faq: "Časté dotazy",
  value: "Hodnota",
  targetValue: "Cílová hodnota",
  invested: "Pořizovací částka",
  back: "Zpátky",
  amountAccumulated: "Množství",
  formHasErrors: "Prosím překonroluj formulář, obsahuje validační chyby.",
  buyTrezor: "Peněženky Trezor",
  meta_title: "Štosuj.cz - Vlastní Bitcoin strategie, bez emocí",
  meta_description:
    "Nejlepší řešení pro automatizaci své investiční strategie. Pravidelné nákupy metodou DCA, grid strategie obchodování cenové volatility, exit strategie pro realizaci zisků.",
  login: "Přihlášení",
  registration: "Registrace",
  accountFree: "Bezplatný účet",
  price: "Cena",
  profit: "Zisk",
  averagePrice: "Průměrná nákupní cena",
  priceForOneMonth: "Cena za 1 měsíc",
  discount: "Sleva",
  youSave: "Ušetříš",
  withoutTax: "{{price}} bez DPH",
  exchangePromo: "Vyzkoušet",
  exchangePromoLine1: "Vyzkoušej českou burzu\u00a0<strong>Coinmate.io</strong>",
  exchangePromoLine2: "Obchoduj přímo v\u00a0českých\u00a0korunách",
  foreverFree: "Navždy zdarma",
  dcaStrategiesCount: "Počet vytvořených DCA strategií",
  dcaStrategiesCountLabel: "oddělené investice",
  dynamicDcaOrderValue: "Dynamické DCA strategie",
  dynamicDcaOrderValueLabel: "podle indikátorů",
  showTutorialLink: "návod",
  maxActiveBots: "Počet aktivních grid strategií",
  maxActiveBotsLabel: "obchodování cenové volatility",
  manualOrders: "Ruční zápis transakcí",
  manualOrdersLabel: "evidence portfolia",
  gridStrategyMinPercentStep: "Nejkratší vzdálenost objednávek",
  gridStrategyMinPercentStepLabel: "na grid strategii",
  limitOrdersCount: "Počet limitních objednávek",
  inASingleOrder: "v 1 pravidelné DCA frekvenci",
  singleLimitOrdersCount: "Samostatných limitních objednávek",
  inDcaBot: "na každé DCA strategii",
  currencyPair: "Měnový pár",
  currencyPairs: "Měnové páry",
  currencyPairsList: "zobrazit podporované",
  currencyPairsFree: "Pouze BTC",
  currencyPairsPlus: "Všechny <a>podporované</a>",
  currencyPairsPremium: "Všechny <a>podporované</a>",
  dcaFrequency: "Frekvence DCA strategií",
  dcaFrequencyFree: "Měsíční nebo\u00a0týdenní",
  dcaFrequencyPlus: "Vlastní frekvence každých X dní",
  dcaFrequencyPremium: "Chystáme i volbu hodiny nebo DCA každou hodinu",
  initialAmounts: "Nastavení počátečního stavu",
  targetValueFeature: "Nastavení cílové hodnoty",
  forDcaBot: "na DCA strategii",
  btcWithdrawal: "Automatický výběr BTC",
  toAddress: "na vlastní peněženku",
  exitStrategy: "Další automatické strategie",
  comingSoon: "*v přípravě - exit strategie, rebalancing",
  fillLimitOrdersFeature: "Naplnění limitních objednávek",
  fillLimitOrdersFree: "Vždy za aktuální cenu, když k poklesu/nárůstu\u00a0nedojde",
  fillLimitOrdersPlus: "Volitelně za aktuální cenu, když k poklesu/nárůstu\u00a0nedojde",
  fillLimitOrdersPremium: "Volitelně za aktuální cenu, když k poklesu/nárůstu\u00a0nedojde",
  dashboardStrategiesDetails: "Detailní zobrazení strategií",
  dashboardStrategiesDetailsOnDashboard: "přímo na Přehledu",
  dashboardStrategiesSummary: "Souhrn všech strategií",
  dashboardStrategiesSummaryOnDashboard: "na Přehledu",
  exitStrategyFree: "Základní nastavení*",
  exitStrategyPlus: "Pokročilé nastavení*",
  exitStrategyPremium: "Pokročilé nastavení*",
  subscriptionFeatureComingSoon: "Už brzy!",
  soon: "Brzy",
  header: {
    strategies: "Výběr strategie",
    pricing: "Ceník",
    whyBitcoin: "Proč Bitcoin?",
  },
  datetime: {
    at: "v",
    month: "měsíc",
  },
  count: {
    of: "z",
  },
  create: "Vytvořit",
  createConnection: "Nové propojení",
  update: "Uložit",
  cancel: "Zrušit",
  confirm: "Potvrdit",
  from: "Od",
  to: "Do",
  delete: "Smazat",
  edit: "Upravit",
  pause: "Pozastavit",
  start: "Začátek",
  end: "Konec",
  fieldRequired: "Toto pole je povinné",
  fieldMaxLength: "Pole musí obsahovat maximálně {{max}} znaků",
  minOrderValues: "Minimální hodnota objednávky je {{value}}\u00a0{{currency}}",
  maxOrderValues: "Maximální hodnota objednávky je {{value}}\u00a0{{currency}}",
  minOrderValuesFallback: "Hodnota objednávky musí být větší než {{value}}\u00a0{{currency}}",
  minLimitPriceFallback: "Limitní cena musí být kladná",
  insufficientFunds: "Nemáš dostatečný volný\u00a0zůstatek",
  minLimitValueFallback: "Limitní cena musí být větší než {{value}}\u00a0{{currency}}",
  maxLimitValueFallback: "Limitní cena musí být menší než {{value}}\u00a0{{currency}}",
  currencySymbol: {
    CZK: 'Kč',
  },
  fieldErrors: {
    onlyNumber: "Toto pole musí obsahovat pouze číslice",
    positiveNumber: "Toto pole musí být kladné číslo",
    integerNumber: "Toto pole musí být kladné celočíslo",
    email: "E-mail musí být platný",
    password: "Tvé heslo musí mít alespoň 8\u00a0znaků a\u00a0obsahovat: 1\u00a0číslici, 1\u00a0velké\u00a0písmeno a 1\u00a0malé\u00a0písmeno",
    passwordCheck: "Hesla se musí shodovat",
    postalCode: "PSČ musí být platné",
    businessIdentificationNumber: "IČO musí být platné",
    businessTaxNumber: "DIČ / IČ DPH musí být platné",
    btcAddress: "Neplatný formát BTC adresy.",
  },
  commonError: "Vyskytla se chyba. Zkuste to znovu později.",
  emailVerification: {
    meta_title: "Ověření e-mailu",
    successMessage: "Ověření e-mailu proběhlo úspěšně.",
    error400: "Odkaz pro ověření e-mailu není platný.",
    error428: "E-mail uživatele je již ověřen.",
    error403: "Platnost odkazu pro ověření e-mailu vypršela.",
    youCanLogIn: "Nyní se můžeš přihlásit.",
    resendVerificationEmail: "Opětovné zaslání ověřovacího e-mailu",
    successResendVerificationEmail: "Na e-mail ti byl zaslán nový token",
  },
  registrationForm: {
    meta_title: "Registrace",
    create: "Registrace",
    email: "E-mail",
    password: "Heslo",
    language: "Jazyk / Language",
    agreement: "Souhlasím s ",
    terms: "Podmínkami\u00a0služby",
    termsAgreementRequired: "Je vyžadován souhlas s Podmínkami služby",
    successMessage: "Registrace proběhla úspěšně",
    errorMessage: "Při registraci nastala chyba",
    thanksForRegistering: "Díky za registraci!",
    userAlreadyExists: "Uživatel s tímto e-mailem již existuje",
    verifyYourEmailAddress: "Nyní prosím otevři odkaz, který jsme ti zaslali k <strong>ověření tvé e‑mailové adresy.</strong>",
    registerButton: "Registrovat",
    emailVerificationWarning: {
      title: "Ověřovací e-mail nedorazil?",
      subtitle1: "Zpráva pro ověření tvé adresy by ti měla dorazit do 2 minut. <strong>Zkontroluj prosím i Spam/Nevyžádanou poštu.</strong>",
      subtitle2: "Pokud ti zpráva ani do hodiny nepřijde, vyplň prosím svůj e-mail v <a>Obnovení zapomenutého hesla</a>. Měl by ti přijít e-mail pro nastavení nového hesla a zároveň se tvá adresa ověří.",
    },
  },
  loginForm: {
    meta_title: "Přihlášení",
    login: "Přihlášení",
    email: "E-mail",
    password: "Heslo",
    rememberMe: "Zapamatovat si\u00a0mě",
    forgottenPassword: "Zapomenuté heslo?",
    noAccount: "Ještě nemáš účet?",
    registerHere: "Registruj se tady!",
    loginButton: "Přihlásit",
    successMessage: "Přihlášení proběhlo úspěšně.",
    errorMessage: "Přihlašovací údaje nejsou správné.",
    mfaAuthRequired: "Bezpečnostní ověření",
    mfaWarningTitle: "Máš zálohu nastavení svého 2FA zařízení?",
    mfaWarning1: "Když teď ztratíš/přeinstaluješ svůj telefon, dokážeš 2FA ze zálohy obnovit?",
    mfaWarning2: "Pokud ne, my ti účet nedokážeme odblokovat!",
    mfaWarning3: "2FA si u nás raději deaktivuj, nastav znovu a zapiš si textový kód pro obnovu!!!",
    send: "Odeslat",
  },
  dcaBots: {
    meta_title: "Strategie",
    negativeTotalAmount: {
      title: "Pozor, něco tu nehraje!",
      description: "Došlo k odprodeji většího množství, než evidujeme na straně\u00a0nákupů.<br/>Nejspíše jsi se jednorázovým prodejem zbavil/a i množství, které nebylo nakoupeno přes tuto strategii.<br/><br/>To je v pořádku, ale pro správné zobrazení statistik je potřeba nastavit <a>Počáteční stav</a>, tedy jaké množství a za jakou pořizovací částku už máš nakoupeno odjinud. Nebo můžeš využít funkci ručního zápisu transakce provedené mimo Štosuj. Jinak nedokážeme vypočítat celkovou průměrnou nákupní cenu a další statistiky.",
      shortDescription: "Došlo k odprodeji většího množství, než evidujeme na straně\u00a0nákupů.<br/>Nejspíše jsi se jednorázovým prodejem zbavil/a i množství, které nebylo nakoupeno přes tuto strategii.",
    },
    hasManualOrders: {
      title: "Obsahuje manuální transakce",
      description: "Pozor, s předplatným PREMIUM jsi do tohoto portfolia zapsal/a ruční transakce, ale momentálně <strong>PREMIUM nemáš aktivní</strong>, ruční transakce se ti ve statistikách, historii a grafech nezobrazují.",
    },
    addNewSubscriptionPausedError: "Tvůj účet je momentálně pozastaven. Doplň Štosáky nebo přejdi zpět na předplatné FREE.",
    header: {
      DCA_BUY: "Nákup",
      DCA_SELL: "Prodej",
      MANUAL: "Portfolio",
      betweenCurrencies: {
        DCA_BUY: "za",
        DCA_SELL: "do",
      },
    },
    exchange: "Burza {{exchange}}",
    brokerage: "Směnárna {{exchange}}",
    tile: {
      exchange: "Burza",
      brokerage: "Směnárna",
      strategy: "Strategie",
      savingsTarget: "Cílová částka",
      frequency: "Frekvence",
      MARKET: "MARKET - nákup za aktuální cenu",
      LIMIT: "LIMIT - nákup při cenovém poklesu",
    },
    totalInvested: "Pořizovací částka",
    currentValue: "Aktuální hodnota",
    confirmEnable: {
      title: "Aktivovat strategii?",
      description: "Strategie se opět zařadí mezi aktivní strategie a v případě nastaveného Režimu strategie pro pravidelné DCA nákupy/prodeje se opět budou objednávky vytvářet podle nastavené frekvence.",
    },
    confirmDisable: {
      title: "Zneaktivnit strategii?",
      description:
        "Strategie se deaktivuje a schová se pod 'Neaktivní strategie'.<br/><br/>Své statistiky stále uvidíš a můžeš strategii opět aktivovat později. Kdykoliv můžeš také udělat jednorázový nákup/prodej nebo zapisovat/upravovat ruční transakce.<br/><br/><strong>Tip:</strong> Pokud chceš pouze pozastavit pravidelné DCA nákupy/prodeje, ale strategii stále vidět mezi aktivními, přejdi do úpravy nastavení a vyber Režim strategie 'Chci si pouze evidovat portfolio'.",
    },
    enabled: "Aktivní od",
    disabled: "Pozastaven ",
    activate: "Aktivovat",
    pause: "Deaktivovat",
    purchaseNow: {
      market: {
        label: {
          DCA_BUY: "Nakup okamžitě",
          DCA_SELL: "Prodej okamžitě",
        },
        for: "za",
      },
      limit: {
        for: "za",
        label: {
          DCA_BUY: "Nakup při <strong>{{percentChange}}%</strong> poklesu\u00a0ceny",
          DCA_SELL: "Prodej při <strong>{{percentChange}}%</strong> nárůstu\u00a0ceny",
        },
        executeAsMarketWhenNotFilled: "nebo na konci frekvence",
      },
    },
    strategyLabel: "Strategie objednávek",
    mode: {
      label: "Režim strategie",
      DCA_BUY: "Pravidelný nákup",
      DCA_SELL: "Pravidelný prodej",
      MANUAL: "Evidence portfolia",
    },
    dynamicStrategy: {
      label: "Dynamická\u00a0strategie",
      minMaxMultiplierLabel: "Násobitel hodnot objednávek",
      minMaxMultiplierValue: "mezi\u00a0<strong>x{{min}}</strong>\u00a0a\u00a0<strong>x{{max}}</strong>",
      rangeTemp: "Dynamicky mezi <strong>{{from}}\u00a0{{currency}}</strong> a <strong>{{to}}\u00a0{{currency}}</strong>",
      range: {
        1: "Dynamicky mezi",
        2: "a",
      },
      orderInputValue: "- výchozí částka",
      orderCurrentDynamicValue: "- aktuálně <strong>x{{multiplier}}</strong> =",
      chart: "Vývoj {{type}} indikátoru",
      tooltipMultiplier: "Násobitel",
    },
    frequency: {
      label: "Frekvence objednávek",
      month: "Každý {{day}}.\u00a0den v\u00a0měsíci",
      week: "Každý týden {{day}}",
      day1: "Každý\u00a0{{day}}\u00a0den",
      day2: "Každé\u00a0{{day}}\u00a0dny",
      day3: "Každých\u00a0{{day}}\u00a0dní",
      days: {
        monday: "pondělí",
        tuesday: "úterý",
        wednesday: "středu",
        thursday: "čtvrtek",
        friday: "pátek",
        saturday: "sobotu",
        sunday: "neděli",
        onmonday: "v\u00a0pondělí",
        ontuesday: "v\u00a0úterý",
        onwednesday: "ve\u00a0středu",
        onthursday: "ve\u00a0čtvrtek",
        onfriday: "v\u00a0pátek",
        onsaturday: "v\u00a0sobotu",
        onsunday: "v\u00a0neděli",
      },
      every: {
        1: "každý týden",
        2: "každý",
        3: "každé",
        4: "každých",
      }
    },
    settings: "Nastavení",
    youHaveSaved: "Máš nakoupeno",
    savingsTarget: "Cíl spoření",
    notSet: "Nenastaveno",
    alreadySaved: "Již naspořeno",
    averagePrice: "Průměrná nákupní cena",
    bidPrice: "Aktuální prodejní kurz",
    askPrice: "Aktuální nákupní kurz",
    botCreated: "Vytvořen ",
    botPaused: "Pozastaven ",
    botLastUpdated: "Upraven ",
    editSettings: "Upravit nastavení",
    successMessageEnable: "Strategie byla aktivována",
    successMessageDisable: "Strategie byla pozastavena",
    errorMessageEnable: "Strategii se nepodařilo aktivovat",
    errorMessageDisable: "Strategii se nepodařilo pozastavit",
    errorMessage423: "Strategie právě teď běží, zkus to později",
    enabledChangedAt: "Aktivní od:",
    disabledChangedAt: "Neaktivní od:",
    confirmDelete: {
      title: "Smazat strategii?",
      description: "Opravdu chceš odstranit tuto strategii?",
    },
    deleteSuccessMessage: "Strategie byla smazána",
    deleteErrorMessage: "Strategii se nepodařilo smazat",
    create: {
      meta_title: "Nová strategie",
      meta_title_update: "Upravit strategii",
      basicSettings: "Základní nastavení",
      modeTitle: "Režim strategie",
      modeDescription: "Metodou <strong>DCA (Dollar-Cost Averaging)</strong> můžeš jak pravidelně nakupovat, tak pravidelně prodávat. Chystáme i\u00a0kombinaci nákupů/prodejů podle různých podmínek a\u00a0samostatnou exit strategii pro realizaci zisku podle růstu ceny.",
      modeChangeDescription: "Režim strategie můžeš kdykoliv upravit, podle toho jestli chceš aktuálně nakupovat, prodávat, nebo jen držet.",
      initialAmountsDescription: "<strong>Tip:</strong> Pokud už máš nakoupené kryptoměny z dřívějška, tak si můžeš po vytvoření strategie na kartě Historie objednávek nastavit <strong>Počáteční stav</strong> a zaevidovat si aktuálně držené množství - tvé statistiky se začnou počítat od tohoto bodu. Všechny transakce provedené přes Štosuj se ti zaznamenají automaticky.",
      mode: {
        DCA_BUY: "Chci pravidelně nakupovat",
        DCA_SELL: "Chci pravidelně prodávat",
        COMBO: "Chci nakupovat i\u00a0prodávat (v\u00a0přípravě)",
        MANUAL: "Chci si pouze evidovat portfolio - ruční zápis transakcí nebo jednorázové obchody, bez nastavení pravidelných obchodů",
      },
      otherInformation: "Doplňkové informace (nepovinné)",
      otherInformationDescription: "Cílovou částku nemusíš zadávat, ale bude se hodit pro zobrazení postupu, když spoříš na konkrétní cíl, např. na nové auto. <strong>Tip:</strong> Zkus si vytvořit více DCA strategií a spoř odděleně třeba na svůj důchod, pro děti nebo na vysněnou dovolenou.",
      successMessage: "Byla vytvořena nová strategie",
      errorMessage: "Novou strategii se nepodařilo vytvořit",
      editSuccessMessage: "Strategie byla aktualizována",
      editErrorMessage: "Strategii se nepodařilo aktualizovat",
      header: "Nová strategie",
      headerUpdate: "Upravit strategii",
      availableExchangeConnections: "Dostupná propojení",
      noExchangeConnectionData: "Nejprve si vytvoř propojení s burzou/směnárnou.",
      currencyPair: "Měnový pár",
      label: "Popisek - vlastní pojmenování",
      savingsTarget: "Cílová částka spoření",
      buyStrategy: "Hodnoty objednávek",
      fixedValue: "Fixní částka",
      dynamicValue: "Dynamická částka",
      dynamicValueInfoTitle: "Jak fungují dynamické DCA strategie?",
      dynamicValueInfoText: {
        DCA_BUY: "Místo fixní částky dokáží dynamické strategie pronásobit <strong>výchozí částku</strong> tvých objednávek tak, aby se nakupovalo za <strong>vyšší částku</strong> ve vhodnější okamžik a za <strong>nižší částku</strong> v méně výhodném období.",
        DCA_SELL: "Místo fixní částky dokáží dynamické strategie pronásobit <strong>výchozí částku</strong> tvých objednávek tak, aby se prodávalo za <strong>vyšší částku</strong> ve vhodnější okamžik a za <strong>nižší částku</strong> v méně výhodném období.",
      },
      dynamicValueInfoAlertTitle: {
        DCA_BUY: "Podrobný návod a backtest",
        DCA_SELL: "Podrobný návod",
      },
      dynamicValueInfoAlertText1: {
        DCA_BUY: "Cílem je nakoupit <strong>více bitcoinu s\u00a0nižší průměrnou nákupní cenou</strong> a\u00a0dosáhnout tak vyššího zhodnocení.",
        DCA_SELL: "Cílem je prodat <strong>bitcoin za vyšší částku při lepší příležitosti</strong> a\u00a0dosáhnout tak vyššího zisku.",
      },
      dynamicValueInfoAlertText2: "Podívej se na náš <a>podrobný\u00a0návod</a>, kde najdeš detailní vysvětlení a\u00a0příklady různých nastavení.",
      dynamicValueInfoAlertText3: "Vyzkoušet si své nastavení můžeš také na interaktivním <a>backtestu dynamické DCA\u00a0strategie</a> na historických datech.",
      modeFreeError: "V základním předplatném FREE lze zdarma využít režim pravidelných prodejů pouze pro české služby Coinmate a Anycoin. V předplatných PLUS a PREMIUM získáš možnost pravidelně prodávat na všech burzách.",
      dynamicStrategyLabel: {
        types: {
          BTC_NUPL: "NUPL: Net Unrealised Profit/Loss",
          BTC_FEAR_AND_GREED: "F&G: Fear & Greed Index",
        },
        typesShort: {
          BTC_NUPL: "NUPL",
          BTC_FEAR_AND_GREED: "F&G",
        },
        alert: "Pro tento měnový pár zatím nemáme dynamickou strategii. Aktuálně podporujeme <strong>NUPL indikátor</strong> a <strong>Fear & Greed Index</strong> pro <strong>bitcoinové měnové páry</strong>.",
        other: "Další dynamické strategie brzy",
        upsell: {
          title: "Aktivuj si PREMIUM teď a\u00a0vyděláš více!",
          description: "Optimalizuj své pravidelné nákupy pomocí naší dynamické DCA strategie a dosáhni většího zhodnocení.<br/><br/>Aktivuj si <a1>předplatné PREMIUM</a1> a\u00a0odemkni všechny funkce!<br/>Doporučujeme aktivovat si automatickou <a2>měsíční platbu</a2> kartou.",
        }
      },
      dynamicStrategy: {
        label: "Výběr dynamické strategie",
        settings: "Nastavení dynamické strategie",
        link: "Detailní informace o {{strategy}} indikátoru",
        multiplierInfo: "Dynamicky mezi <strong>x{{minMultiplierString}}\u00a0({{from}})</strong> a\u00a0<strong>x{{maxMultiplierString}}\u00a0({{to}})</strong>, aktuálně\u00a0<strong>x{{multiplier}}\u00a0({{current}})</strong>.",
        multiplierInfoOnBacktest: "Částka se vypočítá dynamicky mezi <strong>x{{minMultiplierString}}\u00a0({{from}})</strong> a\u00a0<strong>x{{maxMultiplierString}}\u00a0({{to}})</strong>, aktuální násobitel <strong>x{{multiplier}}\u00a0({{current}})</strong>, tj. budete nyní potřebovat <strong>zhruba {{monthlyCurrently}} měsíčně</strong>, s\u00a0maximálním násobením až <strong>{{monthlyMax}} měsíčně</strong>.",
        intro: {
          BTC_NUPL: "Dlouhodobý <strong>Net Unrealized Profit/Loss indikátor</strong> zobrazuje <strong>vztah mezi tržní kapitalizací bitcoinu</strong> (počet BTC v oběhu * aktuální cena) a <strong>realizovanou hodnotou</strong> (hodnota každého BTC při jeho posledním pohybu). Odhaduje tím jak jsou <strong>celkově současní držitelé v nerealizovaném profitu/ztrátě</strong>.",
          BTC_FEAR_AND_GREED: "<strong>Index strachu a chamtivosti</strong> ukazuje <strong>aktuální sentiment na trhu</strong>. Může se měnit velmi rychle, a tak poslouží i k indikaci <strong>krátkodobé nákupní/prodejní příležitosti</strong>. Oproti NUPL indikátoru, který dobře vystihuje pozici v celém bitcoinovém cyklu, lze Fear & Greed Index použít k dočasné úpravě pravidelné částky <strong>při prudkém propadu či extrémnímu nárůstu ceny</strong>.",
        },
        intro2: "Podle aktuální hodnoty indikátoru v den vytváření tvých objednávek použijeme <strong>vypočítaný násobitel</strong> ke zvýšení/snížení hodnoty objednávky.",
        chartError: "Graf vývoje {{type}} indikátoru se nepodařilo načíst",
        chartIntro: {
          DCA_BUY: "Když {{type}} hodnota (modře) klesá, tak se nakupuje za vyšší částku a\u00a0naopak.",
          DCA_SELL: "Když {{type}} hodnota (modře) roste, tak se prodává za vyšší částku a\u00a0naopak.",
        },
        minMultiplier: "Minimální násobitel",
        maxMultiplier: "Maximální násobitel",
        advancedSettings: "Pokročilé nastavení {{type}} úrovní",
        legend: {
          price: "BTC/USD\u00a0cena",
          strategy: "{{type}} hodnota a\u00a0úrovně",
          value: "aktuálně\u00a0<strong>{{value}}\u00a0%</strong>",
        },
        topLineLabel: "vrchol",
        centerLineLabel: "střed",
        bottomLineLabel: "dno",
        currentValue: "Aktuální {{type}} hodnota: {{value}}\u00a0%",
        currentMultiplierValue: "Vypočtený aktuální násobitel: x{{value}}",
        description1: {
          DCA_BUY: "Hodnota {{type}} indikátoru (modrá čára) se pohybuje mezi <strong>{{top}}\u00a0%</strong> (nejhorší čas na nákup) a <strong>{{bottom}}\u00a0%</strong> (nejlepší čas na nákup).",
          DCA_SELL: "Hodnota {{type}} indikátoru (modrá čára) se pohybuje mezi <strong>{{top}}\u00a0%</strong> (nejlepší čas na prodej) a <strong>{{bottom}}\u00a0%</strong> (nejhorší čas na prodej).",
        },
        description2: {
          DCA_BUY: "Jako <strong>střed</strong> s násobitelem <strong>x1</strong> jsme předdefinovali hodnotu <strong>{{center}}\u00a0%</strong>. Na nastavené středové úrovni se nakupuje za výchozí částku.",
          DCA_SELL: "Jako <strong>střed</strong> s násobitelem <strong>x1</strong> jsme předdefinovali hodnotu <strong>{{center}}\u00a0%</strong>. Na nastavené středové úrovni se prodává za výchozí částku.",
        },
        description3: {
          DCA_BUY: "Od středu k tvé <strong>úrovni vrcholu</strong> se nákupní částka postupně <strong>snižuje</strong> a od středu k tvé <strong>úrovni dna</strong> se postupně <strong>zvyšuje</strong>.",
          DCA_SELL: "Od středu k tvé <strong>úrovni vrcholu</strong> se prodejní částka postupně <strong>zvyšuje</strong> a od středu k tvé <strong>úrovni dna</strong> se postupně <strong>snižuje</strong>.",
        },
        description4: "Úrovně pro <strong>dno</strong>, <strong>střed</strong> a <strong>vrchol</strong> si libovolně uprav posuvníkem výše. V\u00a0grafu vidíš všechny 3 úrovně jako modré horizontální čáry.",
      },
      subscriptionLimit: {
        with: "S předplatným",
        onlyFREE: "můžeš mít jen {{number}} strategie.",
        onlyPLUS: "můžeš mít jen {{number}} strategií.",
        onlyPREMIUM: "můžeš mít maximálně {{number}} strategií.",
        question: "Chceš navýšit předplatné?",
      },
      frequency: {
        label: "Frekvence spouštění strategie",
        weekly: "1x týdně",
        monthly: "1x měsíčně",
        custom: {
          selected: "vlastní -",
          normal: "vlastní - každých X dní",
        },
        monthlyRadio: {
          always: "vždy ",
          inMonth: ". den v měsíci",
        },
        dailyRadio: {
          day1: {
            each: "každý",
            day: "den",
          },
          day2: {
            each: "každé",
            day: "dny",
          },
          day3: {
            each: "každých",
            day: "dní",
          },
        },
        weeklyRadio: {
          always: "vždy",
        },
      },
      strategyLabel: "Výběr strategie",
      market: {
        DCA_BUY: "MARKET - nákup za aktuální\u00a0cenu",
        DCA_SELL: "MARKET - prodej za aktuální\u00a0cenu",
      },
      limit: {
        DCA_BUY: "LIMIT - nákup při cenovém\u00a0poklesu",
        DCA_SELL: "LIMIT - prodej při cenovém\u00a0nárůstu",
      },
      limitDynamicAlert: {
        title: "Nejprve uprav frekvenci",
        description: "Pro LIMIT strategii s dynamickou částkou je možné vybrat frekvenci <strong>maximálně každé 3\u00a0dny</strong>, aby hodnota indikátoru nezastarala.",
      },
      marketOrderValue: {
        DCA_BUY: "Nakupovat za",
        DCA_SELL: "Prodávat za",
      },
      dynamicStrategyOrderValue: {
        DCA_BUY: "Výchozí částka nákupu",
        DCA_SELL: "Výchozí částka prodeje",
      },
      dynamicStrategyOrderValueOnBacktest: "Výchozí denní částka nákupu",
      marketOrderTitle: "Hodnota market objednávky",
      limitOrder: {
        title: "Nastavení limitních objednávek",
        label: {
          DCA_BUY: "Nakoupit při poklesu ceny\u00a0o",
          DCA_SELL: "Prodat při nárůstu ceny\u00a0o",
        },
        errorDuplicatedPercentChange: "Není možné mít více limitních objednávek se stejnou procentuální změnou ceny.",
        orderValue: {
          DCA_BUY: "Nakupovat za",
          DCA_SELL: "Prodávat za",
        },
        checkboxValue: {
          DCA_BUY: "Nakoupit za aktuální cenu, pokud cena do konce {{period}} frekvence neklesne.",
          DCA_SELL: "Prodat za aktuální cenu, pokud cena do konce {{period}} frekvence nenaroste.",
        },
        nextOrderButton: "Přidat\u00a0{{number}}.\u00a0limitní\u00a0objednávku",
        weekly: "týdenní",
        monthly: "měsíční",
        daily: "denní",
        free: {
          info1: {
            DCA_BUY: "Pokud cena do konce {{period}} frekvence o tolik neklesne, nakoupí se za aktuální cenu, ať je jakákoliv.",
            DCA_SELL: "Pokud cena do konce {{period}} frekvence o tolik nenaroste, prodá se za aktuální cenu, ať je jakákoliv.",
          },
          info2: {
            DCA_BUY: "Pro následující období se opět vytvoří nová limitní objednávka o {{percentChange}}\u00a0% pod aktuální cenou.",
            DCA_SELL: "Pro následující období se opět vytvoří nová limitní objednávka o {{percentChange}}\u00a0% nad aktuální cenou.",
          }
        }
      },
      alert: {
        currencyPair: {
          freeCzkEurInfoTitle: "Nakupuješ za CZK nebo\u00a0EUR?",
          freeCzkEurInfo: "<strong>Měnový pár</strong> vyber podlo toho, jestli chceš bitcoin (BTC) nakupovat za české koruny, nebo za eura.",
          free: "Základní FREE předplatné naší služby Štosuj.cz ti umožňuje automaticky nakupovat/prodávat bitcoin (BTC) jednou měsíčně nebo jednou\u00a0týdně.<br><br>Další měnové páry, kratší frekvence obchodů a\u00a0další pokročilé funkce jsou ti k\u00a0dispozici po přechodu na\u00a0<a>vyšší\u00a0předplatné</a>.",
        },
        frequency: {
          free: "Vlastní frekvenci pro pravidelnou směnu každých X\u00a0dní odemkneš po přechodu na <a>vyšší\u00a0předplatné</a>.",
        },
        limitOrders: {
          free: "Po přechodu na <a>vyšší\u00a0předplatné</a> můžeš vytvořit až 10 limitních objednávek pro rozdělení částky na více cenových úrovní.",
          plus: "Po přechodu na <a>předplatné\u00a0PREMIUM</a> můžeš vytvořit až 10 limitních objednávek.",
        },
        minAmount1: "Minimální množství objednávky je <strong>{{minAmountBaseCurrency}}</strong>. Při současné ceně {{price}} to vychází <strong>přibližně na {{minAmountCounterCurrency}}</strong>.",
        minAmount2: "<strong>Zadávej vždy vyšší částku s rezervou</strong>, aby se objednávky správně vytvořily i při rychlém růstu ceny.",
      },
      initialRun: {
        title: {
          create: "Vytvoření objednávek",
          update: "Upravit objednávky nyní?",
        },
        description: {
          create: "Aktuální volný zůstatek na {{exchange}}: <strong>{{amount}}</strong>",
          update: "Došlo k úpravě nastavení limitních objednávek, chceš je po uložení předělat podle aktuálního nastavení? Pokud zaškrtneš Ano, tak dojde ke zrušení předchozích objednávek a vytvoření nových podle upraveného nastavení.<br/><br/>V\u00a0opačném případě se nové nastavení použije až po dalším spuštění podle tvé frekvence spouštění strategie.",
        },
        checkboxLabel: {
          create: "Spustit vytvoření limitních objednávek v\u00a0hodnotě <strong>{{value}}</strong>\u00a0hned\u00a0teď {{notEnoughCounter}}",
          update: "Ano, upravit limitní objednávky podle aktuálního nastavení.",
        },
        notEnoughCounter: "nedostatečný volný zůstatek",
      },
      createUpdateButtonSuffix: "strategii",
    },
    balances: {
      storedOnExchange: "Uloženo na burze",
      storedOnBrokerage: "Uloženo na směnárně",
      available: "Volný zůstatek",
      frozen: "Uzamčený zůstatek",
      frozenDesc: "Zůstatek, který není volně k dispozici - například je uzamčený v otevřené limitní objednávce.",
      buySellNow: "Jednorázový nákup/prodej\u00a0{{baseCurrency}}",
      addManualTransaction: "Ruční zápis transakce",
      buySellNowTitle: "Jednorázový nákup/prodej\u00a0{{baseCurrency}}",
      instaBuy: "Okamžitý příkaz",
      limitBuy: "Limitní příkaz",
      limitBuyInfoTitle: "Limitní příkaz vytvoří objednávku na nižší nákupní nebo vyšší prodejní\u00a0ceně",
      limitBuyInfoDesc: "Objednávka tak po vytvoření bude čekat, než se k ní aktuální nákupní/prodejní kurz\u00a0dostane. Tyto\u00a0samostatné objednávky jsou nezávislé na frekvenci tvých pravidelných nákupů.",
      maxOpenInstantLimitWarning: {
        plusTitle: "Předplatné PLUS ti dovoluje mít otevřeno pouze 10 samostatných limitních příkazů.",
        plusDesc: "Pro otevření až 20 limitních příkazů najednou si aktivuj <a>předplatné\u00a0PREMIUM</a>.",
        premiumTitle: "Už máš maximální počet 20 otevřených jednorázových limitních příkazů.",
      },
      manualOrder: {
        title: "Ruční zápis transakce",
        update: "Upravit ruční transakci",
        BUY: "Nákup",
        SELL: "Prodej",
        type: "Typ",
        amount: "Množství",
        value: "Hodnota objednávky",
        time: "Datum a čas",
        note: "Libovolná poznámka k transakci",
        rate: "Vypočtený kurz za",
        createSuccess: "Transakce byla zaznamenána",
        createError: "Transakci se nepodařilo zaznamenat",
        updateSuccess: "Transakce byla aktualizována",
        updateError: "Transakci se nepodařilo aktualizovat",
        premiumUpsellAlert: {
          title: "Vyzkoušej PREMIUM",
          description: "Ruční zápis transakcí a další funkce odemkneš aktivací našeho <a>předplatného PREMIUM</a>.",
        }
      },
      youWillGetApproximately: "Dostaneš přibližně",
      errorExchangeTitle: "<strong>Pozor, chyba propojení s\u00a0burzou!</strong>",
      errorBrokerageTitle: "<strong>Pozor, chyba propojení se\u00a0směnárnou!</strong>",
      errorConnectionText: "<strong>Nepodařilo se načíst zůstatky!</strong><br />Pravděpodobně jsi při vytváření propojení někde udělal/a chybu.<br /><br /><strong>Tuto strategii i\u00a0propojení budeš muset smazat a\u00a0zkusit vytvořit propojení podle návodu pečlivěji.</strong><br /><br />Pokud ti obchody s\u00a0tímto propojením dříve fungovaly, může jít jen o\u00a0dočasný výpadek.",
      warning: {
        title: "Dočasný výpadek",
        description: "Vypadá to, že je tvá burza/směnárna <strong>momentálně offline</strong>, zkus se vrátit později."
      },
      confirmBuy: "Potvrdit nákup",
      confirmSell: "Potvrdit prodej",
      amount: "Částka",
      quantity: "Množství",
      limitPrice: "Limitní cena",
      successBuyMessage: "Jednorázový nákup byl zadán.",
      successSellMessage: "Jednorázový prodej byl zadán.",
      errorBuyMessage: "Došlo k chybě během zadávání jednorázového nákupu.",
      errorSellMessage: "Došlo k chybě během zadávání jednorázového prodeje.",
      successLimitBuyMessage: "Jednorázový limitní nákup byl zadán.",
      successLimitSellMessage: "Jednorázový limitní prodej byl zadán.",
      errorLimitBuyMessage: "Došlo k chybě během zadávání limitního jednorázového nákupu.",
      errorLimitSellMessage: "Došlo k chybě během zadávání limitního jednorázového prodeje.",
      infoBox1: "Vyzkoušej jednorázový nákup nebo prodej klidně hned teď. Pravidelné objednávky se zpracovávají v\u00a0den nastavené frekvence.",
      infoBox2: "Vše uvidíš dole v\u00a0Historii\u00a0objednávek.",
      manualModeInfoBox1: "V režimu evidence portfolia můžeš na propojené službě dělat <strong>jednorázové objednávky</strong>, nebo si s předplatným PREMIUM <strong>ručně zapisovat transakce</strong> provedené mimo Štosuj.",
      manualModeInfoBox2: "Kdykoliv si můžeš strategii přepnout do režimu pravidelných <strong>DCA nákupů nebo prodejů</strong>.",
      processingPaymentBox: {
        title: "Objednávka vytvořena",
        subtitle: "Čeká se na vyřízení objednávky na Anycoinu, může to trvat i několik desítek sekund. Po dokončení se toto okno zavře. Pokud nechceš čekat, můžeš zavřít ihned a my stav zjistíme později."
      }
    },
    runsList: {
      title: "Historie objednávek",
      dynamicStrategySubtitle: "Dynamická strategie <strong>{{type}}</strong> s násobitelem <strong>x{{multiplier}}</strong>",
      startedAt: "Vytvoření objednávek",
      state: "Stav",
      type: "Typ",
      status: "Stav",
      typeAndStatus: "Typ a\u00a0stav",
      value: "Hodnota",
      valueAmountAndPrice: "Hodnota, množství a\u00a0kurz",
      amountAndPrice: "Množství a\u00a0kurz",
      error: "Chyba",
      loadAll: "Zobrazit všechny",
      noData: "Prozatím žádné objednávky",
      orders: "Objednávky",
      purchased: "Nakoupeno",
      sold: "Prodáno",
      for: "za",
      triggering: {
        INSTANT: "Jednorázové",
        PERIODIC: "Pravidelné",
        MANUAL: "Ruční",
        asc: "Od\u00a0nejstarší",
        desc: "Od\u00a0nejnovější",
      },
      scheduledRunInfo: {
        title: "Čeká se ve frontě, prosím\u00a0vydrž",
        description: "Všechny strategie se naplánují v\u00a08:00 ráno a\u00a0spouštějí se postupně v\u00a0průběhu celého dopoledne. Zpracování tisíců objednávek nám může trvat i\u00a0několik hodin, díky za trpělivost.",
      },
      priceInfoBuy: "Nákupní kurz {{baseCurrency}} při vytváření objednávek použitý k\u00a0výpočtu poklesu: {{price}}\u00a0{{counterCurrency}}",
      priceInfoSell: "Prodejní kurz {{baseCurrency}} při vytváření objednávek použitý k\u00a0výpočtu nárůstu: {{price}}\u00a0{{counterCurrency}}",
      infoBox: {
        title: "Kdy se objednávky vytvářejí?",
        subtitle1: "Objednávky se zpracovávají v daný den (podle nastavené frekvence spouštění strategie) postupně <strong>mezi\u00a08.\u00a0a\u00a012. hodinou dopoledne</strong>.",
        subtitle2: "Kdykoliv můžeš ručně provést okamžitý nákup nebo prodej tlačítkem <strong>“Jednorázový nákup/prodej {{baseCurrency}}...”</strong>, bude započítán do všech statistik a historie objednávek.",
        subtitle3: "Pod stejným tlačítkem také najdeš možnost vytvořit <strong>samostatný limitní příkaz</strong>, tedy nákupní/prodejní objednávku čekající na pokles/nárůst ceny, nezávisle na tvé pravidelné frekvenci.",
      },
      dcaBotOrderStatus: {
        PENDING_NEW: "Vytváření",
        OPEN: "Otevřená",
        FILLED: "Naplněná",
        PARTIALLY_FILLED: "Částečně naplněná",
        PARTIALLY_CANCELED: "Částečně naplněná, zrušená",
        PENDING_CANCEL: "Probíhá zrušení",
        CANCELED: "Zrušená",
        ERROR_CALCULATING: "Chyba při výpočtu",
        ERROR_SMALL_BALANCE: "Nedostatek prostředků",
        ERROR_PLACING: "Chyba při zadávání",
        ERROR_CANCELING: "Chyba při rušení",
      },
      dcaBotRunState: {
        SCHEDULED: "Naplánováno",
        STARTED: "Spuštěno",
        OPEN: "Aktivní",
        CLOSING: "Ukončování",
        DONE: "Hotovo",
        ERROR: "Chyba",
        MANUAL_DONE: "Zapsáno"
      },
      initialAmounts: "Nastavený počáteční stav je <strong>{{initialBaseCurrencyAmount}}</strong> za\u00a0pořizovací částku <strong>{{initialCounterCurrencyAmount}}</strong>.",
      confirmCancel: {
        title: "Zrušit limitní příkaz?",
        description: "Opravdu chceš zrušit limitní příkaz?",
      },
      successLimitCancelMessage: "Limitní příkaz byl zrušen.",
      errorLimitCancelMessage: "Došlo k chybě během rušení limitního příkazu.",
      openLimitTitle: "Otevřené jednorázové limitní\u00a0objednávky",
      BUY: "Nákup",
      SELL: "Prodej",
      note: "Poznámka",
      manualOrderEdit: "Upravit ruční transakci",
      manualOrderDelete: {
        button: "Smazat",
        title: "Smazat ruční transakci?",
        description: "Opravdu chceš trvale odstranit tuto ruční\u00a0transakci?",
        success: "Ruční transakce byla smazána.",
        error: "Došlo k chybě během mazání ruční transakce.",
      },
    },
    trezorAffiliate: {
      title: "Nový Trezor Safe 3 🔒",
      subtitle: "<strong>Nejnovější hardwarová peněženka Trezor -\u00a0nejlepší uložení bitcoinu.</strong><br>Trezor Safe 3 přináší jednoduchost, svěží vzhled, USB-C a větší zabezpečení díky secure elementu a <strong>shamir backup</strong>.<br><br>V košíku použij Voucher kód <strong>STOSUJCZ</strong> pro <strong>5%\u00a0slevu!</strong><br>Naši členi <a>Premium\u00a0VIP a Premium\u00a0Hero</a> mají <strong>slevu\u00a0až\u00a015\u00a0%</strong>.",
    },
    initialValues: {
      title: "Počáteční stav",
      cta: "Nastavit počáteční stav",
      ctaSave: "Uložit počáteční\u00a0stav",
      ctaEdit: "Upravit počáteční stav",
      validationError: "Počáteční hodnoty musí být obě kladná čísla, nebo obě\u00a00",
      initialBaseCurrencyLabel: "Už mám nakoupeno",
      initialCounterCurrencyLabel: "Nakoupeno za částku",
      success: "Počáteční stav úspěšně nastaven",
      errorForbidden: "Nelze uložit počáteční stav",
      error: "Při ukládání počátečního stavu došlo k chybě",
      bidPrice: "Aktuální prodejní kurz {{baseCurrency}}: <strong>{{price}}\u00a0{{counterCurrency}}</strong>",
      yourBidPrice: "Tvá průměrná nákupní cena za tento počáteční stav: <strong>{{avgPrice}}\u00a0{{counterCurrency}}</strong>",
      currentDifference: "Aktuální rozdíl: <strong>{{sign}}{{pct}}\u00a0%</strong>",
    },
    manualRun: {
      cta: "Znovuvytvoření limitních objednávek...",
      title: "Znovuvytvoření limitních objednávek",
      description: "Touto akcí se provede zrušení aktivních limitních objednávek, pokud nějaké existují, a od aktuální ceny se vypočítají a vytvoří nové, podle současného nastavení tvé LIMIT strategie.<br/><br/>Akci lze také využít pro nový pokus vytvořit objednávky, pokud došlo k nějaké chybě. Nebo pokud si upravíš nastavení strategie a změníš hodnoty objednávek.",
      confirm: "Potvrdit",
    },
  },
  affiliate: {
    yourLink: "Tvůj odkaz pro\u00a0doporučení",
    text1: "Doporuč své známé na\u00a0Štosuj.cz",
    text2: "<strong>Získáš 50 Štosáků</strong> za každého nového uživatele, který provede alespoň 1\u00a0objednávku.<br/>Další Štosáky můžeš získat po doporučení 3 a 10 uživatelů viz. Odznaky\u00a0výše.<br/>Uživatel registrovaný tvým odkazem také získá hned po registraci bonus 50\u00a0Štosáků.",
    copiedToClipboard: "Tvůj odkaz pro doporučení byl zkopírován do schránky.",
    affUsers: "<strong>Doporučeno uživatelů:</strong> {{count}}",
    affBonusPaidUsers: "<strong>Provedlo objednávku:</strong> {{count}}",
    affBonusCredits: "<strong>Již získáno:</strong> {{count}}\u00a0Štosáků",
    procBitcoinText: "Doporučit svým známým můžeš i náš edukační web <a>ProcBitcoin.cz</a>, kde se mohou o Bitcoinu nejprve vzdělat. Stejné výhody za doporučení oba obdržíte i po tom, co se přes tvůj odkaz dostanou na Štosuj.cz."
  },
  exchangeConnections: {
    listHeader: "Propojené účty",
    recommended: {
      title: "Doporučené české služby",
      subtitle: "Použitím <strong>Coinmate.io</strong> nebo <strong>Anycoin.cz</strong> můžeš <strong>obchodovat v českých korunách i\u00a0eurech</strong> a\u00a0zodpovědět otázky ti pomůže rychlá česká podpora.<br>Se Štosuj.cz si můžeš propojit i\u00a0více účtů zároveň. Propojení stačí založit jednou a\u00a0pak lze použít pro různé strategie.",
      others: "Další podporované služby",
    },
    functionalError: {
      exchange: "<strong>Nefunkční propojení s\u00a0burzou!</strong>",
      brokerage: "<strong>Nefunkční propojení se\u00a0směnárnou!</strong>",
      description: "Toto propojení jsme vyhodnotili jako <strong>nefunkční</strong>, například kvůli smazanému API klíči na burze/směnárně. Zkus si vytvořit nové propojení znovu podle návodu.",
      brokenOptionSuffix: "nefunkční",
    },
    deposit: {
      wantToDeposit: "Chci nabít",
      availableBalance: "Aktuálně máš na {{exchangeEnum}} k\u00a0dispozici <strong>{{amount}}\u00a0{{currency}}.</strong>",
      COINMATE: "Coinmate",
      ANYCOIN: "Anycoin",
      title: "Jak nabít peníze na\u00a0{{exchangeEnum}}?",
      bankAccountInfo: "Peníze posílej jen z bankovního účtu, <strong>který je vedený na stejné jméno, jako je tvůj {{exchangeEnum}} účet</strong>.",
      depositLinkInfo: "Platební údaje najdeš na<br/><a>{{link}}</a>",
      transferInfo: "Převod je možné udělat buď jednorázově, nebo si nastavit trvalý\u00a0příkaz (doporučujeme).",
      exchangeBankAccountInfo: "Pro vklad na tento účet je <strong>limit 25\u00a0000\u00a0Kč na jednu transackci a připsání na Coinmate účet trvá až 2 pracovní dny.</strong> Pro jednorázové větší a rychlejší vklady jdi přímo na: <br/><a>{{link}}</a>",
      bankAccount: "Číslo účtu",
      variableSymbol: "Variabilní symbol",
      specificSymbol: "Specifický symbol",
      messageLabel: "Zpráva pro příjemce",
      messageText: "Jméno Příjmení, vklad na\u00a0Coinmate",
      messageAlert: "Příklad Zprávy pro příjmce (bez\u00a0uvozovek):<br><i>\"Jan Novák, vklad na\u00a0Coinmate\"</i><br><br>Použij své <strong>jméno a příjmení, které máš na Coinmate a\u00a0svém bankovním účtu.</strong> Upozornění: V případě chybějícího, chybného nebo neúplného vyplnění Zprávy pro příjemce nebude možné tvůj příkaz k platbě provést (vrátí se ti zpět).",
    },
    modal: {
      label: "Vlastní popisek tohoto propojení",
      createNew: "Vytvořit nové propojení s\u00a0burzou/směnárnou",
      registerAnycoinTitle: "Propojení s Anycoin.cz",
      registerAnycoin: "Vytvořit registraci a\u00a0propojit",
      registerAnycoinAgreeWith: "Souhlasím s",
      registerAnycoinTerms: "Podmínkami směnárny Anycoin.cz",
      registerAnycoinSuccess: "Registrace a propojení s Anycoin.cz proběhlo v pořádku",
      registerAnycoinError: "Je nutné odsouhlasit podmínky zaškrtnutím",
      upsellAnycoinTitle: "Propojení s naší aplikací Štosuj.cz",
      upsellAnycoinAuthorization1: "<strong>s oprávněním</strong> na čtení zůstatků a provádění obchodů",
      upsellAnycoinAuthorization2: "<strong>bez oprávnění</strong> na výběr prostředků - vybrat si své peníze a kryptoměny můžeš pouze ty přímo na\u00a0Anycoinu, nebo nám později povolíš automatický výběr jen na tvou BTC adresu",
      upsellAnycoinExistingAccount: "Máš již svůj účet na Anycoin.cz?",
      upsellAnycoinExistingAccountAction: "Propojit existující\u00a0účet.",
      upsellAnycoinInfo: "Po odeslání registrace obdržíš od Anycoinu e-mailem odkaz pro dokončení registrace, kde si nastavíš vlastní\u00a0heslo.",
      infoAnycoin:
        "Po přihlášení ke svému existujícímu Anycoin účtu přejdi do nastavení <a>Můj\u00a0účet → Aplikace</a>, aktivuj si propojení se Stosuj.cz a do následujících dvou políček překopíruj zobrazený <strong>API\u00a0klíč</strong> a <strong>Heslo k API\u00a0klíči</strong>.",
      infoAnycoinNoAccount: "Ještě nemáš Anycoin účet?",
      infoAnycoinBonus: "Zaregistruj se jednoduše\u00a0teď.",
      infoCoinmateNoAccount: "Ještě nemáš Coinmate účet?",
      infoCoinmate: "Zaregistruj se teď.",
      infoInstructions: "Zobrazit <strong>návod na propojení</strong> se svým {{exchange}} účtem.",
      successAnycoinTitle: "Účet na Anycoin.cz byl\u00a0vytvořen",
      successAnycoinSubtitle: "Pro dokončení registrace prosím <strong>klikni na odkaz ve svém e-mailu</strong> a vytvoř si heslo přímo na webu\u00a0Anycoinu.",
      successAnycoinLinkFundTitle: "Vklad peněz na směnárnu\u00a0Anycoin",
      successAnycoinLinkFundSubtitle: "Po vytvoření hesla a přihlášení si na Anycoin pošli peníze pro nákupy podle instrukcí klasickým <a>bankovním převodem v menu\u00a0<strong>Vklad</strong></a>.",
      successAnycoinLinkAccountTitle: "Ověření totožnosti (KYC)",
      successAnycoinLinkAccountSubtitle: "Na Anycoinu není nutné provádět ověření totožnosti občanským průkazem hned od začátku. K ověření tě Anycoin může vyzvat kdykoli, nejpozději po vložení 25\u00a0000\u00a0Kč (celkově) a provedeš ho jednoduše v\u00a0<a>menu\u00a0<strong>Účet</strong></a>.",
      anycoinAccountExistsError: "Registrace na Anycoin.cz již existuje",
      anycoinAccountExistsTitle: "Účet na Anycoin.cz již existuje",
      anycoinAccountExistsSubtitle:
        "Registraci na Anycoin.cz se nám nepodařilo dokončit, protože účet s tvým e-mailem již existuje. Můžeš si rovnou propojit svůj účet.",
      connectExistingAccount: "Propojení existujícího účtu",
      exchange: "Burza/směnárna",
      delete: {
        title: "Odstranit: {{label}}",
        description: "Opravdu chceš odstranit toto propojení?",
      },
      testConnection: {
        info: "Ověřujeme, že propojeni bude fungovat správně.",
        success: "Úspěšně propojeno!",
        successButton: "Pokračovat k nastavení svých\u00a0strategií",
        isFree: "Štosuj.cz můžeš využít <strong>úplně zdarma na pravidelné nákupy/prodeje bitcoinu 1x\u00a0měsíčně nebo 1x\u00a0týdně a také pro 1\u00a0grid strategii</strong>.<br/><br/>Pro všechny pokročilé funkce prozkoumej naše <strong>měsíční\u00a0předplatné</strong>.",
        errorTitle: "Propojení se nepodařilo vytvořit",
        errorSubtitle: "Pravděpodobně jsi při vytváření propojení udělal/a nějakou chybu. Zkus to znovu a\u00a0postupuj podle návodu pečlivěji, prosím.",
        errorBalance: "Nepodařilo se načíst zůstatky z\u00a0tvého účtu.",
        errorTrade: "Propojení nemá nastaveno oprávnění pro obchodování.",
        errorWithdrawal: "Je nastaveno oprávnění pro výběr prostředků. Toto oprávnění z\u00a0bezpečnostních důvodů nesmíme obdržet.",
      },
      coinbaseProAlert: {
        title: "Burza Coinbase Pro skončila",
        description: "Tato burza ukončila provoz. V budoucnu chceme integrovat propojení s Coinbase Advance Trade, ale obecně doporučujeme použít české služby Coinmate nebo Anycoin.",
      },
      bitstampAlert: {
        title: "Bitstamp brzy přestaneme podporovat",
        description: "Chystáme se na přepis našeho serveru a kvůli malému zájmu o Bitstamp ho velmi pravděpodobně přestaneme podporovat. Doporučujeme používat české služby Coinmate nebo Anycoin.",
      }
    },
    upsell: {
      noticeHeader: "Jako první krok je potřeba vytvořit propojení s\u00a0burzou/směnárnou.",
      noticeBody:
        "Štosuj.cz funguje jako nástroj pro automatizaci, prostředky máš ale plně pod kontrolou na svém vlastním účtu dané burzy/směnárny. Vyber si z listu podporovaných služeb níže.<br>Doporučujeme použít <strong>české služby Coinmate nebo Anycoin</strong> pro obchodování přímo v\u00a0českých\u00a0korunách.",
      promoHeader: {
        anycoin: "Česká kryptoměnová směnárna.",
        coinmate: "Česká kryptoměnová burza.",
      },
      promoAction: {
        anycoin: "Propojit svůj Anycoin účet",
        coinmate: "Propojit svůj Coinmate účet",
      },
      promoActionSignup: {
        anycoin: "Vytvořit nový Anycoin účet",
        coinmate: "Vytvořit nový Coinmate účet",
      },
      otherHeader: "Další možnosti",
      otherAction: "Vytvořit propojení",
    },
    successMessage: "Vytvoření propojení s burzou/směnárnou proběhlo v pořádku",
    errorMessage428: "Už nemůžeš vytvořit více propojení",
    errorMessage: "Propojení s burzou/směnárnou se nepodařilo vytvořit",
    editSuccessMessage: "Propojení s burzou/směnárnou bylo aktualizováno",
    editErrorMessage: "Propojení s burzou/směnárnou se nepodařilo aktualizovat",
    deleteSuccessMessage: "Propojení s burzou/směnárnou bylo odstraněno",
    deleteErrorMessage: "Propojení s burzou/směnárnou se nepodařilo odstranit",
    delete428Message:
      "Toto propojení s burzou/směnárnou nemohlo být odstraněno, protože je použito pro nesmazanou strategii. Nejprve odstraň související strategii.",
  },
  badgeType: {
    REGISTRATION_COMPLETED: "Registrace dokončena",
    FIRST_EXCHANGE_CONNECTION_CREATED: "Vytvořeno první propojení s\u00a0burzou/směnárnou",
    FIRST_DCA_BOT_CREATED: "Vytvořena první DCA\u00a0strategie",
    TWO_FACTOR_AUTH_COMPLETED: "Dvoufaktorové ověření nastaveno",
    FIRST_DCA_BOT_INSTANT_BUY_ORDER_FILLED: "Proveden jednorázový nákup",
    FIRST_DCA_BOT_INSTANT_SELL_ORDER_FILLED: "Proveden jednorázový prodej",
    FIRST_DCA_BOT_LIMIT_BUY_ORDER_FILLED: "První limitní DCA objednávka\u00a0naplněna",
    SET_DCA_BOT_INITIAL_AMOUNTS: "Nastaven počáteční stav DCA\u00a0strategie",
    SET_DCA_BOT_TARGET_VALUE_PRICE: "Nastavena cílová cena DCA\u00a0strategie",
    FIRST_GRID_BOT_ORDER_FILLED: "První objednávka grid\u00a0strategie naplněna",
    WITHDRAWAL_CONNECTION_CREATED: "Nastaven automatický výběr bitcoinu na\u00a0peněženku",
    CREDITS_PACKAGE_PURCHASED: "Nakoupen balíček Štosáků",
    REFERRED_3_USERS: "Doporučil/a jsi 3\u00a0nové\u00a0uživatele",
    REFERRED_10_USERS: "Doporučil/a jsi 10\u00a0nových\u00a0uživatelů",
  },
  badgeTypeAction: {
    REGISTRATION_COMPLETED: "Dokonči registraci",
    FIRST_EXCHANGE_CONNECTION_CREATED: "Vytvoř první propojení\u00a0s\u00a0burzou/směnárnou",
    FIRST_DCA_BOT_CREATED: "Vytvoř první DCA\u00a0strategii",
    FIRST_GRID_BOT_ORDER_FILLED: "Nastav si grid\u00a0strategii a\u00a0počkej na\u00a0naplnění první objednávky",
    TWO_FACTOR_AUTH_COMPLETED: "Nastav si dvoufaktorové\u00a0ověření",
    FIRST_DCA_BOT_INSTANT_BUY_ORDER_FILLED: "Proveď jednorázový nákup",
    FIRST_DCA_BOT_INSTANT_SELL_ORDER_FILLED: "Proveď jednorázový prodej",
    FIRST_DCA_BOT_LIMIT_BUY_ORDER_FILLED: "První limitní DCA objednávka\u00a0naplněna",
    SET_DCA_BOT_INITIAL_AMOUNTS: "Nastav si počáteční stav DCA\u00a0strategie",
    SET_DCA_BOT_TARGET_VALUE_PRICE: "Nastav si cílovou cenu DCA\u00a0strategie",
    WITHDRAWAL_CONNECTION_CREATED: "Nastav si automatický výběr bitcoinu na\u00a0peněženku",
    CREDITS_PACKAGE_PURCHASED: "Nakup balíček Štosáků",
    REFERRED_3_USERS: "Doporuč 3\u00a0nové\u00a0uživatele",
    REFERRED_10_USERS: "Doporuč 10\u00a0nových\u00a0uživatelů",
  },
  badgeState: {
    UNCLAIMED: "Nevyzvednutý",
    CLAIMED: "Získaný",
  },
  badge: {
    collected: "Odznak",
    collected_other: "Odznaků",
    get: "Získej",
    showAll: "Zobrazit všech {{count}} odznaků"
  },
  creditsTransactionType: {
    BADGE_CLAIMED: "Získaný odznak",
    CREDITS_BOUGHT: "Zakoupený balíček Štosáků",
    CREDITS_RECHARGED: "Zakoupené Štosáky měsíční platbou",
    SUBSCRIPTION_PURCHASED: "Zakoupené předplatné",
    SUBSCRIPTION_REFUNDED: "Vrácené předplatné",
    CREDITS_VOUCHER: "Uplatněný kupón na Štosáky",
    AFF_BONUS: "Bonus za registraci přes doporučení",
    REFERRAL_BONUS: "Bonus za doporučení nového uživatele",
    OTHER: "Jiné",
  },
  unclaimed: "Nevyzvednuté",
  claimed: "Získáno",
  date: "Datum",
  amount: "Počet",
  type: "Typ",
  newBalance: "Nový zůstatek",
  name: "Název",
  credits: "Štosáky",
  claim: "Vyzvednout",
  fillUp: "Dokoupit",
  compare: "Porovnat",
  toClaim: "k vyzvednutí",
  subscription: "Předplatné",
  subscriptionSubtitle1: "Službu Štosuj.cz můžeš využívat naprosto <strong>zdarma</strong> pro pravidelné nákupy/prodeje <strong>BTC 1x\u00a0měsíčně nebo 1x\u00a0týdně</strong>.",
  subscriptionSubtitle2: "Pro odemčení kratších frekvencí, více měnových párů a dalších funkcí si aktivuj naše měsíční předplatné níže.",
  subscriptionSubtitle3: "V nové grid strategii si také díky předplatnému můžeš nastavit kratší vzdálenost objednávek a provádět tak více častějších obchodů.",
  subscriptionSubtitle4: "Předplatné <strong>PLUS stojí 50 Štosáků měsíčně</strong>, vyšší předplatné <strong>PREMIUM stojí 150 Štosáků měsíčně</strong>.",
  subscriptionOwnedCredits: "Momentálně máš <strong>{{amount}} Štosáků</strong>.",
  subscriptionCreditsLink: "Zjisti, jak získat\u00a0Štosáky.",
  subscriptionMoreCreditsLink: "Zjisti, jak získat další\u00a0Štosáky.",
  showAllCreditsTransactions: "Zobrazit všechny transakce",
  activate: "Aktivovat",
  activated: "Aktivováno",
  notActivated: "Neaktivováno",
  activeAlready: "Máš aktivní",
  creditsPackageSubscriptionLabel: {
    title: "Předplatné",
    CREDITS_300_V2: "PLUS na 6\u00a0měsíců nebo PREMIUM na 2\u00a0měsíce",
    CREDITS_600_V2: "PLUS na 1\u00a0rok nebo PREMIUM na 4\u00a0měsíce",
    CREDITS_1800_V2: "PREMIUM na 1\u00a0rok",
    CREDITS_3600_V2: "PREMIUM na 2\u00a0roky <strong>+30m\u00a0call s\u00a0Lubošem</strong>",
    CREDITS_HALF_3600: "PREMIUM na 2\u00a0roky <strong>+30m\u00a0call s\u00a0Lubošem</strong>",
    CREDITS_THIRD_3600: "PREMIUM na 2\u00a0roky <strong>+30m\u00a0call s\u00a0Lubošem</strong>",
    CREDITS_HALF_10000: "<strong>Bitcoin Pizza day</strong> dočasná akce! <strong>+\u00a0přístup na VIP\u00a0Discord</strong>\u00a0🚨",
  },
  creditsBuyTitle1: "Jak nakupovat Štosáky pro platbu za předplatné",
  creditsBuySubtitle1:
    "Štosáky slouží jako interní kredity, pomocí kterých si na Štosuj.cz můžeš platit měsíční předplatné a odemknout tak více funkcí. Štosáky můžeš získat zdarma vyzvednutím Odznaků výše, v soutěžích, nebo na konferencích a meetupech.<br><br>Dále máš možnost si Štosáky kupovat dvěma způsoby - automatickou měsíční platbou (doporučujeme), nebo jednorázovým nákupem balíčku.",
  creditsBuyTitle2: "Automatická měsíční platba",
  creditsBuySubtitle2: "Nejjednodušší řešení - jednou nastavíš, každý měsíc se zaplatí pomocí <strong>platební karty, Apple Pay nebo Google Pay</strong> a přičte se ti <strong>150\u00a0Štosáků</strong> na platbu za předplatné PREMIUM, díky kterému využiješ naši službu naplno.<br/><br/>Zajímá tě přístup do <strong>VIP kanálů na našem komunitním Discordu a další výhody?</strong> Omrkni vyšší varianty <a>Premium\u00a0VIP a Premium\u00a0Hero</a>.",
  creditsBuyTitle3: "Jednorázový nákup balíčku Štosáků",
  creditsBuySubtitle3:
    "Pro nákup většího množství Štosáků najednou můžeš využít balíčky níže.<br/><br/>Po kliknutí na tlačítko <strong>Koupit</strong> a výběru platební metody (<strong>kartou/převodem</strong> nebo <strong>bitcoinem/lightningem</strong>) tě přesměrujeme na platební bránu.<br/>Po zaplacení a potvrzení platby ti přijde účtenka e-mailem a Štosáky se ti automaticky přičtou na účet.<br/><br/>V nejvyšším balíčku získáš možnost <strong>30minutového videohovoru</strong> se zakladatelem projektu Štosuj.cz, <strong>Lubošem Kovaříkem</strong>, pro konzultaci své strategie.",
  creditsBuyError: "Vyskytla se chyba s platební bránou. Zkus to znovu později.",
  creditsBuy: {
    confirm: {
      title: "Chybějící fakturační údaje",
      description: "Pro nákup Štosáků je potřeba mít vyplněné údaje pro vystavení faktury. Formulář k vyplnění najdeš v menu Nastavení v sekci <strong>Fakturační údaje</strong>.",
      button: "Přejít do Nastavení",
    },
    selectPaymentMethod: {
      title: "Jak chceš zaplatit?",
      withCard: "kartou / převodem",
      withBtc: "bitcoinem / lightningem"
    }
  },
  buy: "Koupit",
  retrieved: "Vyzvednout",
  subscriptionsAndCredits: "Předplatné a Štosáky",
  settings: "Nastavení účtu",
  passwordResetPage: {
    title: "Obnovení zapomenutého hesla",
    content: "Zadej e-mailovou adresu, pro kterou chceš obnovit zapomenuté heslo.",
    reset: "Zaslat e-mail s odkazem na obnovení\u00a0hesla",
    resetRequested: "E-mail s odkazem pro obnovení hesla byl odeslán",
    infoSent: "Na tvoji e-mailovou adresu byl odeslán e-mail s odkazem na obnovení hesla.",
    tokenStillValid: "Odeslaný e-mail s odkazem na obnovení hesla je stále platný.",
    error404: "Uživatel s tímto e-mailem neexistuje",
  },
  setNewPasswordPage: {
    title: "Nastavení nového hesla",
    content: "Nyní si můžeš nastavit nové bezpečné heslo.",
    submit: "Nastavit nové heslo",
    requestAgain: "Znovu požádat o obnovení hesla",
    tokenExpired: "Platnost odkazu pro nastavení hesla vypršela.",
    tokenInvalid: "Odkaz pro nastavení nového hesla není platný.",
    passwordChanged: "Nové heslo bylo úspěšně nastaveno.",
    continueToLogin: "Přejít na přihlášení",
  },
  settingsPage: {
    meta_title: "Nastavení",
    user: "Uživatel",
    title: "Nastavení",
    language: "Jazyk aplikace",
    changeLanguage: "Změnit jazyk",
    password: "Přístupové heslo",
    passwordPlaceholder: "********",
    passwordCurrent: "Současné heslo",
    changePassword: "Změnit heslo",
    changePasswordModalTitle: "Změnit přístupové heslo",
    changePasswordContent:
      "Zde zadej své stávající heslo, nové heslo a poté ještě jednou nové heslo pro kontrolu jeho správnosti.",
    changePasswordSuccess: "Heslo bylo úspěšně změněno",
    mfa: "Dvoufaktorové ověření",
    mfaSetup: "Zabezpečit účet",
    mfaDisable: "Zrušit zabezpečení",
    mfaDescription: "Pozor, při aktivaci si musíš zazálohovat textový kód pro obnovu 2FA do nového zařízení. Pokud o zálohu přijdeš, nedokážeme tvůj účet nijak odblokovat!",
    emailPreferences: {
      title: "E-mailové preference",
      description: "Správa zasílaných e-mailů",
    },
    comingSoon: "V přípravě",
    deleteAccount: "Smazat účet",
    deleteAccountDesc: "Pokud chceš odstranit svůj Štosuj.cz účet...",
    deleteAccountConfirm: "Opravdu chceš svůj Štosuj.cz účet smazat? Pokud máš nějaké otevřené limitní objednávky, tak budou zrušeny. Po potvrzení tě odhlásíme a účet bude odstraněn. V budoucnu se budeš moci se stejným e-mailem registrovat znovu.",
    invoice: "Fakturační údaje",
    invoiceDesc: "Tvé údaje pro platby za předplatné",
    invoiceSetup: "Nastavit",
    invoiceSetupModal: {
      title: "Fakturační údaje",
      content: "Jako poskytovatele elektronické služby nám zákon ukládá povinnost pro přijmutí platby od našich zákazníků vystavit doklad s identifikací z důvodu správného započtení DPH do země, kde se uživatel nachází.",
      firstName: "Jméno",
      lastName: "Příjmení",
      countryCode: "Země",
      streetAndNumber: "Ulice a číslo",
      city: "Město",
      postalCode: "PSČ",
      addBusinessData: "Přidat údaje podnikající osoby / firmy",
      businessName: "Název podnikající osoby (nepovinné)",
      businessIdentificationNumber: "IČO",
      success: "Fakturační údaje byly úspěšně uloženy",
    },
  },
  logout: "Odhlásit",
  themeMode: "Barevný režim",
  maskedMode: {
    title: "Diskrétní režim",
    description: "Zamaskuje citlivé údaje",
  },
  showInUSD: {
    title: "Přepočet cen na USD",
    description: "Pro kurzy v CZK nebo EUR",
  },
  device: "Systém",
  light: "Světlý",
  dark: "Tmavý",
  badges: "Odznaky",
  accountState: "Stav účtu",
  badgesTable: {
    title: "Odznaky - tvé odměny",
    subtitle:
      "Za vyzkoušení následujících funkcí Štosuj.cz dostaneš naše kredity, tzv. <strong>Štosáky</strong>.<br/>Po splnění si Štosáky kliknutím vyzvedni - připíšou se na tvůj účet a můžeš je použít na platbu za <a>předplatné</a>.",
  },
  creditsVoucher: {
    title: "Uplatnění kupónu na Štosáky",
    subtitle: "Tady zadej šestimístný kód pro připsání Štosáků, který jsi od nás obdržel/a.",
    label: "Kupón",
    button: "Uplatnit",
    fieldMinLength: "Kód kupónu musí mít 6 znaků",
    success: "Připsali jsme ti {{amount}} Štosáků",
    error404: "Tento kód kupónu neexistuje",
    error428: "Tento kód kupónu už není platný",
  },
  transactions: {
    title: "Transakce",
  },
  twoFactorAuth: {
    title: "Dvoufaktorové ověření",
    subtitle: "Dvoufaktorové ověřování je bezpečnostní prvek, který pomáhá chránit tvůj účet před záškodníky. Po aktivaci bude během tvého přihlašování vyžadován ještě šestimístný kód z Authenticator aplikace.",
    meta_title: "Nastavení dvoufaktorového ověření",
    downloadApp: {
      title: "Stáhnout aplikaci",
      subtitle: "Nainstaluj si Authenticator aplikaci",
      subtitle2: "Můžeš použít libovolnou Authenticator aplikaci, jako například Google Authenticator, Microsoft Authenticator, Authy a další. My doporučujeme Google Authenticator.",
      googleAuth: "Google Authenticator",
      appStore: "Apple App Store",
      googlePlay: "Google Play",
      button: "Stáhnout",
    },
    scanQRcode: {
      title: "Naskenovat QR kód",
      subtitle: "Naskenuj tento QR kód ve své Authenticator aplikaci a zazálohuj si textový kód!",
      tooltip: "Pokud QR kód nemůžeš naskenovat, můžeš následující textový klíč nastavení zadat do Authenticator aplikace\u00a0ručně:",
      recovery1: "Pozor! 👆 Tento textový kód si nyní ZKOPÍRUJ A BEZPEČNĚ ULOŽ pro případ ztráty přístupu ke své Authenticator aplikaci (např. nový telefon), abys mohl/a obnovit přístup k dvoufaktorovému ověření.",
      recovery2: "Pokud o kód přijdeš, nebudeme ti moci tvůj účet odblokovat!",
      recoveryCheckbox: "Potvrzuji, že mám textový kód zazálohovaný a v případě jeho ztráty nenávratně přijdu o přístup ke svému Štosuj.cz\u00a0účtu.",
      recoveryCheckboxError: "Musíš si zazálohovat tento textový kód a potvrdit jeho bezpečné uložení!",
    },
    backupCode: {
      title: "Ověření zálohy",
      subtitle: "Máš zapsaný svůj textový kód jako zálohu?",
      description: "Ještě jednou, pro jistotu - sem opiš zálohu svého textového kódu, kterou sis poznamenal/a v předchozím kroku:",
      label: "Tvůj textový kód pro zálohu",
      helperText: "Chybný kód, překontroluj svoji zálohu!",
    },
    enableGoogleAuth: {
      title: "Povolit Authenticator aplikaci",
      subtitle: "Nyní pro potvrzení použij kód z Authenticator aplikace:",
      label: "Ověřovací kód",
      helperText: "Zadej šestimístný kód z Authenticator aplikace",
    },
    complete: {
      title: "Dokončeno",
      subtitle: "Dvoufaktorové ověření úspěšně aktivováno",
    },
    disable: {
      title: "Deaktivovat Authenticator aplikaci",
      label: "Ověřovací kód",
      helperText: "Zadej šestimístný kód z Authenticator aplikace",
      button: "Deaktivovat",
    },
    completeDisable: {
      title: "Dokončeno",
      subtitle: "Dvoufaktorové ověření bylo deaktivováno",
    },
    next: "Další krok",
    previousStep: "Zpátky",
    done: "Hotovo",
    loginAgain: "Znovu přihlásit",
  },
  dashboard: {
    meta_title: "Přehled",
    subscriptionError: "Kvůli nedostatku Štosáků na účtu při pokusu o obnovu aktivního předplatného byly tvé strategie pozastaveny. Po dokoupení Štosáků si své strategie znovu aktivuj ručně.",
    subscriptionWarning: "Pozor, nebudeš mít dostatek Štosáků na další obnovu aktuálního předplatného. Pokud na účtu nebudeš mít na konci měsíčního období dostatek Štosáků, budou tvé strategie pozastaveny.",
    subscriptionFree: "Můžeš se také rozhodnout vrátit se aktivací předplatného FREE k bezplatnému využívání naší služby.",
    subscriptionCtaFree: "Zpět na předplatné FREE",
    subscriptionCtaBuyCredits: "Jak dokoupit Štosáky",
    community: {
      title: "Štosuj.cz návod a\u00a0komunita",
      watchTutorial: "Otevřít návod v novém okně",
      discord: "Přidej se k naší <a>Discord\u00a0komunitě</a>, kde se můžeš ptát na cokoliv kolem naší služby Štosuj.cz, hlásit nalezené problémy, nebo se zapojit do nápadů na nové funkce a\u00a0vylepšení!",
    }
  },
  creditsPage: {
    meta_title: "Štosáky a odznaky - přehled",
    meta_title_transactions: "Všechny transakce",
  },
  subscriptionPage: {
    meta_title: "Měsíční předplatné",
    modalDowngradingFail: {
      title: "Nelze ponížit předplatné",
      subtitle: "Momentálně využíváš některou z funkcí pro vyšší předplatné. Pokud chceš předplatné ponížit, uprav prosím následující:",
      havingTooManyGridBots: "Máš více grid strategií, než předplatné {{subType}} dovoluje.",
      havingUnsupportedGridBotCurrencyPair: "Některou grid strategii máš nastavenou na měnový pár, kterou předplatné {{subType}} nedovoluje.",
      havingSmallerGridBotPercentStep: "Některou grid strategii máš nastavenou s menší vzdáleností objednávek, kterou předplatné {{subType}} nedovoluje.",
      havingTooManyDcaBots: "Máš více DCA strategií, než předplatné {{subType}} dovoluje.",
      havingDcaBotDynamicStrategy: "Některou DCA strategii máš nastavenou s dynamickou DCA částkou, kterou předplatné {{subType}} nedovoluje.",
      havingTooManyDcaBotLimitOrders: "V některé DCA strategii máš nastaveno více limitních objednávek, než předplatné {{subType}} dovoluje.",
      havingTooManyDcaBotInstantLimitOrders: "V některé DCA strategii máš nastaveno více jednorázových limitních objednávek, než předplatné {{subType}} dovoluje.",
      havingTooFrequentDcaBotFrequency: "Některou DCA strategii máš nastavenou s kratší frekvencí, než předplatné {{subType}} dovoluje.",
      havingUnsupportedDcaBotCurrencyPair: "Některou DCA strategii máš nastavenou na měnový pár, který předplatné {{subType}} nedovoluje.",
      havingDcaBotInitialAmounts: "Pro některou DCA strategii máš nastaven počáteční stav, který předplatné {{subType}} nedovoluje.",
      havingDcaBotTargetValuePrice: "Pro některou DCA strategii máš nastavenou cílovou hodnotu, kterou předplatné {{subType}} nedovoluje.",
      havingDcaBotLimitOrderNotExecutingAsMarket: "Pro některou DCA strategii s LIMIT strategií máš vypnutý zobchodování za aktuální cenu, pokud cena do konce zvolené frekvence neklesne/nestoupne, tuto možnost předplatné {{subType}} nedovoluje.",
      havingDcaBotSellMode: "Pro některou DCA strategii s režimem pravidelného prodeje používáš burzu, kterou předplatné {{subType}} nedovoluje. Je možné použít pouze Coinmate nebo Anycoin.",
      havingWithdrawalConnection: "Máš nastavený automatický výběr bitcoinu na peněženku, tuto funkci předplatné {{subType}} nedovoluje.",
      understand: "Rozumím",
    },
    modalNotEnoughCredits: {
      title: "Nedostatek Štosáků",
      subtitle: "Nemáš dostatek Štosáků pro aktivaci vybraného předplatného. Přejdi na stránku s možnostmi nákupu Štosáků, nebo pokračuj s bezplatným předplatným FREE.",
      close: "Zavřít",
      buy: "Jak nakoupit Štosáky",
    }
  },
  termsPage: {
    meta_title: "Podmínky služby",
  },
  language: {
    cs: "Čeština",
  },
  homepage: {
    heroTitle: "Investuj konečně",
    heroType: {
      1: "v klidu",
      2: "bez starostí",
      3: "s důvěrou",
      4: "automaticky",
      5: "bez emocí",
      6: "s pevnou strategií",
    },
    heroSubtitle: "Přenech starost o dodržení svého investičního plánu na nás. Nepodléhej zbytečným chybám kvůli impulzivním\u00a0krokům.<br/><br/>Vyzkoušej <strong>Štosuj.cz</strong>, nejlepší řešení pro <strong>automatizaci vlastní investiční\u00a0strategie.</strong>",
    register: {
      button: "Vyzkouším",
      email: "Tvůj e‑mail",
      info: "Registrací souhlasíš s\u00a0<a>podmínkami</a>\u00a0Štosuj.cz",
      success: {
        title: "Registrace na Štosuj.cz proběhla v\u00a0pořádku.",
        subtitle: "Pro dokončení aktivace svého účtu prosím potvrď svůj e‑mail<br/>odkazem v zaslané zprávě.",
      }
    },
    weHelp: {
      title: "Pomáháme lidem investovat\u00a0bez\u00a0starostí",
      registered: "registrovaných<br/>uživatelů",
      bots: "aktivních<br/>strategií",
      orders: "uskutečněných<br/>objednávek",
      volume: "celkového<br/>objemu",
    },
    strategies: {
      title: "Naše strategie",
      subtitle: "Máme pro tebe <strong>řešení na míru</strong>. Ať už s\u00a0Bitcoinem teprve začínáš, chceš své investice optimalizovat, obchodovat volatilitu, nebo už realizuješ zisky -\u00a0u\u00a0nás si\u00a0vybereš.<br/><br/>Díky definování a <strong>automatizaci vlastní strategie</strong> dosáhneš svého cíle s\u00a0větší\u00a0jistotou.",
      dca: {
        title: "DCA strategie",
        subtitle1: "pravidelné <strong>investování</strong>",
        subtitle2: "díky naší <strong>optimalizaci</strong> pomocí indikátorů dosáhneš většího\u00a0zisku",
      },
      grid: {
        title: "Grid strategie",
        subtitle1: "<strong>obchodování</strong> volatility",
        subtitle2: "vydělávej automaticky na\u00a0častých <strong>výkyvech</strong> cenových\u00a0kurzů",
      },
      exit: {
        title: "Exit strategie",
        subtitle1: "postupná <strong>realizace zisků</strong>",
        subtitle2: "naplánuj si chytře <strong>odprodej</strong> části svého portfolia při\u00a0růstu\u00a0cen",
      },
      findOutMore: "Zjistit více",
    },
    howItWorks: {
      title: "Štosuj.cz funguje jako nadstavba nad propojenou burzou/směnárnou",
      subtitle: "Své peníze a kryptoměny tak stále držíš u vybrané služby, my za tebe budeme pouze provádět příkazy k nákupu/prodeji <strong>podle zvolené\u00a0strategie<strong>.",
      moreAbout: "Více o strategiích",
    },
    howToStart: {
      title: "Udělej první krok ještě\u00a0dnes",
      subtitle: "Záleží nám na tom, aby naši aplikaci dokázal použít naprosto\u00a0každý.<br/>Na všechny pokročilé funkce máme detailní návody. Zkus to sám, nebo si nech poradit.",
      consultation: {
        name: "Ocením konzultaci",
        title: "Přeješ si osobní přístup?",
        subtitle: "Upřednostňuješ nejprve svou strategii <strong>zkonzultovat s\u00a0odborníkem?</strong> Zvláště pokud jde o <strong>vyšší částky</strong>, dává smysl poradit\u00a0se.",
        individuallyTitle: "Individuálně prodiskutujme:",
        individually1: "tvou situaci a\u00a0finanční\u00a0možnosti",
        individually2: "tvá očekávání a\u00a0investiční\u00a0horizont",
        individually3: "připravenost na různý\u00a0vývoj\u00a0ceny",
        individually4: "výběr, nastavení a automatizaci vlastní strategie pro nákup i\u00a0prodej",
        individually5: "bezpečnou úschovu bitcoinu",
        individuallyButton: "Požádat o konzultaci",
      },
      educate: {
        name: "Nejprve se vzdělám",
        title: "Proč Bitcoin?",
        subtitle: "Než se vrhneš do investování, doporučujeme ti začít edukací, protože k Bitcoinu si napřed potřebuješ vybudovat důvěru.",
      },
      myself: {
        name: "Zvládnu to sám",
        title: "Štosuj.cz průvodce",
        subtitle: "Rozumějte všem funkcím a možnostem naší platformy s naším Štosuj.cz průvodcem. Získáte přehled o strategiích, nástrojích a postupech.",
      },
      nextVideos: "Další videa",
    },
    contact: {
      title: "Kontakt",
      ourTeam: "Štosuj.cz tým a\u00a0komunita",
      discord: "Chceš se zeptat, poradit, nahlásit problém nebo se dozvědět o\u00a0novinkách jako první? Připoj se na náš <strong>komunitní\u00a0Discord</strong>.",
      join: "Připojit se na Discord",
      founderInfo: "Bitcoinu se věnuji přes 11 let, od roku 2013. Jako vývojář jsem řešil automatizaci své strategie a rozhodl jsem se s tímto pomoci i\u00a0dalším lidem. Můžete mne také kontaktovat pro <strong>konzultaci své\u00a0strategie</strong>.",
      founderConsultation: "Pro osobní přístup a\u00a0konzultace mě kontaktujte\u00a0e‑mailem",
      reportProblem: "Hlášení problémů, dotazy a obecné diskuse prosím řešit primárně přes náš <a>Discord</a>,\u00a0díky."
    },
    selectStrategy: {
      title: "Výběr a přizpůsobení strategie",
      subtitle: "Každou strategii si můžeš maximálně upravit přesně podle\u00a0sebe.",
      dca: {
        name: "Základní nákupní strategie pro\u00a0každého",
        title: "DCA strategie",
        subtitle: "<strong>Dollar-Cost Averaging</strong> je nejjednodušší a zároveň velmi efektivní metoda postupného nakupování, kdy v pravidelném intervalu investuješ za stále stejnou částku. Vyhneš se tak “časování trhu” a Bitcoin bereš jako <strong>dlouhodobé\u00a0spoření</strong>.",
        info: {
          title: "Kdy a proč využít DCA\u00a0strategii?",
          description: "Pokud s Bitcoinem začínáš, nebo chceš dlouhodobě pasivně investovat nezávisle na aktuální ceně, je DCA nejlepší způsob, jak si <strong>například každý týden kousek bitcoinu přikoupit</strong>.",
          button: "Vše o DCA strategii",
        }
      },
      dynamicDca: {
        name: "Pokročilá nákupní nebo prodejní strategie",
        title: "Dynamická DCA\u00a0strategie",
        subtitle: "Úspěšně se nám podařilo DCA vylepšit! Díky optimalizaci velikosti investované částky <strong>podle dlouhodobých indikátorů</strong> dokáže naše unikátní strategie nakoupit <strong>více bitcoinu za nižší průměrnou nákupní\u00a0cenu</strong>.<br/><br/>Podle aktuální situace na trhu investuješ více či méně a <strong>alokuješ tak lépe svůj kapitál v průběhu bitcoinových cyklů</strong>. To vše podloženo backtestem.",
        info: {
          title: "Kdy a proč využít dynamickou DCA\u00a0strategii?",
          description: "Pokud jsi o Bitcoinu přesvědčen, nebojíš se <strong>investovat větší částku při cenovém poklesu</strong> a\u00a0chceš strategii optimalizovat k\u00a0dosažení <strong>vyššího zhodnocení</strong>, je\u00a0to pro tebe správná\u00a0volba!",
          button: "Vše o dynamickém DCA",
        },
        backTest: {
          title: "O kolik je tato strategie výhodnější?",
          description: "Díky pronásobení investované částky v\u00a0klesajícím trhu se tvá <strong>průměrná nákupní cena zaručeně sníží o\u00a0jednotky až desítky procent</strong>, ale hlavně zainvestuješ více peněz v\u00a0ten správný okamžik, takže tvé <strong>výsledné zhodnocení bude o\u00a0desítky procent vyšší</strong>. Ověř si to na detailním backtestu...",
          button: "Backtest strategie",
        },
      },
      grid: {
        name: "Obchodování cenové volatility",
        title: "Grid strategie",
        subtitle: "Bitcoin je volatilní. Lítá nahoru a\u00a0dolu. Tyto <strong>cenové výkyvy</strong> naše grid strategie dokáže <strong>automaticky zobchodovat</strong>. Při růstu ceny prodává, při poklesu <strong>nakupuje zpět více bitcoinu</strong>.<br/><br/>Jedná se o <strong>nejjednodušší obchodní strategii</strong> postavenou na čisté matematice. Žádné indikátory, jen procentuální\u00a0pohyby.",
        info: {
          title: "Kdy a proč využít grid\u00a0strategii?",
          description: "Grid strategii aktivuj pouze v\u00a0případě, kdy už jsi se svým bitcoinem celkově v\u00a0plusu, chceš <strong>automaticky vydělávat na cenových pohybech</strong> a\u00a0částečně realizovat\u00a0zisk.",
          button: "Vše o grid strategii",
        },
        backTest: {
          title: "Kolik mohu na gridu vydělat?",
          description: "Záleží na těchto faktorech - nastavení procentuální vzdálenosti (kroku) objednávek, hodnotě objednávek, cenových výkyvech a\u00a0poplatcích za obchodování. <strong>Tip: Na české burze Coinmate klesají poplatky podle zobchodovaného objemu za posledních 30\u00a0dní</strong>. Všechny parametry a\u00a0historické zhodnocení si můžeš vyzkoušet na backtestu...",
          button: "Backtest strategie",
        },
      },
      exit: {
        name: "Postupná realizace zisků",
        title: "Exit strategie",
        subtitle: "Máš už zhodnoceno a přemýšlíš <strong>jak částečně realizovat zisk?</strong> S\u00a0rostoucí cenou bitcoinu a\u00a0dalších kryptoměn přichází otázka <strong>kdy a\u00a0jak vybírat zisk?</strong><br/><br/>Nabízíme <strong>pravidelný odprodej formou DCA</strong> (i\u00a0dynamickou částkou podle indikátorů) a\u00a0chystáme <strong>výpočet a\u00a0automatizaci exit strategie podle rostoucí ceny</strong>. Vždy tedy budeš prodávat na aktuálně nejlepší\u00a0ceně.",
        info: {
          title: "Kdy a proč využít exit\u00a0strategii?",
          description: "Záleží na tvé individuální situaci. Pokud ti v\u00a0rostoucím trhu pomůže <strong>vybrat si část zisků a\u00a0na něco je použít, nebo diverzifikovat</strong> jinam, pomůžeme ti udělat to chytře.",
          button: "Vše o exit strategii",
        },
        backTest: {
          button: "Exit kalkulačka",
        },
      },
    },
    otherFuction: {
      title: "Další užitečné funkce",
      recalculation: {
        title: "Přepočet kurzů na\u00a0USD",
        description: "Ať už obchoduješ v\u00a0českých korunách nebo eurech, pod ikonkou profilu najdeš možnost nechat si <strong>zobrazovat kurzy kryptoměn v\u00a0USD</strong>, takže se v\u00a0cenách budeš lépe\u00a0orientovat."
      },
      initialState: {
        title: "Počáteční stav a\u00a0ruční zápis transakcí",
        description: "Chceš si <strong>zaevidovat stav svého portfolia</strong> ještě před začátkem používání Štosuj.cz, nebo <strong>ručně zapsat transakci</strong> provedenou jinde? Není problém!",
      },
      withdrawal: {
        title: "Automatický výběr BTC na\u00a0vlastní peněženku",
        description: "Nenechávej svůj bitcoin ležet na burze! Nastav si <strong>automatické přeposlání BTC</strong> na svou peněženku jakmile naspoříš nastavené množství. Určíš si také <strong>maximální akceptovatelný poplatek</strong>.",
      },
      pwa: {
        title: "Mobilní aplikace (PWA)",
        description: "Na telefonu v prohlížeči vyber možnost <strong>Přidat na plochu</strong> - spuštěním Štosuj.cz přes tuto ikonu se web otevře jako aplikace, bez adresního řádku\u00a0prohlížeče!",
      },
      savingsGoals: {
        title: "Oddělené spoření na\u00a0cíle",
        description: "Můžeš si vytvořit <strong>samostatné DCA strategie</strong>, každou si pojmenovat a dát jí cílovou částku. <strong>Spoř odděleně</strong> například na svůj důchod, pro své děti nebo na nové\u00a0auto!",
      },
      summary: {
        title: "Souhrn všech strategií",
        description: "Na tvém přehledu ti ukážeme <strong>statistiky skrz všechny vytvořené strategie dohromady</strong>. I\u00a0když jich budeš mít několik, na první pohled uvidíš jak si celkově vedeš.",
      },
    },
    afiliateProgram: {
      name: "Partnerský program a spolupráce",
      title: "Máš svůj web, dosah na sociálních sítích, nebo se věnuješ financím?",
      subtitle: "Staň se partnerem Štosuj.cz a\u00a0získej možnost <strong>affiliate provizí z\u00a0provedených objednávek a\u00a0zaplacených předplatných</strong> od tebou doporučených uživatelů.",
      button: "Chci se stát partnerem Štosuj.cz",
    },
    references: {
      name: "Reference",
      title: "Co o nás říkají?",
      anonymous: "Anonymní uživatel",
      showMore: "Více uživatelských referencí",
      showLess: "Méně uživatelských referencí",
    },
    showDashboard: "Můj přehled",
    testimonials: "Reference",
    testimonialsKicom: "Jediná možnost, jak dlouhodobě spořit do Bitcoinu a nezbláznit se z toho, je nakupovat postupně metodou DCA. Proto podporuji Štosuj.cz!",
    testimonialsMatejSvancer: "Nesnažte se časovat trh, ale raději si jednoduše nastavte pravidelné nákupy na Štosuj.cz a místo stresu ze sledování grafů se věnujte něčemu, co vás baví.",
    testimonialsMartinKuchar: "Dlouhodobě doporučuji DCA jako nejlepší a nejefektivnější způsob nákupu kryptoměn. A i proto jsem rád, že můžu rovnou odkázat na Štosuj.cz jako český a spolehlivý projekt.",
    testimonialsVojtaZizka: "Trh zásadně nečasuji. Pro většinu investorů do Bitcoinu je nejlepší metoda DCA, kterou Štosuj.cz dotáhlo k dokonalosti. V kombinaci s mojí oblíbenou směnárnou Anycoin se jedná o ideální řešení jak postupně spořit do Bitcoinu.",
    authorFounder: "Zakladatel\u00a0projektu, konzultace\u00a0strategie",
    authorCofounder: "Spoluzakladatel",
    authorBackend: "Backend vývoj",
    authorFrontend: "Frontend vývoj",
    authorUiUx: "UI / UX",
    trezorAffiliateTitle: "Hardwarové peněženky Trezor",
    trezorAffiliateText: "Nejbezpečnější uložení tvých kryptoměn. Doporučujeme vybírat si své nakoupené kryptoměny do peněženek Trezor.",
    trezorAffiliateBuy: "Koupit Trezor",
    exchanges: "Podporované burzy/směnárny",
    exchangesCzech: "Pokud <strong>nakupuješ za české\u00a0koruny</strong>,<br/>nejlepším řešením je pro tebe <strong>česká\u00a0burza\u00a0Coinmate</strong> nebo <strong>česká\u00a0směnárna\u00a0Anycoin</strong>.<br/>Když potřebuješ poradit, pomůže ti přímo česká\u00a0podpora.",
    exchangesOther: "Podporujeme také mezinárodní\u00a0burzy:",
    subscription: "Přehled předplatných",
    free: "ZDARMA",
    premium: "PREMIUM",
    subscriptions: "Začni zcela zdarma,<br/>nebo vyzkoušej naše\u00a0předplatné",
    subscriptionsFree: "Bezplatný účet navždy",
    subscriptionsFreeClaim: "Základní FREE předplatné je zdarma a na několik měsíců můžeš vyzkoušet bezplatně i\u00a0pokročilé\u00a0funkce.",
    subscriptionsFree1: "Propojení na všechny burzy/směnárny",
    subscriptionsFree2: "Nákup bitcoinu <strong>1x\u00a0měsíčně nebo každý\u00a0týden</strong>",
    subscriptionsFree3: "2 samostatné DCA\u00a0strategie",
    subscriptionsFree4: "1 aktivní grid\u00a0strategie",
    subscriptionsFree5: "Jednorázový nákup/prodej kdykoliv",
    subscriptionsFreeCta: "Zaregistruj se během\u00a0minuty",
    subscriptionsPaid: "Placené předplatné",
    subscriptionsPaidClaim: "Všechny prémiové funkce odemkneš aktivací předplatného a\u00a0využiješ Štosuj.cz\u00a0naplno!",
    subscriptionsPaid1: "Další kryptoměny a <strong>kratší frekvence\u00a0obchodů</strong>",
    subscriptionsPaid2: "Větší počet aktivních\u00a0strategií",
    subscriptionsPaid3: "<strong>Dynamické DCA</strong> podle\u00a0indikátorů - dlouhodobý <strong>NUPL\u00a0indikátor</strong> a krátkodobý <strong>Fear\u00a0&\u00a0Greed\u00a0Index</strong>",
    subscriptionsPaid4: "Kratší vzdálenost objednávek v\u00a0grid\u00a0strategii",
    subscriptionsPaid5: "<strong>Automatický výběr BTC</strong> na\u00a0vlastní\u00a0peněženku",
    subscriptionsPaid6: "A ještě další funkce... omrkni\u00a0to",
    subscriptionsPaidCta: "Porovnání předplatných",
  },
  exchangeComparison: {
    name: "Název",
    limit: "Limitní objednávka",
    market: "Market objednávka",
    withdrawal: "Poplatek za výběr\u00a0BTC",
    link: "Detailní popis",
    coinmateNote1: "Na české burze Coinmate používáme k naplnění pravidelné market objednávky (za aktuální cenu) interně funkci Rychlý nákup/Rychlý prodej, která má mírně vyšší poplatek. Pomáhá nám tak z vyšší domluvené marže částečně financovat tento projekt a připravovat pro vás nové funkce. Děkujeme za pochopení!",
    coinmateNote2: "K provedení jednorázového nákupu/prodeje (ručně kdykoliv z našeho uživatelského prostředí) používáme i na Coinmate klasický market order v order booku s nízkým poplatkem (až\u00a00,6\u00a0%), takže je pro tebe provedení jednorázového nákupu stejně výhodné jako přímo na Coinmate.",
    cta: "Porovnání poplatků",
  },
  dcaCalculator: {
    savingToBitcoin: "Spořením do <strong>Bitcoinu</strong>",
    amount: "částkou",
    yourSavedAmount: "má nyní tvých",
    currentValue: "aktuální hodnotu",
    main: "Spořením do <strong>Bitcoinu</strong> v posledních {{ years }} letech každý {{ frequency }} částkou {{ amount }} má nyní tvých <strong>{{ invested }} Kč</strong> v <crypto>{{ cryptoAmount }} BTC</crypto> aktuální hodnotu <totalValue/>",
    inLastYear_one: "za poslední",
    inLastYear_few: "za poslední",
    inLastYear_other: "za posledních",
    every_one: "každý",
    every_few: "každé",
    every_other: "každých",
    year_one: "rok",
    year_few: "roky",
    year_other: "roků",
    month_one: "měsíc",
    month_few: "měsíce",
    month_other: "měsíců",
    week_one: "týden",
    week_few: "týdny",
    week_other: "týdnů",
    day_one: "den",
    day_few: "dny",
    day_other: "dnů",
    stackSats: "HODL! Štosuj saty dál ve\u00a0slevě!",
    youGained: "Zhodnocení",
  },
  pricing: "Ceník",
  pricingPage: {
    title: "Ceník",
    meta_title: "Ceník",
    supportBtc: "BTC adresa",
    supportLightning: "Lightning Network",
    howToAcquireCredits: "Jak získat Štosáky?",
    howToAcquireCreditsText1: "Službu Štosuj.cz můžeš v její základní podobě využívat <strong>zcela zdarma</strong>, bez časového omezení.",
    howToAcquireCreditsText2: "V případě, že chceš využít pokročilejší nastavení příkazů, dynamickou DCA strategii podle indikátorů, nakupovat prostřednictvím dalších měnových párů, využít automatický výběr na vlastní peněženku, ruční zápis transakcí a další funkce, tak můžeš přejít na placené předplatné a zároveň nám tak poděkovat za budování tohoto projektu!",
    howToAcquireCreditsText3: "K nákupu měsíčních předplatných používáme interní kredity - Štosáky. Tyto kredity můžeš získat různými způsoby uvedenými níže. Předplatné <strong>PLUS stojí 50 Štosáků</strong> měsíčně, předplatné <strong>PREMIUM stojí 150 Štosáků</strong> měsíčně.",
    subscriptions: "Porovnání předplatných",
    howTo1: "Pokud nás chceš podpořit přímo bitcoinem, můžeš nám poslat BTC na adresu/QR kód níže - buď klasicky on‑chain, nebo přes Lightning Network. Po naskenování QR kódu můžeš nastavit libovolnou částku.",
    contributions: "Přímá podpora/donate",
    btc: "BTC adresa",
    lightning: "Lightning Network",
    registerText: "<strong>Vyzkoušej službu Štosuj.cz zdarma</strong>. Režim FREE můžeš využívat bezplatně neomezeně dlouho, nebo můžeš použít Štosáky za získání odznaků (za registraci, vytvoření první strategie atd.) a vyzkoušet si placené funkce po několik měsíců bezplatně. A až pak se rozhodneš, jestli pokračovat ve FREE, nebo si Štosáky dokoupit.",
    registerCta: "Zaregistruj se teď",
    supporters: "Podporovatelé našeho projektu",
    bronze: "Bronzoví podporovatelé",
    silver: "Stříbrní podporovatelé",
    gold: "Zlatí podporovatelé",
    patron: "Sponzoři projektu",
    affiliate: "Doporučení projektu",
    affiliateText: "Pokud budeš s naším projektem spokojen/a, budeme velmi rádi, když o nás dáš vědět i svým přátelům. Pro všechny registrované uživatele jsme připravili <a>odměnu za doporučení služby</a> v podobě <strong>50 Štosáků za každého nového uživatele</strong>, který naplní alespoň 1\u00a0objednávku. Doporučený zároveň díky registraci přes tvůj odkaz také získá 50\u00a0Štosáků do\u00a0začátku.",
    affiliateThankYou: "Děkujeme!",
    badges: "Získávání Odznaků",
    badgesFeatures: "Prozkoumej, jak Štosuj.cz funguje, a získej Štosáky úplně zdarma za splnění úkonů v\u00a0aplikaci. Štosáků tak budeš mít dost, aby sis na několik měsíců vyzkoušel/a i placené funkce zdarma.",
    rechargeCredits: "Měsíční platba kartou",
    rechargeCreditsFeatures: "Nejjednodušší způsob - automatická platba kartou nebo Apple Pay a Google Pay za předplatné PREMIUM pro odemknutí všech funkcí, jen za zhruba <strong>150\u00a0Kč měsíčně</strong>.",
    rechargeCreditsButton: "Nastavení platby",
    buyCredits: "Nákup balíčku Štosáků",
    buyCreditsFeatures: "Nakup balíček Štosáků jednoduše platební kartou, bankovním převodem nebo platbou bitcoinem (i\u00a0přes Lightning Network). <strong>Sleva až 20\u00a0%</strong> při nákupu balíčku s větším množstvím Štosáků.",
    recommend: "Odměna za doporučení",
    recommendFeatures: "Doporuč Štosuj.cz kamarádům se svým odkazem pro doporučení a za každého registrovaného uživatele, který naplní alespoň 1 objednávku, od nás dostaneš 50\u00a0Štosáků.",
    showBundles: "Ukázat balíčky",
  },
  errorCode: {
    ERROR_SMALL_BALANCE: "Hodnota objednávky {{ORDER_VALUE}}\u00a0{{CURRENCY}} byla větší než dostupný volný zůstatek {{BALANCE}}\u00a0{{CURRENCY}}.",
    ERROR_CALCULATING_BELOW_COUNTER_MIN_AMOUNT: "Hodnota objednávky {{ORDER_VALUE}}\u00a0{{COUNTER_CURRENCY}} byla menší než minimální hodnota {{COUNTER_MIN_AMOUNT}}\u00a0{{COUNTER_CURRENCY}}.",
    ERROR_CALCULATING_ABOVE_COUNTER_MAX_AMOUNT: "Hodnota objednávky {{ORDER_VALUE}}\u00a0{{COUNTER_CURRENCY}} byla větší než maximální hodnota {{COUNTER_MAX_AMOUNT}}\u00a0{{COUNTER_CURRENCY}}.",
    ERROR_CALCULATING_BELOW_MIN_AMOUNT: "Množství objednávky {{ORDER_AMOUNT}}\u00a0{{BASE_CURRENCY}} bylo menší než minimální množství {{MIN_AMOUNT}}\u00a0{{BASE_CURRENCY}}.",
    ERROR_CALCULATING_ABOVE_MAX_AMOUNT: "Množství objednávky {{ORDER_AMOUNT}}\u00a0{{BASE_CURRENCY}} bylo větší než maximální množství {{MAX_AMOUNT}}\u00a0{{BASE_CURRENCY}}.",
    ERROR_ADMIN_MANUAL_CANCEL: "Zrušené z důvodu nefunkčního api klíče či podobné chybě.",
  },
  chartHistory: {
    title: "Vývoj investice",
    error: "Při načítání grafu vývoje investice došlo k problému.",
    notEnoughData: "Jakmile se provedou objednávky alespoň ve 2\u00a0různých dnech, tak ti tady ukážeme graf s\u00a0vývojem tvé pořizovací částky a\u00a0hodnoty.",
    targetValue: {
      title: "Očekávaná cílová hodnota",
      ctaEdit: "Cílová hodnota",
      ctaSave: "Uložit cílovou cenu",
      bidPrice: "Aktuální prodejní cena {{baseCurrency}}: <strong>{{price}}\u00a0{{counterCurrency}}</strong>",
      targetCounterValueLabel: "Zajímá mě cílová cena",
      quickActionsTitle: "Použít aktuální prodejní cenu s\u00a0násobkem:",
      success: "Cílová cena úspěšně nastavena",
      errorForbidden: "Nelze uložit cílovou cenu",
      error: "Při ukládání cílové ceny došlo k chybě",
      info: "Tady si můžeš nastavit <strong>cílovou\u00a0cenu</strong>, proti které ti na grafu <strong>Vývoj investice</strong> ukážeme <strong>křivku cílové hodnoty</strong> (akumulované množství vynásobené cílovou cenou).",
    }
  },
  priceChart: {
    title: "Vývoj průměrné nákupní\u00a0ceny",
    error: "Při načítání grafu průměrné nákupní ceny došlo k problému.",
    notEnoughData: "Uvidíš zde také historický vývoj ceny a jak se měnila tvá průměrná nákupní cena.",
  },
  subscriptionUpsellModal: {
    title: "Odemkni funkci aktivací našeho předplatného",
    description: "Aktivuj si vyšší předplatné pro přístup k rozšířeným funkcím a\u00a0zároveň tak podpoříš vývoj našeho projektu, díky!",
    cta: "Přehled předplatných",
  },
  tutorial: {
    open: "Potřebuješ poradit? Zobrazit návod",
    close: "Skrýt návod",
  },
  currencyPairsPage: {
    title: "Podporované měnové páry",
    meta_title: "Podporované měnové páry",
  },
  activation: {
    meta_title: "Aktivace účtu",
    title: "Aktivace účtu",
    password: "Nové heslo",
    language: "Jazyk / Language",
    button: "Aktivovat účet",
    createAnycoin: {
      title:  "Registrovat nový Anycoin\u00a0účet",
      description: "Vytvoří se účet na české směnárně anycoin.cz a propojení se stosuj.cz. Potvrzením souhlasíš s <a>podmínkami\u00a0Anycoin</a>."
    },
    createStosuj: "Pouze aktivovat účet na stosuj.cz, účet na burze už\u00a0mám.",
    successMessage: "Aktivace účtu proběhla úspěšně",
    errorMessage: "Při aktivaci účtu nastala chyba",
  },
  whyBitcoin: {
    title1: "Proč",
    title2: "Bitcoin?",
    description: "Položili jste si někdy otázku – <strong>Proč Bitcoin?</strong> Proč se o něj vůbec zajímat? Proč jeho obliba neustále roste? A co vůbec určuje jeho\u00a0cenu?<br><br>Mnozí považují Bitcoin za jeden z největších vynálezů 21. století. Vůbec poprvé v historii tu totiž máme <strong>fungující a čistě digitální formu moderních peněz</strong>, které nikdo neovládá. Jeho naprostá decentralizace a nezávislost na jakémkoliv státě, bance či instituci nám dává dosud nevídanou svobodu. Jiní v něm naopak vidí <strong>ideální možnost, jak své úspory dlouhodobě uchránit před znehodnocením\u00a0inflací</strong>.<br><br>V našich kurzech naleznete odpovědi na všechny vaše otázky ve formě poutavých\u00a0videí.",
    firstCourseButton: "Proč se zajímat o\u00a0Bitcoin?",
    otherCoursesButton: "Zobrazit kurzy",
    textSummary: "Textový souhrn",
    chapters_one: "kapitola",
    chapters_few: "kapitoly",
    chapters_other: "kapitol",
    coursesList: "Seznam bitcoinových kurzů",
    coursePlay: "Začít",
    back: "Zpět na všechny kurzy",
    prevChapter: "Předchozí\u00a0kapitola",
    nextChapter: "Další\u00a0kapitola",
    nextCourse: "Další\u00a0kurz",
  },
  withdrawalConnections: {
    title: "Automatický výběr na\u00a0vlastní\u00a0peněženku",
    description1: "Nenechávej nakoupené kryptoměny ležet na burze/směnárně dlouho.",
    description2: "Nastav si automatické přeposílání na vlastní peněženku.",
    description3: "Jakmile naspoříš nastavené množství, tak dojde k výběru na tvou adresu.",
    description4: "<strong>Například:</strong> Jakmile budeš mít na účtu 0,01\u00a0BTC a\u00a0více, provede\u00a0se\u00a0výběr.",
    description5: "Prozatím podporujeme <strong>výběr BTC z\u00a0Coinmate a\u00a0Anycoinu</strong>.",
    description6: "Tuto funkci si mohou aktivovat uživatelé našich <a>předplatných PLUS a PREMIUM</a>.",
    add: "Vytvořit automatický výběr",
    smallAdd: "Nový",
    btcThresholdWithdrawal: "Výběr při zůstatku {{value}}\u00a0BTC",
    enabled: "Aktivní",
    disabled: "Neaktivní",
    modal: {
      create: "Vytvořit nový automatický výběr",
      label: "Vlastní popisek tohoto výběru",
      infoInstructions: "Zobrazit <strong>návod na nastavení výběru</strong> ze svého {{exchange}} účtu.",
      infoAnycoin: "Po přihlášení ke svému Anycoin účtu přejdi do menu <a>Můj\u00a0účet → Aplikace</a>, u aplikace Stosuj.cz klikni na <strong>NASTAVENÍ...</strong> a\u00a0následně:",
      infoAnycoin1: "zaškrtni <strong>Povolit Štosuj.cz provádět výběry výhradně na následující adresy</strong>",
      infoAnycoin2: "klikni na <strong>PŘIDAT ADRESU PRO VÝBĚR</strong>",
      infoAnycoin3: "vyplň svou <strong>adresu peněženky</strong> a klikni na <strong>POTVRDIT</strong>",
      infoAnycoin4: "klikni na <strong>ULOŽIT ZMĚNY</strong>",
      exchange: "Burza/směnárna",
      btcAddress: "BTC adresa",
      btcAddressDescription: {
        ANYCOIN: "Zadej svou BTC adresu, na kterou jsi nám povolil/a oprávnění pro výběr z\u00a0Anycoinu. <strong>Postupuj prosím podle návodu\u00a0výše.</strong>",
        COINMATE: "Zadej svou BTC adresu, na kterou jsi již dříve prováděl/a výběr z\u00a0Coinmate a\u00a0máš ji <strong>uloženou jako šablonu</strong>. Postupuj <strong>podle návodu výše</strong> tak, abychom neměli oprávnění vybrat tvůj bitcoin nikam\u00a0jinam!",
      },
      btcThreshold: "Vybrat vše po dosažení zůstatku",
      btcThresholdDescription: "Jakmile volný zůstatek BTC na propojeném účtu bude stejný nebo větší, tak <strong>dojde k výběru celého zůstatku</strong>. Výběry se provádí vždy po poledni. Transakce najdeš pod tlačítkem <strong>Zobrazit historii výběrů</strong>.",
      privateKey: "Private key",
      publicKey: "Public key",
      clientId: "ID klienta",
      maxFee: "Maximální poplatek za výběr",
      currentFee: "Aktuální poplatek za výběr: <strong>{{value}}\u00a0BTC</strong>",
      maxFeeDescription: "Zvol si <strong>maximální akceptovatelnou hodnotu poplatku</strong> za výběr. Každý den v poledne se poplatky zkontrolují a pokud bude aktuální poplatek vyšší, tak se <strong>výběr neprovede</strong> a zkusí se znovu další den.",
      exchangeConnectionId: "Dostupná Anycoin propojení",
      successMessage: "Vytvoření automatického výběru proběhlo v pořádku",
      errorMessage: "Automatický výběr se nepodařilo vytvořit, zkontroluj oprávnění podle návodu",
      minBtcValue: "Minimální hodnota pro výběr je {{value}}\u00a0BTC",
      maxBtcValue: "Sorry jako, ale nevznikne více než {{value}}\u00a0BTC",
      minFeeValue: "Minimum lze nastavit na {{value}}\u00a0BTC",
      maxFeeValue: "Maximálně dovolujeme nastavit {{value}}\u00a0BTC",
      withoutAnycoinConnectionWarning: "Nejprve je nutné vytvořit propojení s Anycoin směnárnou.",
    },
    deleteModal: {
      title: "Odstranit: {{label}}",
      description: "Opravdu chceš odstranit tento automatický výběr?",
    },
    editModal: {
      label: "Upravit automatický výběr",
      successMessage: "Automatický výběr byl aktualizován",
      errorMessage: "Automatický výběr se nepodařilo aktualizovat",
    },
    successMessageEnable: "Automatický výběr byl aktivován",
    successMessageDisable: "Automatický výběr byl pozastaven",
    errorMessageEnable: "Automatický výběr se nepodařilo aktivovat",
    errorMessageDisable: "Automatický výběr se nepodařilo pozastavit",
    deleteSuccessMessage: "Automatický výběr byl smazán",
    deleteErrorMessage: "Automatický výběr se nepodařilo smazat",
    showWithdrawalsList: "Zobrazit historii výběrů",
    withdrawalsListModal: {
      label: "Historie výběrů",
      state: "Stav",
      startedAt: "Vytvoření",
      amount: "Množství",
      fee: "Poplatek",
      address: "Adresa",
      noData: "Žádná data",
      maxFeeWarningTitle: "Pozor, další výběr se provede až klesnou poplatky",
      maxFeeWarningDescription: "Aktuální poplatek za výběr ({{value}}\u00a0BTC) je vyšší než nastavený maximální akceptovatelný poplatek ({{maxFee}}\u00a0BTC).",
      states: {
        STARTED: "Zadáno",
        DONE: "Hotovo",
        ERROR: "Chyba",
      },
    }
  },
  gridBot: {
    meta_title: "Grid strategie",
    bidPrice: "Aktuální prodejní kurz",
    askPrice: "Aktuální nákupní kurz",
    midPrice: "Aktuální středová cena",
    insufficientFunds: "Nedostatek prostředků",
    gridLevels: "Cenové úrovně gridu",
    nextSellLevels: "Další prodejní úrovně ({{count}})",
    nextBuyLevels: "Další nákupní úrovně ({{count}})",
    showAllLevels: "Zobrazit všechny úrovně",
    create: {
      meta_title: {
        create: "Nová grid strategie",
        update: "Upravit grid strategii",
      },
      header: {
        create: "Nová grid strategie",
        update: "Upravit grid strategii",
      },
      exchangeConnectionId: "Dostupná propojení",
      currencyPair: "Měnový pár",
      label: "Popisek - vlastní pojmenování",
      gridUpperPrice: "Horní limit",
      gridLowerPrice: "Spodní limit",
      gridPercentStep: "Vzdálenost objednávek",
      gridOrderValue: "Hodnota každé objednávky",
      minUpperPriceFallback: "Horní limit musí být větší než {{value}}\u00a0{{currency}}",
      maxLowerPriceFallback: "Spodní limit musí být menší než {{value}}\u00a0{{currency}}",
      minGridOrderValueFallback: "Minimální hodnota objednávky je {{value}}\u00a0{{currency}}",
      maxGridOrderValueFallback: "Maximální hodnota objednávky je {{value}}\u00a0{{currency}}",
      create: "Vytvořit strategii",
      update: "Uložit grid strategii",
      successMessage: "Byla vytvořena nová grid strategie",
      errorMessage409: "Pod jedním účtem na burze/směnárně nemůžeš mít více aktivních grid strategii používajících stejnou měnu (i v případě BTC/CZK a ETH/CZK je problém použití CZK ve dvou gridech).",
      errorMessage: "Novou grid strategii se nepodařilo vytvořit",
      editSuccessMessage: "Grid strategie byla aktualizována",
      editErrorMessage: "Grid strategii se nepodařilo aktualizovat",
      coinmateTradingFees: {
        info: "Tvé aktuální <a1>Coinmate poplatky</a1> jsou <strong>{{makerFee}}\u00a0%</strong>. Podle zobchodovaného objemu za posledních 30 dní poplatky klesají. <a2>Více\u00a0informací.</a2>",
        warning: "Pozor, tvé aktuální <a1>Coinmate poplatky</a1> za limitní objednávky (Maker fee) jsou <strong>{{defaultFee}}\u00a0%</strong> a nastavení gridu po {{percentStep}}% kroku by <strong>nebylo profitabilní!</strong> Použij větší vzdálenost objednávek, nebo na Coinmate nejprve zobchoduj více než {{amount}} za posledních 30 dní, pak se ti sníží poplatky jen na {{newFee}}\u00a0%. Při větším objemu poplatky dále klesají. <a2>Více\u00a0informací.</a2>",
      },
      howDoesItWork: {
        title: "Co je to grid strategie a jak funguje",
        header: "Jak to funguje",
        text1: "Grid strategie používá limitní objednávky pro <strong>nákupy i prodeje</strong>. Díky cenovým výkyvům zobchoduje velké množství objednávek.<br>Od aktuální ceny ti vytvoří limitní objednávky <strong>nahoru pro prodej</strong> (červeně) a\u00a0<strong>dolu pro nákup</strong> (zeleně).<br><br>Jakmile se cena pohne nahoru a naplní se nejbližší prodejní objednávka, tak se automaticky pod ní vytvoří nová nákupní objednávka o stejné hodnotě. A naopak, po naplnění každé nákupní objednávky dojde k vytvoření prodejní objednávky nad ní.",
        title2: "Kde se tvoří a vypočítává zisk?",
        text2: "Za výchozí situaci považujeme, že od aktuální ceny při pohybu POUZE nahoru jen odprodáváš.<br>Ale díky volatilitě (výkyvům ceny) dokážeš pomocí grid strategie automaticky zobchodovat tyto pohyby ceny a <strong>z\u00a0každého dvojobchodu (nákup‑prodej nebo prodej‑nákup) máš zisk navíc</strong>.<br><br>A tuto sumu, kterou získáš oproti výchozí situaci, ti ukážeme jako zisk.",
      },
      beta: {
        title: "Beta verze + upozornění",
        title1: "Stále pokračujeme na vylepšeních",
        text1: "Grid strategie u nás funguje už od května 2023. Interní mechanismus stále vylepšujeme a budeme rádi za zpětnou vazbu, pokud narazíš na nějakou chybku. Neváhej se ozvat nebo se na cokoliv zeptat.",
        text1b: "Zpětnou vazbu piš prosím na náš komunitní <a>Discord</a>, díky!",
        title2: "Návod s představením grid strategie",
        text2: "Ještě než budeš pokračovat, tak si prosím přečti <a>tento návod</a> a zkoukní video s představením grid strategie, ať máš jistotu, že vše uděláš správně a nic tě nepřekvapí. Hlavně <strong>je potřeba mít na burze dostatek prostředků</strong> na vytvářené nákupní i prodejní objednávky (vypočteš si dále v tomto průvodci).",
        title3: "Backtest - výpočet zisku na historických datech",
        text3: "Připravili jsme pro tebe interaktivní <a>backtest grid strategie</a>, kde si můžeš vyzkoušet vše vypočítat.",
        agree: "Potvrzuji, že jde stále o beta verzi a návod mám přečtený.",
      },
      basicSetting: {
        title: "Základní nastavení",
        header: "Základní nastavení",
        exchangeConnectionsInfo: "Strategii si můžeš vytvořit se svým propojeným účtem na Coinmate nebo Anycoin.",
        currencyPairInfo: "Vyber, jestli chceš obchodovat BTC nebo ETH vůči korunám nebo euru.",
        labelInfo: "Svou grid strategii si můžeš libovolně pojmenovat (nepovinné).",
      },
      limitSetting: {
        title: "Cenové rozpětí",
        header: "Cenové rozpětí gridu",
        tooltip: "Mezi spodní a horní hranicí se vypočítají cenové úrovně, na kterých se následně vytvoří nákupní a prodejní objednávky. Vzdálenost cenových úrovní a hodnotu objednávek nastavíš na následujícím kroku. Nyní vyber spodní hranici, kam až chceš dokupovat. A horní hranici, kam až chceš odprodávat. Mimo tyto hranice se už objednávky nevytvoří.",
        gridUpperPrice: "Horní hranice",
        gridLowerPrice: "Spodní hranice",
        midPrice: "Aktuální středový kurz",
      },
      gridSetting: {
        backToSettingPriceRange: "Zpátky na nastavení cenového rozpětí",
        title: "Nastavení objednávek",
        orderDistance: {
          title: "Vzdálenost objednávek",
          description: "Procentuální krok vypočítaných cenových úrovní. Menší vzdálenost způsobí provedení více obchodů.",
          tooltip: "Čím menší vzdálenost nastavíš, tím častěji se obchody budou provádět.",
          alert: {
            FREE: "Kratší vzdálenost objednávek pro častější obchody odemkneš aktivací vyššího <a>předplatného</a>.",
            PLUS: "S <a>předplatným PREMIUM</a> si můžeš nastavit vzdálenost objednávek už <strong>od\u00a01\u00a0%</strong>.",
          }
        },
        orderValue: {
          title: "Hodnota každé objednávky",
          description: "Na každé cenové úrovni se nakoupí nebo prodá za tuto hodnotu. Níže uvidíš vypočítané, kolik prostředků celkem budeš potřebovat pro všechny objednávky.",
          tooltip: "Uprav hodnotu objednávek tak, aby se prodalo/nakoupilo v nastaveném cenovém rozpětí kolik si přeješ.",
        },
        buy: "Nákupy:",
        sell: "Prodeje:",
        notEnough: "Nedostatek {{currency}}:",
        buyTotal: "Potřebuješ na všechny\u00a0nákupy",
        sellTotal: "Potřebuješ na všechny\u00a0prodeje",
        available: "Volný zůstatek na\u00a0{{title}}",
        missing: "Chybí doplnit na\u00a0{{title}}",
        count: "Počet",
        value: "Hodnota",
        buyOrders: "Nákupní objednávky",
        sellOrders: "Prodejní objednávky"
      },
      about: {
        title: "Co se stane po vytvoření strategie",
        description: `Výše vidíš souhrn hodnot nákupních a prodejních objednávek v nastaveném cenovém rozpětí svého gridu.<br><br>
        <strong>*Pozor, silně doporučujeme grid strategii aktivovat pouze v případě, že máš aktuálně volné prostředky na celé své rozpětí gridu!</strong><br>
        Nedoporučujeme mít v aktivním gridu větší rozpětí! Rozpětí můžeš kdykoliv upravit, až si doplníš prostředky.<br><br>
        Pokud výše vidíš, že ti chybí doplnit prostředky na burzu/směnárnu, tak si tam vypočítané množství nejprve převeď a pak se vrať sem dokončit nastavení a grid strategii aktivovat. Nebo si teď uprav cenové rozpětí tak, aby ti tam volné prostředky vystačily.<br><br>
        Protože grid se stále <strong>snaží udržovat otevřené objednávky kolem aktuální ceny</strong>, tak při pohybu v aktivním rozpětí, kde už ale nemáš volné prostředky, začne rušit nejvzdálenější objednávky (aby uvolnil prostředky) a vytvářet je blíže středu.<br><br>
        I když máš k dispozici dostatečný volný zůstatek, <strong>vždy budeme udržovat otevřené limitní objednávky jen v rozmezí
        +-20 % od aktuální ceny.</strong><br>
        Při větším pohybu na druhou stranu se ty vzdálenější uzavřou.`,
        button: "Zobrazit přehled objednávek",
        modalTitle: "Přehled objednávek",
      },
      lastStep: "Poslední krok",
    },
    update: {
      tileTextTitle: "Co mohu na grid strategii upravit?",
      tileText1: "Kdykoliv můžeš upravit <strong>popisek a cenové rozpětí</strong>, tj. spodní a horní hranici gridu.",
      tileText2: "Pokud potřebuješ přenastavit <strong>vzdálenost objednávek nebo hodnotu každé objednávky</strong>, tak svou grid strategii nejprve pozastav.",
      priceRangeTooltip: "Mezi spodní a horní hranicí se vypočítají cenové úrovně, na kterých se vytvoří nákupní a prodejní objednávky. Mimo tyto hranice se už objednávky nevytvoří. Pokud cenové rozpětí zúžíš, otevřené objednávky mimo toto rozpětí se po uložení zruší.",
      activeAlert: {
        title: "Úprava vzdálenosti a hodnoty objednávek",
        description: "Pro změnu procentuální vzdálenosti a hodnoty objednávek <strong>nejprve svou grid strategii musíš pozastavit</strong>, aby se existující objednávky zrušily a uvolnily se ti všechny uzamčené prostředky. Pak ti zobrazíme nastavení a detailní přehled objednávek a potřebných prostředků.",
        disable: "Pozastavit grid strategii",
      },
      about: {
        title: "Co se stane po aktivaci strategie",
      },
      updateModal: {
        title: "Upravit grid strategii",
        description: "Chceš grid strategii po uložení rovnou aktivovat s novým nastavením?",
        onlySave: "Uložit bez aktivování",
        saveAndEnable: "Uložit a zároveň aktivovat",
      }
    },
    tile: {
      priceRange: "Cenové rozpětí",
      orderValue: "Hodnota objednávek",
      percentStep: "Vzdálenost objednávek",
    },
    balances: {
      sellTotal: "Celkem {{currency}} na prodej",
      buyTotal: "Celkem {{currency}} na nákup",
      available: "K dispozici {{currency}}",
      missing: "Chybějící zůstatek {{currency}}",
      frozen: "Uzamčený zůstatek",
      frozenDesc: "Zůstatek, který není volně k dispozici - například je uzamčený v otevřených objednávkách.",
      errorConnectionText: "<strong>Nepodařilo se načíst zůstatky!</strong><br />Pravděpodobně jsi při vytváření propojení někde udělal/a chybu.<br /><br /><strong>Tuto grid strategii i\u00a0propojení budeš muset smazat a\u00a0zkusit vytvořit propojení podle návodu pečlivěji.</strong><br /><br />Pokud ti obchody s\u00a0tímto propojením dříve fungovaly, může jít jen o\u00a0dočasný výpadek.",
    },
    detail: {
      profit: "Celkový zisk",
      profitTooltip: "Zisk díky provedeným dvojobchodům. Kolik máš navíc oproti situaci, kdyby se od výchozí ceny pouze jednorázově odprodalo.",
      gridUpperPrice: "Horní limit",
      gridLowerPrice: "Spodní limit",
      gridPercentStep: "Vzdálenost objednávek",
      gridOrderValue: "Hodnota objednávek",
    },
    confirmDelete: {
      title: "Smazat grid strategii?",
      description: "Opravdu chceš odstranit tuto grid strategii?",
    },
    brokeragePriceLines: {
      askPrice: "Kurz pro nákup",
      bidPrice: "Kurz pro prodej",
    },
    chart: {
      title: "Graf",
      errorData: "Data se nepodařilo načíst",
      error: "Při načítání grafu došlo k problému.",
      brokerageInfoBox:
        "Zeleně jsou <t1>nákupní objednávky</t1>, červeně <t2>prodejní\u00a0objednávky</t2>.<br><br>Na směnárně {{brokerage}} musí dojít k\u00a0posunu tučně vyznačeného aktuálního <strong><t1>Kurzu\u00a0pro\u00a0nákup</t1></strong> k\u00a0otevřené <t1>nákupní</t1> objednávce, resp. <strong><t2>Kurzu\u00a0pro\u00a0prodej</t2></strong> k\u00a0otevřené <t2>prodejní</t2> objednávce, aby se naplnila. Svíčkový graf ukazuje vývoj středového\u00a0kurzu.",
    },
    deleteSuccessMessage: "Grid strategie byla smazána",
    deleteErrorMessage: "Grid strategii se nepodařilo smazat",
    confirmEnable: {
      title: "Aktivovat grid strategii?",
      description: "Grid strategie se aktivuje a znovu se vytvoří objednávky podle aktuálního nastavení.",
    },
    confirmDisable: {
      title: "Pozastavit grid strategii?",
      description:
        "Všechny otevřené objednávky se zruší a nebudou se vytvářet nové. Své statistiky stále uvidíš a můžeš grid strategii opět aktivovat později.",
    },
    successMessageEnable: "Grid strategie byla aktivována",
    successMessageDisable: "Grid strategie byla pozastavena",
    errorMessageEnable: "Grid strategii se nepodařilo aktivovat",
    errorMessageDisable: "Grid strategii se nepodařilo pozastavit",
    orderList: {
      title: "Historie objednávek",
      showOpenOrder: "Zobrazit i otevřené objednávky",
      originalPrice: "úroveň {{price}}",
      originalPriceWithCumulative: "{{amount}} na úrovni {{price}}",
      empty: "Prozatím žádné objednávky",
      profit: "zisk +{{value}}\u00a0{{currency}}",
      BUY: "Nákup",
      SELL: "Prodej",
      orderProfit: "Zisk",
      orderStatus: {
        PENDING_NEW: "Vytváření",
        OPEN: "Otevřená",
        FILLED: "Naplněná",
        PARTIALLY_FILLED: "Část. naplněná",
        PARTIALLY_CANCELED: "Část. zrušená",
        PENDING_CANCEL: "Probíhá zrušení",
        CANCELED: "Zrušená",
        ERROR_CALCULATING: "Chyba při výpočtu",
        ERROR_SMALL_BALANCE: "Nedostatek prostředků",
        ERROR_PLACING: "Chyba při zadávání",
        ERROR_CANCELING: "Chyba při rušení",
        NOT_ENOUGH_BALANCE: "Nedostatek {{currency}}",
        ENOUGH_BALANCE: "Dostatek {{currency}}",
        PENDING_FUNDS: "Čeká na uvolnění prostředků",
        ERROR_PENDING_FUNDS_TIMEOUT: "Chyba při uvolňování prostředků",
      },
    }
  },
  bots: {
    listHeader: {
      enabled: "Moje strategie",
      disabled: "Neaktivní strategie",
      empty: "Vytvoření první strategie",
    },
    createNewShort: "Nová",
    createNew: "Nová strategie",
    gridLabel: "Grid strategie",
    tile: {
      showDetail: "Přejít na detail",
      gridDescription: {
        1: "Grid strategie <strong>{{currencyPair}}</strong> přes <strong>{{exchangeTitle}}</strong>",
        2: "Objednávky za",
        3: "{{every}} <strong>{{percentStep}}\u00a0%</strong>",
      },
      dcaDescription: {
        1: {
          DCA_BUY: "Pravidelný nákup <strong>{{currencyPair}}</strong> přes <strong>{{exchangeTitle}}</strong>",
          DCA_SELL: "Pravidelný prodej <strong>{{currencyPair}}</strong> přes <strong>{{exchangeTitle}}</strong>",
          MANUAL: "Portfolio <strong>{{currencyPair}}</strong> přes <strong>{{exchangeTitle}}</strong>",
        },
        2: "<strong>{{stategy}}</strong> za",
        3: "{{every}} <strong>{{day}}</strong>",
      },
      valueDifference: "Rozdíl hodnoty",
      midPrice: "Aktuální kurz",
      label: "Vlastní popisek strategie",
      dynamicStrategy: {
        label:  "Dynamická strategie <strong>{{type}}</strong>",
        currentMultiplier: "Aktuální násobitel",
      }
    },
    bot: {
      add: "Nová grid strategie",
      title: "Grid strategie",
      subtitle1: "Chci vydělávat na cenových pohybech.",
      subtitle2: "Kryptoměnový trh je volatilní.<br>Díky <strong>automatizaci nákupů a prodejů v nastavených procentuálních krocích</strong> dokážeš vydělat na cenových výkyvech.",
      subtitle3: "Před nastavením si pozorně přečti <a1>návod</a1> (a\u00a0jeho <a2>pokročilou část</a2>), vyzkoušet můžeš také <a3>backtest</a3> na historických datech.<br><br>Pokud plánuješ v gridu obchodovat vyšší částky a raději by ses poradil, <strong>napiš si o konzultaci</strong> na info@stosuj.cz, spojíme se.",
    },
    dcaBot: {
      add: "Nová DCA strategie",
      title: "DCA strategie",
      subtitle1: "Chci pravidelně nakupovat, nebo\u00a0pravidelně\u00a0prodávat.",
      subtitle2: "Strategie <strong>DCA (Dollar‑Cost Averaging)</strong> je nejjednodušší a\u00a0zároveň velmi efektivní metoda pravidelného investování nebo realizace\u00a0zisku.<br>Kdykoliv můžeš udělat i\u00a0jednorázový nákup nebo\u00a0prodej.",
      subtitle3: "<strong>Novinka: Dynamická DCA strategie</strong> podle indikátorů -\u00a0chceš <strong>více\u00a0bitcoinu za nižší průměrnou nákupní cenu?</strong><br>Mrkni na <a1>návod</a1> a ověř si na <a2>backtestu</a2>, že to opravdu funguje.<br><br>Využij dlouhodobý <strong>NUPL\u00a0indikátor</strong>, nebo krátkodobý <strong>Fear\u00a0&\u00a0Greed Index</strong> k\u00a0pronásobení hodnoty objednávek v\u00a0lepším okamžiku.",
    },
    create: {
      alertTitle: "Ještě než vytvoříš strategii",
      alertText: "Službu Štosuj.cz můžeš používat <strong>zcela zdarma</strong> s\u00a0frekvencí pravidelných nákupů/prodejů <strong>bitcoinu 1x\u00a0měsíčně nebo 1x\u00a0týdně</strong> a s\u00a0<strong>1\u00a0grid strategií</strong>.<br>Pro odemčení kratších frekvencí, <a0>dynamické DCA strategie</a0>, dalších kryptoměn, automatického výběru BTC na vlastní peněženku a dalších funkcí prozkoumej naše <a1>měsíční\u00a0předplatné</a1>.<br>Pro platbu za předplatné se používají naše interní kredity, tzv. <a2>Štosáky</a2>.<br><br>➡️ Do začátku si <strong><a3>vyzvednni Štosáky za získané Odznaky</a3></strong>, takže budeš moci vyzkoušet všechny funkce předplatných zdarma.",
      subscriptionLimit: {
        with: "S předplatným",
        onlyFREE: "můžeš mít jen {{number}} aktivní grid strategii.",
        onlyPLUS: "můžeš mít jen {{number}} aktivní grid strategie.",
        onlyPREMIUM: "můžeš mít maximálně {{number}} aktivní grid strategie.",
        question: "Chceš navýšit předplatné?",
      },
      alert: {
        free: "Základní FREE předplatné naší služby Štosuj.cz ti umožňuje zdarma nastavit <strong>1 grid strategii</strong> pro měnový pár <strong>BTC/CZK</strong> nebo <strong>BTC/EUR</strong>.<br><br>Pro nastavení více grid strategií a měnových párů <strong>ETH/CZK</strong> a <strong>ETH/EUR</strong> můžeš přejít na <a>vyšší\u00a0předplatné</a>.",
      },
    },
    profitChart: {
      title: "Celkový zisk",
      error: "Při načítání grafu celkového zisku došlo k problému.",
    }
  },
  // email category titles are corresponding to EmailPreference.java enum values
  emailPreferences: {
    title: "E-mailové preference",
    necessary: {
      title: "Nezbytné e-maily",
      description: "Obnova hesla, informace o\u00a0aktivním předplatném",
    },
    notifications: {
      title: "Notifikace o mých strategiích (v\u00a0přípravě)",
      description: "Provedené obchody, nedostatek prostředků, chyby, výběry",
    },
    tutorials: {
      title: "Průvodce aplikací (v\u00a0přípravě)",
      description: "Série tipů a návodů po\u00a0registraci",
    },
    news: {
      title: "Novinky v\u00a0aplikaci",
      description: "Informace o čerstvě přidaných funkcích",
    },
    questionnaires: {
      title: "Dotazníky",
      description: "Zpětná vazba, návrhy nových funkcí",
    },
    events: {
      title: "Události a komunita",
      description: "Konference, meetupy, akce",
    },
    saved: "Uloženo",
    error: "Při ukládání došlo k chybě",
    all: "Odhlásit ze všech e-mailů",
  },
  withoutLabel: "Bez popisku",
  guide: "Štosuj.cz průvodce",
  myAccount: "Můj účet",
  each: "každé",
  each2: "každých",
  bottomNavigation: {
    info: "Informace",
    strategies: "Strategie",
    dashboard: "Přehled",
    credits: "Štosáky",
    settings: "Nastavení",
    subMenu: {
      INFO: {
        title: "Informace",
      },
      STRATEGIES: {
        title: "Moje strategie",
        disabled: "Neaktivní strategie",
      }
    }
  },
  howStart: {
    title: "Jak začít",
    description: "<strong>Štosuj.cz funguje jako nadstavba nad tvým účtem u\u00a0některé burzy nebo směnárny</strong>, kde máš své peníze a\u00a0kryptoměny plně pod kontrolou.<br>Díky propojení s\u00a0tvým účtem dokážeme za tebe automatizovat objednávky podle zvolené strategie.",
    steps: {
      1: "Propojit Štosuj.cz účet s\u00a0burzou/směnárnou",
      2: "Vytvořit první strategii - pravidelný nákup/prodej (DCA\u00a0strategie) nebo obchodování cenové volatility (grid\u00a0strategie)",
      3: "Poslat si na svou burzu/směnárnu peníze, ideálně nastavit trvalý příkaz, aby se mohla provádět pravidelná směna",
      4: "(Volitelně) Nastavit si automatický výběr BTC na vlastní\u00a0peněženku"
    }
  },
  backtestGridBot: {
    title: "Backtest grid strategie",
    meta_title: "Backtest grid strategie",
    inputs: {
      exchange: "Burza/směnárna",
      dateFrom: "Od",
      dateTo: "Do",
      adjustRange: "Upravit {{input}}",
      gridUpperPriceCheckTooltip: "Nastav na nižší hodnotu, pokud na začátku disponuješ menšími prostředky k odprodeji",
      gridLowerPriceCheckTooltip: "Nastav na vyšší hodnotu, pokud na začátku disponuješ menšími prostředky k nákupům",
      minGridUpperPrice: "Hodnota musí být vyšší než {{value}}",
      maxGridLowerPrice: "Hodnota musí být nižší než {{value}}",
      exchangeFeeValue: "{{fee}} % ({{description}})",
      exchangeFeeDescription: "Na Coinmate lze podle 30denního objemu obchodování dosáhnout <a>nižších poplatků</a>.",
      exchangeFee: "Poplatek za limitní objednávku (Maker)",
      exchangeFeeVolume: {
        default: "výchozí poplatek",
        volume: "30d objem > {{volume}}",
      },
    },
    chart: {
      startMarker: "Od",
      endMarker: "Do",
    },
    performance: "Výkon grid strategie",
    totalProfit: "Celkový zisk ze všech dvojobchodů",
    baseCurrencyValueInCounterCurrency: "při {{baseCurrency}} ceně {{price}}\u00a0{{counterCurrency}} v\u00a0hodnotě",
    ordersCount: "Počet provedených obchodů",
    buyOrdersCount: "Počet nákupních objednávek",
    sellOrdersCount: "Počet prodejních objednávek",
    startState: "Počáteční stav dne {{date}}",
    endState: "Konečný stav dne {{date}}",
    startCounterCurrencyAmount: "Potřeba na všechny nákupy",
    startBaseCurrencyAmount: "Potřeba na všechny prodeje",
    startCounterCurrencyTotalValue: "Celková počáteční hodnota",
    endCounterAndBaseCurrencyAmount: "Konečný zůstatek",
    endCounterCurrencyTotalValue: "Celková konečná hodnota",
    alert: {
      "2-5": "Na Štosuj.cz můžeš mít 1 grid strategii se vzdáleností objednávek od 2,5\u00a0% <a>zcela zdarma</a>.",
      "2": "Pro možnost nastavení vzdálenosti objednávek už od 2\u00a0% je potřeba naše <a>předplatné PLUS</a>.",
      "1-5": "Pro možnost nastavení vzdálenosti objednávek už od 1,5\u00a0% je potřeba naše <a>předplatné PREMIUM</a>.",
      "1": "Pro možnost nastavení vzdálenosti objednávek už od 1\u00a0% je potřeba naše <a>předplatné PREMIUM</a>. {{coinmate}}",
      "0-5": "Pro možnost nastavení vzdálenosti objednávek už od 0,5\u00a0% je potřeba naše <a>předplatné PREMIUM</a> a individuální konzultace. {{coinmate}}",
      coinmate: "Aby byly obchody ziskové, tak je potřeba na Coinmate dosáhnout díky vyšším objemům na <a2>nižší poplatky</a2>.",
      tutorial: "Zobrazit návod s <a>představením grid\u00a0strategie</a>."
    },
    priceLevelWarning: "Vyber delší časové období. V tomto období se cena dostatečně nehýbala",
  },
  darkMode: "Dark mode",
  summaries: {
    title: "Celkový souhrn strategií",
    gridTotalProfit: "Celkový zisk grid strategií",
    modal: {
      title: "Souhrn všech strategií",
      dcaSummaries: "Zobrazit souhrn DCA\u00a0strategií",
      dcaSummariesIncludingDisabled: "- včetně pozastavených DCA\u00a0strategií",
      gridSummaries: "Zobrazit souhrn grid\u00a0strategií",
      gridSummariesIncludingDisabled: "- včetně pozastavených grid\u00a0strategií",
      cashbackSats: "Zobrazit cashback saty",
    }
  },
  recharge: {
    create: {
      meta_title: "Měsíční platba za Štosáky",
      title: "Automatická měsíční platba",
      subtitle: "Aktivací si zajistíš přísun <strong>150 Štosáků měsíčně</strong> pomocí platební karty, Apple Pay nebo Google Pay.<br/>Pokud ještě nemáš zaplé <a>předplatné PREMIUM</a>, tak se ti po první platbě automaticky aktivuje.<br/><br/>Volbou vyšší varianty <strong>Premium\u00a0VIP</strong> nebo <strong>Premium\u00a0Hero</strong> podpoříš vývoj našeho projektu a získáš další výhody navíc (obdoba Patreonu nebo Herohero), díky!",
      additionalTitle: "Doplňující informace",
      additionalSubtitle: "Aktivací automatické měsíční platby souhlasíš s <a>Podmínkami služby</a> (Opakované platby).<br/><br/>Všechny 3 varianty ti každý měsíc na účet přičtou 150 Štosáků. Ty se následně odečítají z účtu každý měsíc podle aktivního předplatného. Automatická platba jen za 50 Štosáků měsíčně pro předplatné PLUS není možná kvůli poplatkům platební bráně a naší účetní, skoro nic by nám z toho nezbylo.<br/><br/>Po zaplacení varianty <strong>Premium VIP</strong> nebo <strong>Premium Hero</strong> se ti změní silueta profilu v pravém horním rohu na VIP/Štosujman a obdržíš v e-mailu instrukce jak se připojit do <strong>VIP kanálů na Discordu</strong>. Těšit se tam můžeš na interní informace, neveřejné statistiky, chystané novinky a také soutěže. Pro naše podporovatele mám také <strong>10%, resp. 15% slevové vouchery do Trezor e-shopu</strong>.<br/>Tvá Discord role VIP/Štosujman bude viditelná pro všechny se speciální barvou nicku (fialová/zlatá).<br/><br/>Ale hlavně budeš mít <strong>sakra dobrej pocit</strong>, že podporuješ náš projekt a patří ti za to náš obrovský vděk!<br/>Ještě jednou díky všem, kteří nás jakoukoli částkou podpoří, jste skvělí! 🧡",
      price: "<t>{{price}}\u00a0Kč</t><t1>/ měsíc</t1>",
      withoutTax: "{{price}}\u00a0Kč bez DPH",
      button: "Varianty měsíční platby",
      createSuccess: "Měsíční platba byla aktivována",
      createError: "Měsíční platbu se nepodařilo vytvořit",
      contain: {
        1: "+150 Štosáků měsíčně - použije se na předplatné PREMIUM pro odemknutí všech funkcí aplikace",
        2: "Přístup do <strong>VIP kanálů</strong> na komunitním\u00a0<a>Discordu</a>\u00a0+\u00a0soutěže",
        3: "Přednostní <strong>testování nových\u00a0funkcí</strong>",
        4: "Možnost <strong>videohovoru</strong> se\u00a0zakladatelem\u00a0Štosuj.cz",
        5: "Stane se z tebe uctívaný superhrdina\u00a0<strong>Štosujman</strong>\u00a0🧡<br>+ v budoucnu i merch (triko)",
      },
      redirectToPay: "Aktivovat za {{price}}\u00a0Kč\u00a0měsíčně",
      ACTIVE: "Aktivní",
      NEW: "Čeká na autorizaci",
      setInvoice: "Nastavit fakturační údaje",
      cancelActive: {
        title: "Deaktivace aktuální měsíční platby",
        description: "Opravdu chceš ukončit aktuální automatickou měsíční platbu a aktivovat jinou variantu?",
      }
    },
    RECHARGE_125: "Premium",
    RECHARGE_250: "Premium VIP",
    RECHARGE_500: "Premium Hero",
    showAllTransactions: "Seznam všech transakcí",
    start: "Od {{date}}",
    end: "Deaktivace měsíční platby",
    changeButton: "Detaily",
    cancelButton: "Deaktivovat",
    cancelPageText: "Tlačítkem níže ukončíš automatickou měsíční platbu a nebudou se ti nadále dokupovat Štosáky. Platbu můžeš kdykoliv znovu aktivovat.",
    confirmCancel: {
      title: "Deaktivace měsíční platby",
      description: "Opravdu chceš ukončit automatickou měsíční platbu za 150\u00a0Štosáků měsíčně? Tvé aktuální předplatné zůstane stále aktivní a bude dál odečítat Štosáky, akorát se ti nebudou automaticky dokupovat další Štosáky.",
      cancelSuccess: "Měsíční platba byla deaktivována",
      cancelError: "Měsíční platbu se nepodařilo deaktivovat",
      error404: "Neexistuje žádná aktivní měsíční platba",
    },
    INACTIVE: {
      badge: "Neaktivní",
      title: "{{name}} - {{cost}}\u00a0Štosáků",
      price: "<s>{{priceBeforeDiscount}}</s><t>{{price}}\u00a0Kč</t><t1>/ měsíc bez\u00a0DPH</t1>",
      subtitle: "Můžeš náš projekt podpořit i vyšší částkou a získat další výhody!\u00a0🧡",
      discount: "Dočasně s <strong>16% slevou.</strong> ⭐<br/>Stihni aktivovat před zdražením pro zachování nižší ceny!",
    },
    EXPIRED: {
      badge: "Expirováno",
      title: "{{name}}",
      price: "<t>{{price}}\u00a0Kč</t><t1>/\u00a0měsíc</t1>",
      subtitle: "Došlo k přerušení pravidelné měsíční platby."
    },
    ACTIVE: {
      badge: "Aktivní",
      title: "{{name}}",
      price: "<t>{{price}}\u00a0Kč</t><t1>/\u00a0měsíc</t1>",
    },
    NEW: {
      badge: "Čeká na autorizaci",
      title: "{{name}}",
      price: "<t>{{price}}\u00a0Kč</t><t1>/\u00a0měsíc</t1>",
    },
  },
  backtestDcaStrategy: {
    title: "Backtest DCA strategie",
    meta_title: "Backtest DCA strategie",
    alertTitle: "Porovnání DCA strategií na historických datech",
    alertText: "Vyzkoušejte si porovnání klasické DCA strategie s\u00a0fixní částkou a naší nové <a>dynamické DCA strategie <strong>(detailní\u00a0návod)</strong></a>, která vám dokáže spolehlivě <strong>nakoupit více bitcoinu s\u00a0lepší průměrnou nákupní cenou</strong> a dosáhnout tak <strong>vyššího zhodnocení</strong>.",
    dateFrom: "Od",
    dateTo: "Do",
    orderValueInfo: "<strong>Výchozí částka nákupu</strong> bude pro každou objednávku <strong>zvýšena či snížena</strong> podle aktuální hodnoty násobitele zvolené dynamické strategie.",
    frequencyInfo: "Backtest bude vypočítán s\u00a0<strong>nákupem každý den</strong> v\u00a0tomto období. Na nastavení vlastní frekvence nákupu v\u00a0této kalkulačce pracujeme, vydržte.",
    errorBoundaryChart: "Při načítání grafu <strong>{{title}}</strong> došlo k problému.",
    result: {
      dcaFixed: "DCA s fixní částkou",
      dcaDynamic: "DCA s dynamickou částkou",
      saved: "Nakoupeno",
      currentValue: "Aktuální hodnota",
      averagePrice: "Průměrná nákupní cena",
      difference: "Rozdíl hodnoty",
      invested: "Pořizovací částka",
      better: "Lepší o {{value}}\u00a0%"
    },
    selectBack: {
      label: "Každodenní investice po dobu",
      1: "1 roku",
      2: "2 let",
      3: "3 let",
      4: "4 let",
      5: "5 let",
      6: "6 let",
      custom: "Vlastní období",
    },
    orderValue: {
      title: "Nákupní částka",
      subtitle: "Fíxní nákupní částka je <strong>{{lastOvf}}\u00a0{{currency}}</strong> denně, dynamická částka  <strong>{{lastOvd}}\u00a0{{currency}}</strong> v\u00a0posledním dni.",
      fixed: "Fixní nákupní částka",
      dynamic: "Dynamická nákupní částka",
    },
    totalValuePercentDifference: {
      title: "Procentuální zhodnocení",
      subtitle: "Výsledné zhodnocení je <strong>{{lastVfp}}\u00a0%</strong> vs. <strong>{{lastVdp}}\u00a0%</strong> =\u00a0dynamická DCA strategie vychází <strong>lépe\u00a0o\u00a0{{lastFdp}}\u00a0%</strong>.",
      fixed: "Zhodnocení (fixní částka)",
      dynamic: "Zhodnocení (dynamická částka)",
      fixedVsDynamic: "Rozdíl zhodnocení dynamické a fixní částky",
    },
    priceAndAverage: {
      title: "Průměrná nákupní cena",
      subtitle: "Při konečné ceně <strong>{{lastP}}\u00a0{{currency}}</strong> jsou výsledné průměrné nákupní ceny <strong>{{lastApf}}\u00a0{{currency}}</strong> vs. <strong>{{lastApd}}\u00a0{{currency}}</strong> =\u00a0dynamická DCA strategie vychází <strong>lépe\u00a0o\u00a0{{diff}}\u00a0%</strong>.",
      price: "Cena {{currencyPair}}",
      fixed: "Průměrná nákupní cena (fixní částka)",
      dynamic: "Průměrná nákupní cena (dynamická částka)",
      difference: "Procentuální rozdíl fixní vs. dynamická",
    },
    totalValue: {
      title: "Aktuální hodnota",
      subtitle: "Výsledné hodnoty nakoupeného množství jsou <strong>{{lastVf}}\u00a0{{currency}}</strong> vs. <strong>{{lastVd}}\u00a0{{currency}}</strong> =\u00a0rozdíl fixní a dynamické DCA strategie je <strong>{{diff}}\u00a0%</strong>.",
      fixed: "Hodnota (fixní částka)",
      dynamic: "Hodnota (dynamická částka)",
      difference: "Procentuální rozdíl fixní vs. dynamická",
    },
    totalInvested: {
      title: "Investovaná částka",
      subtitle: "Celkově investováno <strong>{{lastIf}}\u00a0{{currency}}</strong> vs. <strong>{{lastId}}\u00a0{{currency}}</strong> =\u00a0rozdíl fixní a dynamické DCA strategie je <strong>{{diff}}\u00a0%</strong>.",
      fixed: "Investováno {{currency}} (fixní částka)",
      dynamic: "Investováno {{currency}} (dynamická částka)",
      difference: "Procentuální rozdíl fixní vs. dynamická",
    },
    totalAmount: {
      title: "Nakoupené množství",
      subtitle: "Celkově nakoupeno <strong>{{lastAf}}\u00a0{{currency}}</strong> vs. <strong>{{lastAd}}\u00a0{{currency}}</strong> =\u00a0rozdíl fixní a dynamické DCA strategie je <strong>{{diff}}\u00a0%</strong>.",
      fixed: "Nakoupeno {{currency}} (fixní částka)",
      dynamic: "Nakoupeno {{currency}} (dynamická částka)",
      difference: "Procentuální rozdíl fixní vs. dynamická",
    },
  },
  footer: {
    guide: {
      title: "Průvodce",
      stepByStep: "Návod krok za krokem",
      dynamicDca: "Dynamická DCA strategie",
      introductionGrid: "Představení grid strategie",
      detailGrid: "Grid strategie detailně",
      exitStrategy: "Exit strategie",
    },
    backtest: {
      title: "Backtesty strategií",
      dcaCalc: "DCA kalkulačka",
      dca: "Backtest DCA strategie",
      grid: "Backtest grid strategie",
      exit: "Výpočet exit strategie",
    },
    nextInfo: {
      title: "Další informace",
      pricing: "Ceník",
      currencyPairs: "Měnové páry",
      consultation: "Osobní konzultace",
      references: "Reference",
      affiliate: "Partnerský program",
      terms: "Podmínky služby",
    }
  },
  completeRegistration: {
    meta_title: "Dokončení registrace",
    title: "Dokončení registrace",
    newPassword: "Nové heslo",
    complete: "Dokončit registraci",
    successMessage: "Registrace byla dokončena",
    errorMessage: "Registraci se nepodařilo dokončit",
    errorMessage403: "Platnost odkazu pro dokončení registrace vypršela",
    errorMessage428: "E-mail už je aktivovaný",
  },
};
